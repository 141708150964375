<p-dialog [(visible)]="store.visible" [modal]="true" [draggable]="false" [resizable]="false" [styleClass]="'serial-modal'">
  <p-progressBar mode="indeterminate" *ngIf="store.loadingSwitch"></p-progressBar>
  <ng-template pTemplate="header">
    <h3>{{store.header}}</h3>
  </ng-template>
  <div class="p-fluid p-grid p-mt-1">
    <ng-container *ngFor="let ipt of store.ipts">
      <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="ipt"></app-input-cst>
    </ng-container>
  </div>
  <p-table styleClass="p-datatable-sm p-datatable-striped p-mt-1"
  [value]="store.serialList" [columns]="store.columns"
  responsiveLayout="scroll" [scrollable]="true" scrollHeight="300px"
  [resizableColumns]="false" [reorderableColumns]="false">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{width: col.width}">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [id]="col.title">{{col.title}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">{{data[col.key]}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length" style="text-align: center">No record found.</td>
      </tr>
    </ng-template>
  </p-table>
    <span class="custom-switch">
      <p-inputSwitch [(ngModel)]="store.checked" (onChange)="handleChange($event)" ></p-inputSwitch>
      <span class="switch-title">{{switchTitle}}</span>
    </span>
</p-dialog>
