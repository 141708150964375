<illustration name="Reservation"></illustration>
<app-query-pannel class='query-panel' [store]="queryPannel"></app-query-pannel>

    <div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
      <div class="p-d-flex p-jc-between p-mb-2">
        <span class="panel-title"></span>
        <app-multi-select
            [options]="columns"
            [(ngModel)]="selectedColumns"
            [autofocusFilter]="true"
            optionLabel="title"
            selectedItemsLabel="{{selectedColumns.length == columns.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
            selectedItemsLabel="{0} columns displayed"
            [style]="{minWidth: '200px'}"
            placeholder="Choose Columns"
          ></app-multi-select>
      </div>
      <div class="btns p-mb-2" style="display: inline-block;margin-right: 5px">
      </div>
      <p-table #ptable p-table-custom class="custom-expand-table"
        styleClass="p-datatable-sm p-datatable-striped"
        [rowHover]="true"
        [scrollable]="true"
        [(selection)]="selectedProducts"
        [resizableColumns]="editShow"
        [reorderableColumns]="editShow"
        [value]="lisOfData"
        [columns]="selectedColumns"
        columnResizeMode="expand"
        [loading]="loadingSwitch"
        dataKey="id"
        [sortField]="initSortField"
        [sortOrder]="initSortOrder"
        [frozenColumns]="frozenColumns"
        [frozenWidth]="frozenWidth"
        scrollHeight="500px"
      >

        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>

            <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns">
              <th
                *ngIf="col.sortable!=false"
                pResizableColumn
                pReorderableColumn
                [pSortableColumn]="col.sortable!=false?col.header:false"
                [title]="col.fullTitle || col.title"
                (click)="sortSearch(col.header)"
                [id]="col.title"
              >
                {{col.title}}
                <p-sortIcon [field]="col.header"></p-sortIcon>
              </th>
              <th
                *ngIf="col.sortable==false"
                pResizableColumn
                pReorderableColumn
                [pSortableColumn]="col.sortable!=false?col.header:false"
                [title]="col.fullTitle || col.title"
                (click)="sortSearch(col.header)"
                [id]="col.title"
              >
                {{col.title}}
              </th>
            </ng-container>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
          <tr style="height: 58px">
            <td *ngFor="let col of columns" class="p-text-truncate">

              <button *ngIf="col.type==='expandControl'"
                type="button" pButton pRipple
                [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

              <div *ngIf="showEdit && col.type==='action'" style="display: inline-flex">
                <!-- <button class="p-button-sm p-mr-1" *ngIf="data.edit && showU" pButton label="Save" (click)="save(data, rowIndex)"></button> -->
                <button *ngIf="data.edit && showEdit && data._data && data._data.allowEdit"
                  type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                  pTooltip="Save" tooltipPosition="top"
                  [icon]="'pi pi-save'" (click)="save(data, rowIndex)"></button>

                <!-- <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1"  pButton label="Edit" (click)="editDetail(data)"></button> -->
                
                <!-- have channel access && have edit reservation function role && not warehouse && not ELG / ELX && reservation type is_editing_allow == Y -->
                <button *ngIf="isActionEnable[rowIndex] && showEdit && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1 && data._data && data._data.allowEdit"
                  type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                  pTooltip="Edit" tooltipPosition="top" 
                  [icon]="'pi pi-pencil'" (click)="editDetail(data)"></button>

                <!-- <button class="p-button-sm p-button-outlined" *ngIf="data.edit && showU" pButton label="Cancel" (click)="cancel(data, rowIndex)"></button> -->
                <button *ngIf="data.edit && showEdit"
                  type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                  pTooltip="Cancel" tooltipPosition="top"
                  [icon]="'pi pi-times'" (click)="cancel(data, rowIndex)"></button>

                <!-- <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && data.expiryDate != null"  pButton label="Extend" (click)="extendReleaseDate(data)"></button> -->
                <button *ngIf="isActionEnable[rowIndex] && showExtend && !data.edit && data.expiryDate != null"
                  type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                  pTooltip="Extend" tooltipPosition="top" 
                  [icon]="'pi pi-calendar-plus'" (click)="extendReleaseDate(data)"></button>

                <button *ngIf="isActionEnable[rowIndex] && !data.edit && data.cancelReservation"
                  type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                  pTooltip="Cancel Reservation" tooltipPosition="top"
                  [icon]="'pi pi-times-circle'" (click)="cancelReservation(data)"></button>
              </div>
              <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.header]" [style]="{'width': '100%'}"/>
              <p-inputNumber *ngIf="col.type==='number'" [min]="col.min" [disabled]="!data.edit" class="p-inputtext-sm"
                (ngModelChange)="onTableValueChange($event, data, col)"
                (onInput)="onTableValueInput($event, data, col)"
                [(ngModel)]="data[col.header]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
              <span *ngIf="col.type==='date'" [title]="data[col.header]|date:'yyyy/MM/dd'">{{data[col.header] | date:'yyyy/MM/dd' }}</span>
              <span *ngIf="col.type==='datetime'" [title]="data[col.header]|date:'yyyy/MM/dd HH:mm'">{{data[col.header] | date:'yyyy/MM/dd HH:mm' }}</span>
              <ng-container *ngIf="col.type==='clickableText' && data[col.header]">
                <span *ngIf="data[col.isClickableKey]" class="cus-clickable" [title]="data[col.header]" (click)="col.onClick(data[col.header], data)">{{data[col.header]}}</span>
                <span *ngIf="!data[col.isClickableKey]" [title]="data[col.header]">{{data[col.header]}}</span>
              </ng-container>
              <span *ngIf="col.type==='longtext'" class="cus-longtext" [title]="data[col.header]">{{data[col.header]}}</span>
              <span *ngIf="!col.type" [title]="data[col.header]">{{data[col.header]}}</span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
          <tr></tr>
          <tr class="expand-row">
            <td [attr.colspan]="columns.length">
              <div class="p-d-flex expand-row-wrapper-container">
                <div class="expand-row-wrapper">
                  <div class="expand-row-content-wrapper">
                    <div class="expand-row-content">
                      <!-- content -->
                      <div class="p-fluid p-grid p-mt-2 p-mb-2">
                        <ng-container *ngFor="let col of selectedColumns">
                          <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                            <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==frozenColumns}">
                              <input
                                *ngIf="!col.type || !col.type.includes('date')"
                                class="p-inputtext-sm"
                                type="text"
                                [attr.id]="col.header"
                                [class]="{'p-input-sm':true}"
                                pInputText [(ngModel)]="data[col.header]"
                                [disabled]='true'
                                [readonly]='true'>
                              <input
                                *ngIf="col.type == 'date'"
                                class="p-inputtext-sm"
                                type="text"
                                [attr.id]="col.header"
                                [class]="{'p-input-sm':true}"
                                pInputText [ngModel]="data[col.header] | date: 'yyyy/MM/dd' "
                                [disabled]='true'
                                [readonly]='true'>
                              <input
                                *ngIf="col.type == 'datetime'"
                                class="p-inputtext-sm"
                                type="text"
                                [attr.id]="col.header"
                                [class]="{'p-input-sm':true}"
                                pInputText [ngModel]="data[col.header] | date: 'yyyy/MM/dd HH:mm' "
                                [disabled]='true'
                                [readonly]='true'>
                              <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="12" style=" text-align: center;">No record found.</td>
          </tr>
        </ng-template>
      </p-table>

      <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="page($event)"></p-paginator>

    </div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
