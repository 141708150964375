<!-- <span class="p-float-label">
  <span class="cus-required-label" *ngIf="ipt.required">*</span>
  <p-multiSelect [inputId]="ipt.name" [class]="{'p-multiSelect-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}" resetFilterOnHide=true
    [options]="ipt.options" [(ngModel)]="ipt.value" [optionLabel]="ipt._optionLabel?ipt._optionLabel:'name'" optionDisabled="edit" [filter]="filter" [panelStyle]="{'max-width': '200%'}"
    [dataKey]="ipt.dataKey?ipt.dataKey:null" [virtualScroll]="true" [itemSize]="40"
    [disabled]="ipt.disabled" (onChange)="onChange($event)" (onPanelShow)="onPanelShow()" (onPanelHide)="onPanelHide()" [appendTo]="ipt.appendTo?ipt.appendTo:null">
    <ng-template let-option pTemplate="item">
      <div class="p-text-truncate" [title]="option[ipt._optionLabel?ipt._optionLabel:'name']">{{option[ipt._optionLabel?ipt._optionLabel:'name']}}</div>
    </ng-template>
  </p-multiSelect>
  <label [for]="ipt.name">{{ipt.title}}</label>
</span> -->

<span class="p-float-label">
  <span class="cus-required-label" *ngIf="ipt.required">*</span>
  <app-multi-select [inputId]="ipt.name" [class]="{'p-multiSelect-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}" resetFilterOnHide=true
    [options]="ipt.options" [(ngModel)]="ipt.value" [optionLabel]="ipt._optionLabel?ipt._optionLabel:'name'" optionDisabled="edit" [filter]="filter" [panelStyle]="{'max-width': '200%'}"
    [dataKey]="ipt.dataKey?ipt.dataKey:null" [virtualScroll]="true" [itemSize]="40"
    [disabled]="ipt.disabled" [skipAllCheckMatch]="ipt.skipAllCheckMatch"
    [autofocusFilter]="true"
    (onChange)="onChange($event)" (onPanelShow)="onPanelShow()" (onPanelHide)="onPanelHide()" [appendTo]="ipt.appendTo?ipt.appendTo:null">
    <ng-template let-option pTemplate="item">
      <div class="p-text-truncate" [title]="option[ipt._optionLabel?ipt._optionLabel:'name']">{{option[ipt._optionLabel?ipt._optionLabel:'name']}}</div>
    </ng-template>
  </app-multi-select>
  <label [for]="ipt.name">{{ipt.title}}</label>
</span>
