<span class="p-float-label">
    <span class="cus-required-label" *ngIf="ipt.required">*
  </span>
  <p-dropdown [appendTo]="ipt.appendTo?ipt.appendTo:null" [inputId]="ipt.name" [autoDisplayFirst]="false" [options]="ipt.options" 
    [virtualScroll]="true" [itemSize]="40"
    [(ngModel)]="ipt.value" [optionLabel]="ipt.optionLabel||'name'" [optionValue]="ipt.optionValue||'code'" resetFilterOnHide=true
    [panelStyle]="{'max-width': '200%'}" [showClear]="true" [class]="{'p-dropdown-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}"
    [disabled]="ipt.disabled" [filter]="true" [filterBy]="ipt.optionLabel||'name'" (onChange)="onChange($event)">
    <ng-template let-option pTemplate="item">
      <div class="p-text-truncate" [title]="option[ipt.optionLabel?ipt.optionLabel:'name']">{{option[ipt.optionLabel?ipt.optionLabel:'name']}}</div>
    </ng-template>
  </p-dropdown>
  <label [for]="ipt.name">{{ipt.title}}</label>
</span>
