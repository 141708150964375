import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, computed, observable} from "mobx";
import {HttpHelper} from "../../../util/HttpHelper";
import {BUTTON_TYPE, INPUT_TYPE, URLDICT, CONFIG} from "../../../stores/base/BaseStore";
import {CommonMethod} from "../../../util/CommonMethod";
import {Table} from 'primeng/table';
import {LocalStorageHelper} from 'src/app/util/LocalStorageHelper';
import {MessageService, ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {RequestDetailStore} from "../../../stores/stock/reservation/requestDetailStore";
import { StockReservationService } from 'src/app/service/stock/stock-reservation.service';
import { tap } from 'rxjs/operators';
import { FilterCommonMethod } from 'src/app/util/FilterCommonMethod';
import { DatePipe } from '@angular/common';
import { Paginator } from 'primeng/paginator';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from '@/service/common/common-service';
import { SearchFieldRecordor } from '@/service/common/search-record';
import { CommonStore } from '@/service/common/common-store-service';


const QUERYTITLE = {
  ITEM: "Item Code",
  SOURCESYSTEM: "Source System",
  SOURCEREFNO: "Source Ref. No.",
  CHANNEL: "Stock Channel",
  SALESCHANNEL: "Sales Channel",
  RESERVATIONTYPE: "Reservation Type",
  RESERVEDFOR: "Reserved For",
  RESERVEDBY: "Reserved By",
  RESERVATIONID: "Reservation ID",
  RESERVATIONCREATEDATE: "Reservation Creation Date",
  RESERVATIONWITH: "Reservation with",
  CONDITION: "Condition Type",
  VALUEOFRESERVATIONWITH:"Value of Reservation with",
  CONDITIONOFPLANNEDTOALLOCATE:"Condition Type of Planned to Allocate",
  VALUEOFPLANNEDTOALLOCATE:"Value of Planned to Allocate",
  SCHOOLCODE: "School Code",
  SCHOOLLOCID: "School Location ID",
  SCHOOLNUM: "School Number",
  RESERVATIONCREATEDATEFROM: "Reservation Creation Date From",
  RESERVATIONCREATEDATETO: "Reservation Creation Date To",
};

@Component({
  selector: 'nz-test',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  @observable permission: string[]
  @observable defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false
  // @observable loadingSwitch2 = false
  @observable selectedProducts: any[] = []

  @observable queryPannel: any = {}
  @observable lisOfData: any[] = []

  @action changeitem = (idx, data) => {
    this.lisOfData[idx] = data
  }
  backupData: any[] = [];
  // @observable isVisible:boolean = false

  hasQualityOption= [
    {code:'All', name: 'All'},
    {code:'Pending', name: 'Pending'},
    {code:'Allocated', name: 'Allocated'},
    {code:'Cancelled', name: 'Cancelled'},
    {code:'Released', name: 'Released'},
    {code:'Planned To Cancel', name: 'Planned To Cancel'},
  ]
  // hasQualityOption = ['All', 'Pending', 'Allocated', 'Cancelled', 'Released']
  symbolOption = [
    {name: '<', code: 1},
    {name: '<=', code: 2},
    {name: '=', code: 3},
    {name: '>=', code: 4},
    {name: '>', code: 5},
  ]
  columns = [
    {header: 'reservationDate', title: 'Reservation Date', width: '150px', type: 'date'},
    {header: 'pickupDate', title: 'Pickup Date', width: '150px', type: 'date'},
    {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '150px', type: 'clickableText', isClickableKey: '_sourceTxnRefHeaderIdLink', onClick:(e, data)=>{
      try{
        let _id = data.sourceTxnRefHeaderId
        let sourceTxnRefHeaderURL = data.sourceTxnRefHeaderURL
        if(_id && _id.length > 0) {
          let url = this.getCOMUrl(_id, sourceTxnRefHeaderURL)
          if(url) window.open(url, '_blank')
        }
      }catch(e){console.error(e)}
    }},
    // {header: 'itemCode', title: 'Item', width: '90px'},
    {header: 'itemDesc', title: 'Item Description', width: '280px', type: 'longtext'},
    {header: 'reservationType', title: 'Reservation Type', width: '150px'},
    {header: 'salesChannel', title: 'Sales Channel', width: '150px'},
    {header: 'releaseChannel', title: 'Stock Channel', width: '150px'},
    {header: 'qtyRequested', title: 'Request Reserve Qty', width: '170px'},
    {header: 'qtyPending', title: 'Pending Qty', width: '120px'},
    {header: 'qtyPlanToAllocate', title: 'Planned to Allocate Qty', width: '190px'},
    {header: 'qtyAllocated', title: 'Allocate Qty', width: '120px', type: 'number', min: 0},
    {header: 'qtyReleased', title: 'Release Qty', width: '120px'},
    {header: 'qtyPlanToCancel', title: 'Planned to Cancel Qty', width: '190px'},
    {header: 'qtyCancelled', title: 'Cancel Qty', width: '120px', type: 'number', min: 0},
    {header: 'expiryDate', title: 'Expiry Date', width: '130px', type: 'date'},
    {header: 'extensionCount', title: 'No. of Extension', width: '150px'},
    {header: 'reason', title: 'Reason', width: '100px'},
    {header: 'reserveDescription', title: 'Reserved For', width: '190px'},
    {header: 'reservedBy', title: 'Reserved By', width: '190px'},
    {header: 'schNum', title: 'School Number', width: '150px'},
    {header: 'sensitiveRemarks', title: 'Remarks', width: '150px', type: 'txt'},
    {header: 'id', title: 'Reservation ID', width: '150px'},
    {header: 'sourceSystem', title: 'Source System', width: '140px'},
    {header: 'sourceTxnRefHeaderId', title: 'Source Ref. ID', width: '200px'},
    {header: 'sourceTxnType', title: 'Source System Txn Type', width: '190px'},
    // {header: 'createDate', title: 'Create Date', width: '150px', type: 'date'},
    {header: 'interfacedstatus', title: 'Interfaced Status (LIS)', width: '190px'},
    // {header: 'backlogIso', title: 'backlog iso', width: '190px'},
    {header: 'lis30eorderNumber', title: 'ISO No.', width: '190px'},
    {header: 'lisReservationId', title: 'LIS Reservation ID', width: '190px'},
    {header: 'replenishReference', title: 'Replenish Reference', width: '190px'},
    {header: 'unallocatedDate', title: 'Unallocate Date', width: '10rem', type: 'date'},
    {header: 'updateDate', title: 'Last Update Date', width: '150px', type: 'date'},
    {header: 'creationDate', title: 'Creation Date', width: '150px'},
    // {header: 'action', title: 'Action', type: 'action'},
    // {header: 'qtyPlanToAllocate', title: 'Planned to Allocate Qty', width: '190px'},
    // {header: 'qtyPlanToCancel', title: 'Planned to Cancel Qty', width: '190px'},
    // {header: 'schLocCode', title: 'School Location Code', width: '190px'},
  ]

  frozenColumns: any = [
    {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
    {header: 'action', title: 'Action', type: 'action', width: '150px', sortable: false},
    {header: 'itemCode', title: 'Item Code', width: '110px'},
    // {header: 'itemCode', title: 'Item', width: '75px'},
  ]
  frozenWidth = 56 + 150 + 110 + 'px'

  // editDialog = false
  dialogTitle = ""
  channels: any[] = []
  channels2: any[] = []
  skus: any[] = []
  seSkus: any[] = []
  catalogs
  orderCreationDate
  reservationID
  assignmentLogic
  reservationType
  reservedBy
  orderId
  value: null
  balance
  seSku = [""]
  reservationStatus
  sechannelValue = ""
  _selectedColumns: any[];
  sourceSystem
  // orderStatus
  // assignmentlogics
  resstockreservationtypes
  selectedValue
  remark
  field = 'reservationDate'
  order = -1;
  isSortSearch
  initSortField = 'reservationDate'
  initSortOrder = -1
  channelOptions = [];
  sourceSystemList = [];

  totalRecords
  pageIndex = 0
  pageSize = 20
  searchParams = {}
  editShow: boolean = true;
  clonedLines = {}

  channelsDisableEdit = CONFIG.REPLENISH_BYOD_CHANNEL_LIST; // ['ELG']
  _allowListBuffer={}

  selectedChannel = [];
  selectedChannelDisplay = '';

  isActionEnable:boolean[] = [];

  @ViewChild('ptable') private ptable: Table

  // not calling storeSearchParams in this component because no requirement
  // import this class only for get params data at NavigationEnd event
  searchFieldRecordor: SearchFieldRecordor;

  // tslint:disable-next-line:max-line-length
  constructor(
    public n: MessageService,
    public r: Router,
    private detail: RequestDetailStore,
    public p: ActivatedRoute,
    public m: ConfirmationService,
    private i18n: TranslateService,
    public stockReservationService: StockReservationService,
    public datepipe: DatePipe,
    public cdRef: ChangeDetectorRef,
    public commonService: CommonService,
    public commonStore: CommonStore,
  ) {
    this._selectedColumns = this.columns;
    this.getPermission(p)
    this.initQueryData()
    this.searchFieldRecordor = new SearchFieldRecordor(this.commonStore, this.r, this.p, CONFIG.SEARCHPARAMSKEY.STOCKALLOCATION)
    this.initRouterEvent()
  }

  initRouterEvent() {
    this.searchFieldRecordor.setRouterEventForSearchParams(this.r)
    .subscribe(res=>{
      let haveSetValueFromParams = this.getSnapshotData(res)
      if(!haveSetValueFromParams){
        // load default channel if no params
        this.channels = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
        this.initDefaultChannel()
        //this.search(true,true); // search after init default channel, no other default need to set
      }else{
        this.search(true,true)
      }
    })
  }


  checkActionDisable(channelId){
    this.isActionEnable.push(CommonMethod.haveChannelPermission(channelId))
  }

  getSnapshotData(params) {
    let data = params;
    let haveSetValueFromParams: boolean = false;
    this.queryPannel.ipts.forEach(ipt=>{
      if(ipt.type==INPUT_TYPE.DATETIME){
        if(data[ipt.name]){
          let val = data[ipt.name];
          ipt.value = new Date(val);
          haveSetValueFromParams = true;
        }
      }else {
        if(data[ipt.name]){
          let val = data[ipt.name];
          ipt.value = val;
          haveSetValueFromParams = true;
        }
      }
    })
    if(data.pageSize){
      this.pageSize = Number.parseInt(data.pageSize);
      haveSetValueFromParams = true;
    }
    if(data.sortEvent){
      let sortEvent = data.sortEvent;
      this.field = sortEvent.field;
      this.order = sortEvent.order;
      haveSetValueFromParams = true;
    }
    return haveSetValueFromParams;
  }

  initQueryDataOptions(){
    let itemIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.ITEM)
    itemIpt.options = this.seSkus
    let sourceSystemIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.SOURCESYSTEM)
    sourceSystemIpt.options = this.sourceSystemList
    let channelIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.CHANNEL)
    channelIpt.options = this.channelOptions
    let salesChannelIdListIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.SALESCHANNEL)
    salesChannelIdListIpt.options = this.channelOptions
    let typeIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.RESERVATIONTYPE)
    typeIpt.options = this.resstockreservationtypes
  }

  initQueryData() {
    this.queryPannel = {
      hasQuantity: "All",
      ipts: [
        {
          title: QUERYTITLE.ITEM,
          name: 'itemId',
          placeholder: 'Choose Item',
          value: null,
          type: INPUT_TYPE.MUTIPLESELECT,
          dataKey: 'code',
          skipAllCheckMatch: true,
          options: this.seSkus,
          class: "p-col-12 p-md-4 p-lg-6",
        },
        {
          title: QUERYTITLE.SOURCESYSTEM,
          name: 'sourceSystem',
          type: INPUT_TYPE.SELECT,
          options: this.sourceSystemList,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          optionValue: 'value',
        },
        {
          title: QUERYTITLE.SOURCEREFNO,
          name: 'sourceReferenceNo',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: QUERYTITLE.CHANNEL,
          name: 'channel',
          value: this.selectedChannel,
          showValue: this.selectedChannelDisplay,
          type:INPUT_TYPE.MUTIPLETREESELECT,
          options: this.channelOptions,
          class: "p-col-12 p-md-4 p-lg-3",
          beforeSubmit: function(value) { return value?value.map(val=>val.id):null },
          change: function(value, ipt) {
            if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        },
        {
          title: QUERYTITLE.SALESCHANNEL,
          name: 'salesChannelIdList',
          value: null,
          showValue: null,
          type:INPUT_TYPE.MUTIPLETREESELECT,
          options: this.channelOptions,
          class: "p-col-12 p-md-4 p-lg-3",
          beforeSubmit: function(value) { return value?value.map(val=>val.id):null },
          change: function(value, ipt) {
            if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        },
        {
          title: QUERYTITLE.RESERVATIONTYPE,
          name: 'reservationType',
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          options: this.resstockreservationtypes,
          // iptionLabel: 'name',
          // iptionValue: 'code',
        },
        {
          title: QUERYTITLE.RESERVEDFOR,
          name: 'reserveDescription',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        // {
        //   title: QUERYTITLE.RESERVATIONCREATEDATE,
        //   name: 'reservationCreateDate',
        //   type: INPUT_TYPE.DATETIME,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        // },
        {
          title: QUERYTITLE.RESERVATIONCREATEDATE,
          name: 'orderDate',
          type: INPUT_TYPE.DATERANGE,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: QUERYTITLE.SCHOOLNUM,
          name: 'schNum',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: QUERYTITLE.RESERVATIONWITH,
          name: 'haveQuantity',
          type: INPUT_TYPE.SELECT,
          options: this.hasQualityOption,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: QUERYTITLE.RESERVATIONID,
          name: 'reservationId',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        // {
        //   type: INPUT_TYPE.EMPTY,
        //   class: "p-lg-3 p-d-none p-d-lg-block",
        //   value: null,
        // },
        {
          title: QUERYTITLE.CONDITION,
          name: 'conditionType',
          type: INPUT_TYPE.SELECT,
          disabled: true,
          options: this.symbolOption,
          class: "p-col-12 p-md-4 p-lg-3",
          value: 4,
        },
        {
          title: QUERYTITLE.VALUEOFRESERVATIONWITH,
          name: 'haveQuantityValue',
          type: INPUT_TYPE.INPUTNUMBER,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: QUERYTITLE.CONDITIONOFPLANNEDTOALLOCATE,
          name: 'ptaConditionType',
          type: INPUT_TYPE.SELECT,
          disabled: true,
          options: this.symbolOption,
          class: "p-col-12 p-md-4 p-lg-3",
          value: 4,
        },
        {
          title: QUERYTITLE.VALUEOFPLANNEDTOALLOCATE,
          name: 'ptaConditionValue',
          type: INPUT_TYPE.INPUTNUMBER,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },

        // {
        //   title: "School Code",
        //   name: 'schoolCode',
        //   type: INPUT_TYPE.INPUT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        // },
        // {
        //   title: "School Location Code",
        //   name: 'schoolLocCode',
        //   type: INPUT_TYPE.INPUT,
        //   class: "p-col-12 p-md-4 p-lg-3 p-lg-offset-right-6",
        //   value: null,
        // },
        // {
        //   title: "Reservation Status",
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: this.orderStatus,
        //   iptionLabel: 'name',
        //   iptionValue: 'code',
        // },
        // {
        //   title: "Assignment Logic",
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: this.assignmentlogics,
        //   // iptionLabel: 'name',
        //   // iptionValue: 'code',
        // },
        // {
        //   title: "All",
        //   id: "one",
        //   name: "hasQua",
        //   type: INPUT_TYPE.RADIO,
        //   class: "p-col-12 p-md-4 p-lg-2",
        //   value: "All",
        //   // disabled: true
        // },
        // {
        //   title: "Pending",
        //   id: "Two",
        //   name: "hasQua",
        //   type: INPUT_TYPE.RADIO,
        //   class: "p-col-12 p-md-4 p-lg-1",
        //   value: "Pending",
        //   // disabled: true
        // },
        // {
        //   title: "Allocated",
        //   id: "Three",
        //   name: "hasQua",
        //   type: INPUT_TYPE.RADIO,
        //   class: "p-col-12 p-md-4 p-lg-1",
        //   value: "Allocated",
        //   // disabled: true
        // },
        // {
        //   title: "Cancelled",
        //   id: "Four",
        //   name: "hasQua",
        //   type: INPUT_TYPE.RADIO,
        //   class: "p-col-12 p-md-4 p-lg-1",
        //   value: "Cancelled",
        //   // disabled: true
        // },

        // {
        //   title: "Released",
        //   id: "Five",
        //   name: "hasQua",
        //   type: INPUT_TYPE.RADIO,
        //   class: "p-col-12 p-md-4 p-lg-1",
        //   value: "Released",
        //   // disabled: true
        // }
      ],
      btnsclass: 'p-d-flex p-justify-end p-col-12 p-md-12 p-md-offset-0 p-lg-12 p-lg-offset-0',
      btns: [{
        type: BUTTON_TYPE.BUTTON,
        title: 'Clear',
        class: "p-button-outlined p-mr-1",
        show: true,
        handler: {
          'click': () => {
            this.refresh()
          }
        }
      }, {
        type: BUTTON_TYPE.BUTTON,
        title: 'Export',
        icon: '',
        show: true,
        class: "p-mr-1",
        handler: {
          "click": () => {
            this.export()
          }
        }
      }, {
        type: BUTTON_TYPE.BUTTON,
        title: 'Create',
        icon: '',
        permissionType: 'c',
        show: true,
        class: "p-mr-1",
        handler: {
          "click": () => {
            this.create()
          }
        }
      }, {
        type: BUTTON_TYPE.BUTTON,
        title: 'Search',
        icon: '',
        show: true,
        class: "",
        handler: {
          "click": () => {
            this.search(true, true)
          }
        }
      }],
      change: (idx, value, ipt) => {
        if(ipt.title === QUERYTITLE.RESERVATIONWITH) {
          this.showValueOfReservationWith(value)
        }
        if(ipt.change){
          ipt.change(value, ipt)
        }
      }
    };
    this.setQueryButtonShow()
  }

  @action getPermission(p) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // console.log('>-- get permission --<', p.snapshot.data, this.permission);
  }

  @computed get showEdit() {
    return this.permission?.includes('Edit Reservation')
  }

  @computed get showExtend() {
    return this.permission?.includes('Extend Reservation')
  }

  @computed get showSearch() {
    return this.permission?.includes('Search Reservation')
  }

  @computed get showCreate() {
    return this.permission?.includes('Create Reservation')
  }

  setQueryButtonShow() {
    this.queryPannel.btns.forEach(element => {
      element.title === 'Search' && (element.disabled = !this.showSearch)
      element.title === 'Create' && (element.disabled = !this.showCreate)
    });
  }
  onChange() {
    const len = this.seSku.length;
    this.seSku = len > 0 ? (this.seSku[len - 1] === '' ? [""] : this.seSku.filter((item) => item)) : [""]
  }

  warehouseIdList = {};

  initDefaultChannel(){
    try{
    let channel = this.commonService.getDefaultChannel(this.channels)
    if(channel){
      this.selectedChannel = [channel];
    }else{
      this.selectedChannel=null
    }
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
    let channelIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.CHANNEL);
    channelIpt.value = this.selectedChannel;
    channelIpt.showValue = this.selectedChannelDisplay;
    }catch(e){console.error(e)}
  }

  ngOnInit(): void {
    this.initPaginatorSub()
    this.selectedValue = "All"
    this.channels = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
    // default channel init when NavigationEnd event in SearchFieldRecordor, trigger after constructor and before ngInit
    // this.initDefaultChannel()
    this.channelOptions = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
    this.seSkus = JSON.parse(localStorage.getItem("SKU")).map(item=>{
      return {
        code: item.code,
        name: `${item.name} - ${item.flag}`,
        _data: item
      }
    })
    this.sourceSystemList = JSON.parse(localStorage.getItem("SOURCESYSTEM")).map(val=>{return {name: val.code, value: val.code, _data: val}})
    // this._allowListBuffer['_sourceTxnRefHeaderIdLink'] = this.sourceSystemList
    //   .filter(item=>[5].indexOf(item._data.sys_def_lookup_id)>-1) // 5: COM
    //   .map(item=>item._data.code.toUpperCase())
    this.warehouseIdList = {}
    JSON.parse(localStorage.getItem("WAREHOUSE")).forEach(wh => {
      this.warehouseIdList[wh.id]=true
    });
    /* this.orderStatus = LocalStorageHelper.getObject("RESERVATION_STATUS").map(
      (item) => {
        let {value: code, label: name, ...other} = item
        return {code, name, ...other}
      }
    ) */

    this.i18n.get('reservation').pipe(tap(res=>{
      this.resstockreservationtypes = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").map(item => {
        item.label = res[item.label]
        let {stockReservationTypeCode: code, description: name, ...other} = item
        return {code, name, ...other}
      })
    })).subscribe(res => {
      this.initQueryDataOptions()
      // this.search(true,true)
    })
  }
  /* setPermission(list) {
    list.forEach(item => {
      if(item.permissionType){
        let permission = this.defaultPermission[item.permissionType]
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permission & permission) === permission
      }
    })
  } */
  showValueOfReservationWith(val) {
    let conditionIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.CONDITION)
    conditionIpt.disabled = val === "All" || !val ? true : false
    conditionIpt.value = 4

    let valueIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.VALUEOFRESERVATIONWITH)
    valueIpt.value = null
    valueIpt.disabled = val === "All" || !val ? true : false

    let condition1Ipt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.CONDITIONOFPLANNEDTOALLOCATE)
    condition1Ipt.disabled = val === "All" || !val ? true : false
    condition1Ipt.value = 4

    let value1Ipt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.VALUEOFPLANNEDTOALLOCATE)
    value1Ipt.value = null
    value1Ipt.disabled = val === "All" || !val ? true : false
  }
  lastSearchData = {}
  search(isSort: boolean = true, filterChanged = false) {
    this.isActionEnable=[]
    this.editShow = true
    let data: any = {}
    if(!filterChanged){
      data = this.lastSearchData
    }else if(filterChanged){
      // this.sechannelValue = this.queryPannel.ipts.find(item => item.title === "Channel").value?.data;
      this.seSku = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.ITEM).value;
      this.seSku = this.seSku ? this.seSku.map(item => item['code']) : null;
      this.sourceSystem = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.SOURCESYSTEM).value;
      // this.orderCreationDate = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVATIONCREATEDATE).value;
      // this.orderCreationDate = this.transferDate(this.orderCreationDate)
      this.reservationID = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVATIONID)?.value?.toString();
      this.orderId = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.SOURCEREFNO).value;

      this.reservationType = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVATIONTYPE).value;
      let _reservationType
      if(this.reservationType){
        _reservationType = this.reservationType.map(type=>type.code)
      }
      this.reservationType = _reservationType;
      // this.reservationType = this.reservationType ? this.reservationType.code : null;

      this.reservedBy = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVEDFOR).value;
      let _channelIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.CHANNEL);
      let _channel = _channelIpt.beforeSubmit?_channelIpt.beforeSubmit(_channelIpt.value): _channelIpt.value;
      let _salesChannelIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.SALESCHANNEL);
      let _salesChannel = _salesChannelIpt.beforeSubmit?_salesChannelIpt.beforeSubmit(_salesChannelIpt.value): _salesChannelIpt.value;
      let _schNum = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.SCHOOLNUM).value;
      let _reserveDescription = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.RESERVEDFOR).value;
      let _conditionType  = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.CONDITION).value;
      let _hasQuantity = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.RESERVATIONWITH).value;
      let _haveQuantityValue = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.VALUEOFRESERVATIONWITH).value;
      let _ptaConditionType = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.CONDITIONOFPLANNEDTOALLOCATE).value;
      let _ptaConditionValue = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.VALUEOFPLANNEDTOALLOCATE).value;

      // let _from = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.RESERVATIONCREATEDATEFROM).value;
      // let _to = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.RESERVATIONCREATEDATETO).value;
      let _date = this.queryPannel.ipts.find(item=>item.title===QUERYTITLE.RESERVATIONCREATEDATE).value;
      let _orderDate = []
      if(_date) {
        // When missing end date
        _date[1] = _date[1] || _date[0]
        _orderDate = [
          this.dateFormatToApi(_date[0]),
          this.dateFormatToApi(new Date(new Date(_date[1]).setHours(23, 59, 59))) // add 1 day
        ]
      }

      data = {
        ...data,
        itemId: this.seSku,
        // repoId:this.sechannelValue,
        sourceSystem: this.sourceSystem,
        reservationId: !isNaN(Number.parseInt(this.reservationID))?Number.parseInt(this.reservationID):this.reservationID,
        // orderDate: this.orderCreationDate,
        orderDate: _orderDate,
        sourceReferenceNo: this.orderId,
        // reservationStatus: this.reservationStatus,
        // assignmentLogicId: this.assignmentLogic,
        reserveDescription: _reserveDescription,
        // haveQuantity: this.queryPannel.hasQuantity,
        haveQuantity: _hasQuantity,
        conditionType: _conditionType,
        haveQuantityValue: _haveQuantityValue,
        ptaConditionType: _ptaConditionType,
        ptaConditionValue: _ptaConditionValue,
        reservationType: this.reservationType,
        // reservedFor: this.reservedBy,
        channel: _channel?_channel:null,
        salesChannelIdList: _salesChannel?_salesChannel:null,
        schNum: _schNum,
        // schLocCode: _schoolLocCode,
        // pageIndex: this.pageIndex,
        pageIndex: !filterChanged?this.pageIndex:0,
        pageSize: this.pageSize,
        sortEvent: isSort ? {field: this.field, order: this.order} : null,
      }
    }
    data = {
      ...data,
      accountName: this.commonService.getAccount(),
      pageIndex: !filterChanged?this.pageIndex:0,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order},
    };
    // -----------
    // 获取从notice传递的数据
    let noticeMsg = JSON.parse(sessionStorage.getItem('NOTICE_MESSAGE'))
    if(noticeMsg) {
      // data.reservationId = noticeMsg.reservationId;
      const ipt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVATIONID);
      ipt.value = data.reservationId
      sessionStorage.removeItem('NOTICE_MESSAGE')
    }
    // -----------
    // return
    this.lastSearchData = data;
    this.searchParams = {...data}
    const url = URLDICT.STOCK_RESERVATION_SEARCH;
    this.getDataFromServer(url, data, filterChanged)
    this.isSortSearch = isSort;
    if (!isSort) {
      // tslint:disable-next-line: no-unused-expression
      this.ptable && this.ptable.reset()
      this.order = undefined
    }
  }

  transferDate(date) {
    let dateNew
    if (date){
      var year = date.getFullYear()
      // console.log(typeof (year))

      var m = (date.getMonth() + 1).toString();
      var d = (date.getDate()).toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      if (d.length == 1) {
        d = '0' + d;
      }
      dateNew = year + '-' + m + '-' + d

    }else dateNew = null
    return dateNew
  }

  dataPreProcess(data){
    data.forEach(item => {
      item._data = JSON.parse(JSON.stringify(item))
      if(item.reservationCode === 'NOP-SOAO' || item.reservationCode === 'NOP-SO') {
        item.cancelReservation = true
      }
      if (item.createdDate){
        item.createdDate = new Date(item.createdDate)
      }
      if (item.fulfilledDate) {
        item.fulfilledDate = new Date(item.fulfilledDate)
      }
      if (item.endDateActive) {
        item.endDateActive = new Date(item.endDateActive)
      }
      if (item.interfacedstatus&&item.interfacedstatus=='null') {
        item.interfacedstatus = null
      }
      // /stock_reservation/search return reservation type description
      // let type = this.resstockreservationtypes.find(_type=>_type.name==item._data.reservationType)
      if (
        item.isEditingAllowed == 'Y' &&
        !(CONFIG.RESERVATION_DISALLOW_EDIT_SOURCESYSTEM_CODE || []).includes(<string>item.sourceSystem)
        // type.isEditingAllowed == "Y"
      ){
        item._data.allowEdit = true;
      }else{
        item._data.allowEdit = false;
      }
      item._sourceTxnRefHeaderIdLink = this.getSourceTxnRefHeaderIdLink(item)
    })
    return data
  }

  getDataFromServer(url, data, filterChanged = false) {
    this.loadingSwitch = true
    this.isSortSearch = false
    this.commonService.httpPost(url, data)
    // HttpHelper.post(url, data)
    .then(res => {
      if (res.code == '000') {
        if(this.paginatorSubject && filterChanged) this.paginatorSubject.next('reset');
        this.backupData = JSON.parse(JSON.stringify(res.data));
        // console.log('getDataFromServer: ', this.backupData)
        // res.data.forEach(item => {
        //   item._data = JSON.parse(JSON.stringify(item))
        //   if(item.reservationCode === 'NOP-SOAO' || item.reservationCode === 'NOP-SO') {
        //     item.cancelReservation = true
        //   }
        //   if (item.createdDate){
        //     item.createdDate = new Date(item.createdDate)
        //   }
        //   if (item.fulfilledDate) {
        //     item.fulfilledDate = new Date(item.fulfilledDate)
        //   }
        //   if (item.endDateActive) {
        //     item.endDateActive = new Date(item.endDateActive)
        //   }
        //   if (['CSTK'].includes(<string>item.sourceSystem)){
        //     item._data.allowEdit = true;
        //   }else{
        //     item._data.allowEdit = false;
        //   }
        //   if(item.sourceSystem)item._sourceTxnRefHeaderIdLink = this.getSourceTxnRefHeaderIdLink(item.sourceSystem)
        // })
        let data = this.dataPreProcess(res.data)
        this.lisOfData = data
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // this.buildData()
        for(let i=0;i<res.data.length;i++){
          this.checkActionDisable(res.data[i].channelId);
        }
        //console.log(this.isActionEnable)
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
    }).catch(e => {
      // this.n.error("System",e.toString())
      this.showMessage('error', 'System', e.toString())
      // console.log(e.toString());
    }).finally(() => this.loadingSwitch = false)
  }

  refresh() {
    // this.seSku = ['']
    // this.sechannelValue = null
    // this.sepaymentStatus=null
    // this.secustomerType=null
    // this.ptable.reset()
    // this.ptable.sortOrder = this.initSortOrder
    // this.ptable.sortField = this.initSortField
    // this.order = this.initSortOrder
    // this.field = this.initSortField
    this.queryPannel.ipts.forEach((item) => {
      switch(item.title){
        case QUERYTITLE.CONDITION:
        case QUERYTITLE.CONDITIONOFPLANNEDTOALLOCATE:
          item.value = 4;
          item.disabled = true;
          break;
        case QUERYTITLE.VALUEOFRESERVATIONWITH:
        case QUERYTITLE.VALUEOFPLANNEDTOALLOCATE:
          item.value = null;
          item.showValue = null;
          item.disabled = true;
          break;
        default:
          item.value = null;
          item.showValue = null;
          break;
      }
    });

    // if(this.queryPannel.hasQuantity !== 'All') {
    //   this.queryPannel.hasQuantity = 'All'
    // }
    // this.search(true)
  }

  save(d, idx) {
    const {newid, edit, pickupDate, createDate, updateDate, reservationDate, ...swapDate} = d; // key need to remove from update payload
    let data = {...swapDate, id: d.id};
    /* data.createdDate = data.createdDate.getTime()
        data.fulfilledDate = data.fulfilledDate.getTime()
        data.endDateActive = data.endDateActive.getTime() */
    delete data._data;
    this.editShow = true;
    this.loadingSwitch = true
    this.commonService.httpPost(URLDICT.STOCK_RESERVATION_UPDATE, data)
    // HttpHelper.post(URLDICT.STOCK_RESERVATION_UPDATE, data)
    .then(res => {
      if (res.code === '000') {
        // d.edit = false
        // d.id = data.id
        // delete d.newid
        // this.changeitem(idx, data)
        this.updateRowData(d.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
        // this.search()
        // this.clear()
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', res.msg)
        this.resetQty(d, idx)
        this.loadingSwitch = false
      }
    }).catch(e => {
      // this.n.error("System",e.toString())
      this.showMessage('error', 'System', e.toString())
      this.resetQty(d, idx)
      this.loadingSwitch = false
    }).finally(
      () => {}
    )
  }

  editDetail(data) {
    // this.detail.currentState = 'edit'
    this.clonedLines[data.id] = {...data};
    data.edit = true
    // this.detail.selectRequest = data
    // this.dialogTitle = 'Stock Reservation - Edit'
    // this.editDialog = true
    // console.log('view request data', this.clonedLines);
  }

  edit(data) {

    if (data.createdDate == null) {
      data.createdDate = new Date(data.createdDate)
    }
    if (data.fulfilledDate == null) {
      data.fulfilledDate = new Date(data.fulfilledDate)
    }
    if (data.fulfilledDate == null) {
      data.endDateActive = new Date(data.endDateActive)
    }
    this.channels = JSON.parse(localStorage.getItem("REPOTREE"))[0].children
    if (data.id) {
      data.newid = data.id
      delete data.id
    }
    this.editShow = false;
    // console.log('The data to be edited is: ', data);
  }

  cancel(data, idx) {
    data.edit = false
    data.qtyPending = this.clonedLines[data.id].qtyPending
    data.qtyCancelled = this.clonedLines[data.id].qtyCancelled
    data.qtyAllocated = this.clonedLines[data.id].qtyAllocated
    data.sensitiveRemarks = this.clonedLines[data.id].sensitiveRemarks
    delete this.clonedLines[data.id];
    // this.clear()
    // if (d.newid) {
    //   const oldData = this.backupData.filter(item => item.id === d.newid)
    //   d.id = d.newid
    //   delete d.newid
    //   d.remark = oldData[0].remark
    //   this.changeitem(idx, oldData[0])
    //   this.editShow = true;
    // }
  }

  resetQty(data, idx){
    if(this.clonedLines[data.id]){
      data.qtyPending = this.clonedLines[data.id].qtyPending
      data.qtyCancelled = this.clonedLines[data.id].qtyCancelled
      data.qtyAllocated = this.clonedLines[data.id].qtyAllocated
    }
  }

  sortSearch(key) {
    if (this.field && this.field !== key) {
      this.order = undefined
    }
    this.field = key;
    this.isSortSearch = true
    // if(key === 'createdDate' || key === 'fulfilledDate'){
    this.order = !this.order ? 1 : -this.order
    // this.sortPageSearch()
    this.search(true)
  }

  page(e) {
    if(e===null)return
    // console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    let data: any = {...this.searchParams}
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    // if(this.isSortSearch) this.sortPageSearch()
    // else this.getDataFromServer(data)
    this.search(this.isSortSearch);
  }

  delete() {
    const len = this.selectedProducts.length
    if (len < 1) {
      // this.n.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      // this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    } else {
      const obj = {
        ids: this.selectedProducts.map(item => item.id + '')
      }
      this.m.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.loadingSwitch = true
          this.commonService.httpPost(URLDICT.STOCK_RESERVATION_DELETE, obj)
          // HttpHelper.post(URLDICT.STOCK_RESERVATION_DELETE, obj)
          .then(res => {
            if (res.code == '000') {
              this.search()
              this.selectedProducts = null;
              //清空选中并重赋数据源
              // this.allCheckBoxValue = false
              // this.mapOfCheckedId = {}
            } else {
              // this.n.error("System", "Submit Fail!")
              this.showMessage('error', 'System', 'Submit Fail!');
            }
            this.loadingSwitch = false
          }).catch(e => {
            // this.n.error("System", e.toString())
            this.showMessage('error', 'System', e.toString());
            this.loadingSwitch = false
          })
        }
      });
    }
  }

  toChannelLabel(v) {
    return CommonMethod.toSelectedLabel("REPO", v)
  }

  toSkuLabel(v) {
    return CommonMethod.toSelectedLabel("SKU", v)
  }
  hideDialog(ref){
    this.search()
    this.initQueryData()
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    // console.log(this.selectedColumns)
    this._selectedColumns = this.columns.filter(col => val.includes(col));
  }

  openUrl(id) {
    const url = 'https://here2serve--fta.lightning.force.com/lightning/n/Cart?orderID=' + id
    window.open(url, '_blank')
  }

  showMessage(severity, summary, detail) {
    this.n.add({severity, summary, detail});
  }

  create() {
    this.r.navigate(["main", 'stock_reservation', 'create']);
  }
  onTableValueInput(event, data, col){
    let value = event.value!=null?event.value:0;
    this.onTableValueChange(value, data, col)
  }
  onTableValueChange(event, data, col){
    let value = event!=null&&event>=0?event:0;
    switch(col.header){
      case 'qtyCancelled':
        data.qtyPending = data.qtyRequested - (data.qtyAllocated + value + data.qtyReleased)
        break;
      case 'qtyAllocated':
        data.qtyPending = data.qtyRequested - (value + data.qtyCancelled + data.qtyReleased)
        break;
    }
  }

  extendReleaseDate(e){
    this.loadingSwitch = true;
    this.stockReservationService.extendAutoReleaseDate(e.id).subscribe(
      res=>{
        if(res.code=='000'){
          this.updateRowData(e.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
        }else{
          this.showMessage('error', 'System', `Extend Fail! ${res.msg}`);
          this.loadingSwitch = false;
        }
      },
      (err)=>{
        this.showMessage('error', 'System', `Extend Fail! ${err}`);
        this.loadingSwitch = false;
      },
      ()=>{
      }
    )
  }
  cancelReservation(e) {
    if (e.qtyPlanToAllocate > 0) {
      this.showMessage('wran', 'System', `IR issued. Please return after replenishment sent.`);
      return
    }
    let data = {
      id: e.id
    }
    this.loadingSwitch = true;
    this.stockReservationService.cancelReservation(data).subscribe(res => {
      this.loadingSwitch = false;
      if (res.code === '000') {
        this.showMessage('success', 'System', `Cancel Reservation Succeeded!`);
        this.search(true, true)
      } else {
        this.showMessage('error', 'System', `Cancel Reservation Fail! ${res.msg}`);
      }
    })
  }
  updateRowData(id){
    return this.stockReservationService.searchReservationById(id).pipe(tap(
      res=>{
        if(res.code=='000'){
          let _rowIdx = this.lisOfData.findIndex(row=>row.id == id);
          if(_rowIdx>-1) {
            let data = this.dataPreProcess(res.data)
            let item = data[0]
            this.lisOfData[_rowIdx] = item;
            this.lisOfData = [...this.lisOfData];
          }
        }
      }
    ))
  }

  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss" )
  }

  getCOMUrl(comId: string, url: string){
    // let url: string = CONFIG.RESERVATION_COM_URL;
    // let result = url.replace('${1}', comId)
    // return result
    if(!url||!comId){
      return null
    }
    return `${url}/${comId}`
  }

  paginatorSubject = new Subject()

  @ViewChild('paginator', {static: false}) paginator: Paginator;
  resetPaginator(){
    // if(this.paginator) this.paginator.changePage(0);
    // this.pageIndex = 0;
    if(!this.paginator) return;
    var pc = this.paginator.getPageCount();
    this.paginator.first = 0;
    var state = {
      page: 0,
      first: this.paginator.first,
      rows: this.paginator.rows,
      pageCount: pc
    };
    this.paginator.updatePageLinks();
    this.paginator.onPageChange.emit(null); // paginator display not update without this line
    this.paginator.updatePaginatorState();
    this.paginator.pageLinks = [...this.paginator.pageLinks]
    this.cdRef.markForCheck();
    // this.paginator.changePage(0);
  }
  paginatorSub: Subscription
  initPaginatorSub(){
    if(this.paginatorSubject){
      if(this.paginatorSub) {
        this.paginatorSub.unsubscribe();
        this.paginatorSub = null;
      }
      this.paginatorSub = this.paginatorSubject.subscribe(e=>{
        if(e=='reset'){
          this.resetPaginator()
        }
      })
    }
  }

  ngOnDestroy() {
    if(this.paginatorSub)this.paginatorSub.unsubscribe()
    if(this.searchFieldRecordor)this.searchFieldRecordor.onDestory()
  }

  getSourceTxnRefHeaderIdLink(item:any){
    return item.sourceTxnRefHeaderURL!=null&&item.sourceTxnRefHeaderURL.length>0&&item.sourceTxnRefHeaderId!=null&&item.sourceTxnRefHeaderId.length>0
    // if(sourceSystem){return this._allowListBuffer['_sourceTxnRefHeaderIdLink'].indexOf(sourceSystem.toUpperCase())>-1}else{return false}
  }
  export(){
    if(this.lastSearchData){
      let data = {
        ...this.lastSearchData,
        pageIndex: 0,
        pageSize: 2147483647,
      }
      let columns = [
        ...this.frozenColumns
          .filter(col=>!['expandControl', 'action']
          .includes(col.type))
          .map(col=>{return {title: col.title, field: col.header}}),
        ...this.columns
          .map(col=>{return {title: col.title, field: col.header}}),
      ]
      this.loadingSwitch = true;
      this.stockReservationService.searchReservation(data).subscribe(res=>{
        this.loadingSwitch = false;
        let data = this.dataPreProcessForExport(res.data)
        CommonMethod.downloadXlsx(data, columns, `Reservation_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`, this.datepipe)
      }, e=>{
        this.loadingSwitch = false;
        this.showMessage('error', 'System', e.toString())
      })
    }
  }

  dataPreProcessForExport(data){
    return data.map(e=>{
      return {
        ...e,
        interfacedstatus: e.interfacedstatus=='null'?null:e.interfacedstatus
      }
    })
  }
}
