<illustration [name]="'Stock Take'"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel [store]="store.queryData" [headerTilte]="'Edit'"></app-query-pannel>

<!-- <p-panel [header]="'Add Items'" [toggleable]="true" class="p-col-12 p-mt-3 p-d-block p-p-0 p-shadow-1" *ngIf="store.bulkAddQueryPanel && store.isDraft">
  <div class="p-fluid p-grid">
    <ng-container *ngFor="let ipt of store.bulkAddQueryPanel.ipts; index as i">
      <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
      <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-treeSelect-cst>
      <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
      <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
    </ng-container>
    <div [class]="store.bulkAddQueryPanel.btnsclass">
      <button *ngFor="let btn of store.bulkAddQueryPanel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;" [disabled]="!store.showEdit"></button>
    </div>
  </div>
</p-panel> -->

<p-panel [header]="'Add Items'" [toggleable]="true" class="p-col-12 p-mt-3 p-d-block p-p-0 p-shadow-1" *ngIf="store.isAllowAddItem">
  <div class="p-fluid p-grid">
    <ng-container *ngFor="let ipt of store.filterAddPanel.ipts; index as i">
      <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
      <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-treeSelect-cst>
      <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
      <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
      <div *ngIf="ipt.type==null" [class]="ipt.class"></div>
    </ng-container>
    <div [class]="store.filterAddPanel.btnsclass">
      <button *ngFor="let btn of store.filterAddPanel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;"></button>
    </div>
  </div>
</p-panel>

<div class="p-shadow-1 cus-fix-scroll-y" #dropdownTarget>
  <div class="p-grid" *ngIf="store.isShowScan && store.haveSerialControlItem">
    <!-- <app-select-multiple-cst class="p-col-3" [class]="store.assignByIpt.class" [ipt]="store.assignByIpt"></app-select-multiple-cst>
    <div class="p-col p-d-flex p-align-center">
      <button pButton type="button" class="assignByBtn" [label]="'Filter'" (click)="store.filterAssignBy()"></button>
      <button pButton type="button" class="assignByBtn p-ml-2" [label]="'Reset'" (click)="store.clearFilterAssignBy()"></button>
    </div> -->
    <div class="p-col p-d-flex p-align-center">
      <button pButton type="button" class="assignByBtn" [label]="'Scan All'" (click)="store.showScanAllModal()"></button>
    </div>
  </div>
  <!-- <p class="p-fluid p-grid">
    <app-select-multiple-cst [ipt]="store.tableData.ipt"></app-select-multiple-cst>
  </p> -->
  <!-- <div class="p-mb-1">
    <p-button label="Add Stock Take Item" styleClass="p-button-sm" (click)="store.addItem()"></p-button>
  </div> -->
  <div class="p-fluid p-grid" *ngIf="store.isPending">
    <div class="p-col p-d-flex" *ngIf="store.isPending">
      <p-button class="p-mb-1" [disabled]="!(store.isPending && store.isAllowAssignBy)" label="Assign Action By" styleClass="p-button-sm" (onClick)="store.showAssignModal()"></p-button>
    </div>
    <!-- <app-select-multiple-cst class="p-p-2 action-by-select" *ngIf="!store.isDraft&&!store.isPending" [ipt]="store.actionByIpt" (change)="store.actionByIpt.change?store.actionByIpt.change(null,$event,store.actionByIpt):null"></app-select-multiple-cst> -->
  </div>
  <p-table
    [rowHover]="true"
    [paginator]="false"
    [showCurrentPageReport]="false"
    [value]="store.tableData.dataDisplay?store.tableData.dataDisplay:store.tableData.data"
    [columns]="store.tableData.head"
    [loading]="store.loading"
    [customSort]="true"
    [sortField]="store.tableData.sortField || null"
    [sortOrder]="store.tableData.sortOrder || null"
    (sortFunction)="store.customSort($event)"
    [scrollable]="true"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup >
      <col *ngIf="store.isDraft" style="width:100px">
      <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>

    <tr>
      <th id="action" *ngIf="store.isDraft" rowspan="2">Action</th>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent && col.parent.title" [attr.colspan]="col.parent.colspan?col.parent.colspan:1" [id]="col.parent">
          {{col.parent.title}}
        </th>
        <th *ngIf="!col.parent" rowspan="2" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
        <!-- <th *ngIf="!col.parent" rowspan="2" [id]="col.title">
          {{col.title}}
        </th> -->
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
        <!-- <th *ngIf="col.parent" [id]="col.title">
          {{col.title}}
        </th> -->
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr [class]="store.tableTagPrefix+rowIndex">
      <td *ngIf="store.isDraft">
        <div style="display: inline-flex">
          <p-button label="Delete" styleClass="p-button-text p-button-sm" (click)="store.delete(rowIndex, rowData)" [disabled]="store.status !== 'Draft' && rowData.isOldData"></p-button>
        </div>
      </td>
      <td *ngFor="let h of columns" [ngClass]="{'p-text-truncate':h.key==='itemDesc'}" [class]="store.tableTagPrefix+rowIndex+'-'+h.key">

        <!-- <ng-container *ngIf="(h.edit || rowData.isNew) && h.key == 'itemDesc' ">
          <p-dropdown class="p-dropdown-sm" [options]="store.tableData.ipt.options" [(ngModel)]="rowData._item" optionLabel="name" optionValue="code"
            [filter]="true" [filterBy]="'name'"
            [disabled]="store.status !== 'Draft' && rowData.isOldData"
            [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" placeholder="Select a Item" [showClear]="true" [style]="{'width':'100%'}" (onChange)="store.tableItemChange($event, rowData)">
          </p-dropdown>
        </ng-container> -->
        <!-- <p-inputNumber class="p-inputtext-sm" *ngIf="h.edit && h.type == 'input' && !rowData.isNew"  [(ngModel)]="rowData[h.key]" [style]="{'width':'100%'}" [disabled]="rowData.disabled"></p-inputNumber> -->
        <!-- <span *ngIf="(!h.edit && !rowData.isNew) || (rowData.isNew && h.key !== 'itemDesc')" [title]="rowData[h.key]">{{rowData[h.key]}}</span> -->
        <!-- <p-inputNumber class="p-inputtext-sm" *ngIf="h.edit && h.type == 'input' && (!rowData.isNew || store.status.includes('Progress'))"  [(ngModel)]="rowData[h.key]" [style]="{'width':'100%'}" [disabled]="rowData.disabled"></p-inputNumber>
        <span [title]="rowData[h.key]" *ngIf="(!h.edit && !rowData.isNew) || (rowData.isNew && h.key !== 'itemDesc' && !store.status.includes(h.count))" >{{rowData[h.key]}}</span> -->
        <ng-container *ngIf="h.type == 'amtInput'">
          <ng-container *ngIf="!rowData.isSerialControl || (rowData.isSerialControl && !rowData.isSerialControl[h.count])">
            <p-inputNumber [appCusPInputEvent]="'p-inputtext'" (onEnter)="store.onInputEnter($event, rowIndex, h.key, rowData)" class="p-inputtext-sm" *ngIf="store.showEdit && h.edit && h.type == 'input' && (!rowData.isNew || store.status.includes('Progress'))"
              [(ngModel)]="rowData[h.key]"  (ngModelChange)="store.lineValueChanged($event, rowIndex, h.key, rowData)" [style]="{'width':'100%'}" [disabled]="rowData.disabled"></p-inputNumber>
            <p-inputNumber [appCusPInputEvent]="'p-inputtext'" (onEnter)="store.onInputEnter($event, rowIndex, h.key, rowData)" class="p-inputtext-sm" *ngIf="store.showEdit && h.edit && h.type == 'amtInput' && (!rowData.isNew || store.status.includes('Progress'))"
              [(ngModel)]="rowData[h.key]" (ngModelChange)="store.lineValueChanged($event, rowIndex, h.key, rowData)" [style]="{'width':'100%'}" [disabled]="rowData.disabled || rowData[h.disabledKey]"></p-inputNumber>
            <span [title]="rowData[h.key]" *ngIf="(!h.edit && !rowData.isNew) || (rowData.isNew && h.key !== 'itemDesc' && !store.status.includes(h.count))" >{{rowData[h.key]}}</span>
          </ng-container>
          <ng-container *ngIf="rowData.isSerialControl && rowData.isSerialControl[h.count]">
            <div class="p-d-flex p-align-center">
              <span [title]="rowData[h.key]">{{rowData[h.key]}}</span>
              <span *ngIf="store.showEdit && h.type == 'amtInput' && rowData[h.key]!=null && h.count==store.stockTakeCount && store.isShowScan" class="p-ml-auto">
                <button [disabled]="store.showScan(rowData._data,h.key)" class="p-button-sm p-mr-1" pButton label="Scan" (click)="store.viewSerial(rowData, h.key)"></button>
              </span>
            </div>
          </ng-container>
        </ng-container>
        <span [title]="rowData[h.key]" *ngIf="h.type != 'amtInput'" >{{rowData[h.key]}}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectVisible]="false">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-blockUI [blocked]="store.loading"></p-blockUI>



<p-dialog [(visible)]="store.assignModal.visible" [modal]="true"
  [draggable]="false" [resizable]="false" [styleClass]="'modal'">
  <ng-template pTemplate="header">
    <h3 *ngIf="store.assignModal.title">{{store.assignModal.title}}</h3>
  </ng-template>
  <div class="p-fluid p-grid" *ngIf="store.isPending && store.isAllowAssignBy">
    <!-- <p-panel [header]="'Filter'" [toggleable]="true" class="p-col-12 p-mt-3 p-d-block p-p-0 p-shadow-1" *ngIf="store.filterPanel"> -->
      <div class="p-col-12">
        <div class="actionByPopupFilter p-p-2">
          <h4 class="p-mt-0 p-mb-2">Filter</h4>
          <div class="p-fluid p-grid">
            <ng-container *ngFor="let ipt of store.filterPanel.ipts; index as i">
              <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
              <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-treeSelect-cst>
              <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
              <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
            </ng-container>
            <div [class]="store.filterPanel.btnsclass">
              <button *ngFor="let btn of store.filterPanel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;"></button>
            </div>
          </div>
        </div>
      </div>
    <!-- </p-panel> -->
    <div class="p-col">
      <span class="p-float-label">
        <input type="text" #inputtext pInputText [(ngModel)]="store.assignModal.staffId">
        <label for="inputtext">{{store.assignModal.inputTitle}}</label>
      </span>
    </div>
    <div class="p-col flex-grow-0">
      <div class="p-d-flex">
        <div class="p-mr-2">
          <p-button label="Clear" styleClass="p-button-sm p-button-outlined cus-btn-align-height" (click)="store.clearAssignModalInput()"></p-button>
        </div>
        <div class="p-mr-2">
          <p-button label="Assign" styleClass="p-button-sm cus-btn-align-height" (click)="store.applyAssignModalActionBy()"></p-button>
        </div>
        <div>
          <p-button label="Assign All " styleClass="p-button-sm cus-btn-align-height p-text-nowrap" (click)="store.applyAssignModalActionByToExistRow()"></p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="cus-fix-scroll-y">
    <p-table
      [rowHover]="false"
      [paginator]="false"
      [showCurrentPageReport]="false"
      [value]="store.assignModal.table.data"
      [columns]="store.assignModal.table.head"
      [customSort]="true"
      [scrollable]="true"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup >
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th *ngIf="col.key=='checkbox'" [id]="col.title">
              <p-checkbox binary="true" [disabled]="!(store.isPending && store.isAllowAssignBy)" [(ngModel)]="store.assignModal.table.selectAll" (ngModelChange)="store.assignModalSelectAllChange($event)"></p-checkbox>
            </th>
            <th *ngIf="col.key!='checkbox'" [id]="col.title">
              {{col.title}}
            </th>
            <!-- <th *ngIf="col.key!='checkbox'" pSortableColumn="{{col.key}}" pReorderableColumn [id]="col.title">
              {{col.title}}
              <p-sortIcon field="{{col.key}}"></p-sortIcon>
            </th> -->
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let h of columns" [class]="{'p-text-truncate':h.key==='itemDesc'}">
            <p-checkbox *ngIf="h.type=='checkbox'" binary="true" [(ngModel)]="rowData[h.key]" (ngModelChange)="store.assignModalSelectChange($event)"></p-checkbox>
            <span *ngIf="!h.type" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="store.cancelAssignActionBy()"></button>
    <button pButton pRipple label="Confirm" icon="pi pi-check" [disabled]="!(store.isPending && store.isAllowAssignBy)" (click)="store.assignActionBy()"></button>
  </ng-template>
</p-dialog>
<app-compare-serial [store]="store.compareSerialModalConfig"></app-compare-serial>

<app-serial-cst #serialcst [store]="store"
[serialList]="store.serialModalConfig.serialList"
[loadingSwitch]="store.loading"
[(visible)]="store.serialModalConfig.visible"
(onMultiDelete)="store.onMultiDelete($event)"
(onResult)="store.addSerialByScanner($event)"></app-serial-cst>

<app-serial-scanall-cst #serialscanallcst
[config]="store.scanallModalConfig"
[existSerialList]="store.scanallModalConfig.existSerialList"
[selectedItemList]="store.tableData.data"
[currentTakeLineSerialList]="store.currentLineSerialFullList"
[(visible)]="store.scanallModalConfig.visible"
(onHide)="store.hideScanAllModal()"
(onCancel)="store.hideScanAllModal()"
(onConfirm)="store.addSerialToDataListByItemCondition($event)"></app-serial-scanall-cst>

<ngx-file-drop class="p-d-none" dropZoneLabel="Drop files here" #fileDropper (onFileDrop)="store.fileDropped($event)" [accept]="'.csv,.txt'"
  [dropZoneClassName]="'custom-drop-zone'" [contentClassName]="'custom-drop-content'">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <!-- <div class="custom-drop-content-content-selector" (click)="openFileSelector()">
      <div><i class="pi pi-cloud-upload dragndrop-upload-icon"></i></div>
      <div>Drop File or Click to Browse</div>
    </div> -->
  </ng-template>
</ngx-file-drop>
