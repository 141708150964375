<span #wrapper class="p-float-label p-input-icon-right" (click)="openSelectPanel()" style="width: 100%;">
  <span class="cus-required-label" *ngIf="ipt.required">*</span>
  <i *ngIf="ipt.value&&!ipt.disabled" class="pi pi-times" (click)="clearSelectData()"></i>
  <i class="pi pi-chevron-down" [ngClass]="{'cus-i-disabled':ipt.disabled}"></i>
  <input class="p-inputtext-sm" type="text" [attr.id]="ipt.name" [class]="{'p-input-sm':true, 'ng-invalid':ipt.error,'ng-dirty':ipt.error, 'cursor-pointer':!ipt.disabled}"
  pInputText [(ngModel)]="ipt.showValue" [disabled]="ipt.disabled" autocomplete='off' style="width: 100%;" readonly='readonly' 
  (keydown)="onKeydown($event)">
  <label [for]="ipt.name" *ngIf="ipt.title">{{ipt.title}}</label>
</span>
<!-- <div class="tree-panel" [ngClass]="{'tree-level':isOpen}" *ngIf="isOpen"
  [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
  (keydown)="onKeydown($event)"
>
  <p-tree *ngIf="isOpen" [value]="_treeOptions" selectionMode="single" [(selection)]="ipt.value" scrollHeight="230px"
    (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [filter]="true"
    [class]="{'p-tree-sm':true, 'ng-invalid':ipt.error,'ng-dirty':ipt.error}" styleClass="tree-panel-content p-dropdown-panel">
  </p-tree>
</div> -->
<p-overlayPanel #overlay [appendTo]="'body'" (onHide)="onOverlayHide($event)">
  <ng-template pTemplate>
    <div class="tree-panel" [style.--input-wrapper-width]="inputWrapperWidth" [ngClass]="{'tree-level':isOpen}" (keydown)="onKeydown($event)">
      <p-tree *ngIf="isOpen" [value]="_treeOptions" selectionMode="single" [(selection)]="ipt.value" scrollHeight="230px"
        (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [filter]="true"
        [filterMode]="'strict'"
        [class]="{'p-tree-sm':true, 'ng-invalid':ipt.error,'ng-dirty':ipt.error}" styleClass="tree-panel-content p-dropdown-panel">
      </p-tree>
    </div>
  </ng-template>
</p-overlayPanel>
