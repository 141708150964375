<illustration [name]="store.breadcrumb"></illustration>
<p-progressBar [style.visibility]="store.showSearchLoading"></p-progressBar>
<app-query-pannel class="query-panel" [store]="store.queryData"></app-query-pannel>
<app-add-pannel *ngIf="store.addData.show" class="add-panel" [store]="store.addData"></app-add-pannel>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <app-multi-select
      [options]="store.tableData.columns"
      [(ngModel)]="store.tableData.selectedColumns"
      [autofocusFilter]="true"
      optionLabel="title"
      selectedItemsLabel="{{store.tableData.selectedColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.selectedColumns.length +' columns displayed'}}"
      selectedItemsLabel="{0} columns displayed"
      [style]="{minWidth: '200px'}"
      placeholder="Choose Columns"
    ></app-multi-select>
  </div>
  <p-table #ptable dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="true"
    [scrollable]="true"
    [(selection)]="store.tableData.selectedProducts"
    [resizableColumns]="store.tableData.editShow"
    [reorderableColumns]="store.tableData.editShow"
    [value]="store.tableData.data"
    [columns]="store.tableData.selectedColumns"
    [loading]="store.tableData.loadingSwitch"
    [sortField]="store.tableData.initSortField"
    [sortOrder]="store.tableData.initSortOrder"
    [frozenColumns]="store.tableData.frozenColumns"
    [frozenWidth]="store.tableData.frozenWidth"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [title]="col.fullTitle || col.title"
          (click)="store.sortSearch(col.field)"
          [id]="col.title"
        >
          {{col.title}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px">
        <td *ngFor="let col of columns" (click)="store.openDet(data)">
          <div *ngIf="store.showSearch && col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="store.showSearch &&data.isEdit" pButton label="Save" (click)="store.rowSave(data)" [disabled]="!store.docEditEnable"></button>
            <button class="p-button-sm p-button-outlined p-mr-1" *ngIf="store.showSearch && data.isEdit" pButton label="Cancel" (click)="store.rowCancel(data, rowIndex)" [disabled]="!store.docEditEnable"></button>

            <button *ngIf="store.showSearch && !data.isEdit && !data.action" class="p-button-sm p-mr-1" pButton label="Edit" (click)="store.rowEdit(data, rowIndex)" [disabled]="!store.docEditEnable"></button>
            <button *ngIf="store.showSearch && !data.isEdit && !data.action" class="p-button-sm p-mr-1" pButton [label]="'Delete'" (click)="store.rowDelete(data, rowIndex)" [disabled]="!store.docEditEnable"></button>
          </div>
          <span *ngIf="col.type==='select'">
            <span *ngIf="data.edit" class="p-input-icon-right" (click)="store.openSelect($event, data, op)">
              <i class="pi pi-chevron-down"></i>
              <input type="text" pInputText [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
            </span>
            <span *ngIf="!data.edit" [title]="data[col.field]">{{data[col.field]}}</span>
          </span>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="12" style=" text-align: center;">{{frozen.length === 1 ? '' : 'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <p-listbox [options]="store.days" [(ngModel)]="store.selected" optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}" (onChange)="store.selectDay($event, op)"></p-listbox>
  </ng-template>
</p-overlayPanel>
