<p-dialog
  [(visible)]="store.modalConfig.visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'modal'"
  class="pre-allocate-modal"
  (onHide)="store.setModalVisible(false)">
  <ng-template pTemplate="header">
    <h3 class="modal-header" *ngIf="store.modalConfig.title">{{store.modalConfig.title}}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="popup-body-wrapper" [appResizeSensor]="true" (onResize)="popupBodyResized($event)">
      <div class="popup-left-sticker">
        <div class="popup-left-panel-wrapper">
          <div class="popup-left-panel-body">
            <div class="popup-left-panel-content">
              <div class="p-fluid p-mt-2">
                <span class="p-float-label">
                  <input id="leftpanelfilter" type="text" pInputText [(ngModel)]="store.modalConfig.leftPanelFilterText" (ngModelChange)="store.leftPanelFilter($event)">
                  <label for="leftpanelfilter">Item Filter</label>
                </span>
              </div>
              <app-pre-allocate-left-item-panel
                class="popup-left-panel-items"
                [itemList]="store.modalConfig.items"
                [changedItemListCode]="store.modalConfig.changedItemCodeList"
                [savedItemListCode]="store.modalConfig.savedItemCodeList"
                [selectedItem]="store.modalConfig.selectedItem"
                [filterItemCode]="store.modalConfig.filteredCodeList"
                (onItemClick)="store.popupLeftItemPanelOnClick($event)"
              ></app-pre-allocate-left-item-panel>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-main-panel">
        <div class="p-fluid p-grid">
          <div class="p-xl-6 p-col-12 p-mt-2">
            <div class="p-px-2 p-pt-3 p-pb-1 preallocatetopgrid h-100">
              <div class="p-fluid p-grid h-100">
                <div class="p-col-12 p-md-12 p-lg-6 p-mb-auto">
                  <span class="p-float-label">
                    <input id="fromDate" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.rangeDate">
                    <label for="fromDate">Reservation From Date</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6 p-mb-auto">
                  <span class="p-float-label">
                    <p-calendar  [appendTo]="'body'" [inputId]="'toDate'" dateFormat="yy/mm/dd" [(ngModel)]="store.modalConfig.preAllocateDate" [showIcon]="true" inputId="icon"></p-calendar>
                    <label for="toDate">Reservation To Date</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-auto">
                  <div class="p-fluid p-d-flex">
                    <div class="p-ml-auto p-mr-1">
                      <button class="p-button-sm p-mr-1" pButton label="Reload By Date" (click)="store.searchReservation()"></button>
                    </div>
                    <!-- <div>
                      <button class="p-button-sm p-mr-1" pButton label="Save" (click)="store.saveItem()"></button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-xl-6 p-col-12 p-mt-2">
            <div class="p-px-2 p-pt-3 p-pb-1 preallocatetopgrid">
              <div class="p-fluid p-grid">
                <div class="p-col-12 p-md-12 p-lg-6">
                  <span class="p-float-label">
                    <input id="preallocatewhqtybefore" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.totalCountBefore">
                    <label for="preallocatewhqtybefore">{{store.modalConfig.warehouseTitle}} Qty Before Pre-Allocate</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                  <span class="p-float-label">
                    <input id="preallocatewhqtyafter" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.totalCountAfter">
                    <label for="preallocatewhqtyafter">{{store.modalConfig.warehouseTitle}} Qty After Pre-Allocate</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                  <span class="p-float-label">
                    <input id="preallocateaopending" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.totalAOPending">
                    <label for="preallocateaopending">AO Reservation Pending Qty</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                  <span class="p-float-label">
                    <input id="preallocatedopending" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.totalDOPending">
                    <label for="preallocatedopending">DO Reservation Pending Qty</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                  <span class="p-float-label">
                    <input id="preallocatetotalpending" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.totalPending">
                    <label for="preallocatetotalpending">Total Reservation Pending Qty</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6" *ngIf="store.modalConfig.schoolNumber">
                  <span class="p-float-label">
                    <input id="schoolNo" type="text" pInputText disabled  [(ngModel)]="store.modalConfig.schoolNumber">
                    <label for="schoolNo">{{store.modalConfig.schoolTitle}}</label>
                  </span>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6" [ngClass]="{'p-lg-12':store.modalConfig.rightSideInputElementAmt%2==0}">
                  <div class="p-fluid p-d-flex">
                    <div class="p-ml-auto p-mr-1">
                      <span class="p-float-label">
                        <p-inputNumber inputId="inputnumber" [(ngModel)]="store.modalConfig.changeCount"></p-inputNumber>
                        <label for="inputtext" class="p-text-nowrap">Pre-Allocate Qty</label>
                      </span>
                    </div>
                    <div>
                      <button class="p-button-sm p-mr-1 p-px-2" pButton label="Calculate" (click)="store.calculate()"></button>
                    </div>
                    <!-- <div>
                      <button class="p-button-sm p-mr-1" pButton label="Save" (click)="store.saveItem()"></button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-lg-6 p-col">
            <div class="p-fluid p-d-flex">
              <span class="p-float-label">
                <input id="sourceRefNoFilter" type="text" pInputText [(ngModel)]="store.modalConfig.sourceRefNoFilter" (keydown.enter)="store.preAllocateTableSourceRefNoFilter()">
                <label for="sourceRefNoFilter">Source Ref. No</label>
              </span>
              <div class="p-ml-1">
                <button class="p-button-sm" pButton label="Filter" (click)="store.preAllocateTableSourceRefNoFilter()"></button>
              </div>
            </div>
          </div>
          <div class="p-lg-6 p-col flex-grow-0">
            <div class="p-fluid p-d-flex">
              <div class="p-mr-1 p-ml-auto">
                <button class="p-button-sm p-button-outlined" pButton label="Clear" (click)="store.resetItem()"></button>
              </div>
              <div>
                <button class="p-button-sm" pButton label="Save" (click)="store.saveItem()"></button>
              </div>
            </div>
          </div>
        </div>
        <p-table #ptable dataKey="id"
          [columns]="store.modalConfig.table.columns"
          [frozenColumns]="store.modalConfig.table.frozenColumns"
          [frozenWidth]="store.modalConfig.table.frozenWidth"
          [scrollable]="true"
          styleClass="p-datatable-sm p-datatable-striped custom-table"
          [loading]="store.tableData.loadingSwitch"
          [value]="store.modalConfig.table.data"
          [(selection)]="store.modalConfig.table.selected">
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style]="{'width': col.width || '80px'}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr style="height: 45px">
              <!-- <th style="width: 3rem"></th> -->
              <ng-container *ngFor="let col of columns">
                <th 
                  *ngIf="col.field=='action'">
                  {{col.title}}
                </th>
                <th 
                  *ngIf="col.field!=='action'"
                  pReorderableColumn
                  [pSortableColumn]="col.field">
                  {{col.title}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr style="height: 47px" [ngClass]="{'filtered': store.modalConfig.filteredIdList&&store.modalConfig.filteredIdList.indexOf(rowData.id)>-1}">
              <ng-container *ngFor="let col of columns">
                <td *ngIf="col.field==='action'"><p-tableCheckbox [value]="rowData" (click)="store.change(rowData, store.modalConfig.selTotalCount===0)" [disabled]="store.modalConfig.selTotalCount===0"></p-tableCheckbox></td>
                <td *ngIf="col.type=='date'" class="ui-resizable-column p-text-truncate"><span [title]="rowData[col.field]|date:'yyyy/MM/dd'">{{rowData[col.field]|date:'yyyy/MM/dd'}}</span></td>
                <td *ngIf="!col.type" class="ui-resizable-column p-text-truncate"><span [title]="rowData[col.field]">{{rowData[col.field]}}</span></td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="9" style=" text-align: center;">No record found.</td>
              <td [attr.colspan]="columns.length" style=" text-align: center;" [ngClass]="{hidden: columns===store.modalConfig.table.frozenColumns}">No record found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Calculate All Item" icon="pi pi-cog" (click)="store.calculateAll()"></button>
    <button pButton pRipple label="Save All Item" icon="pi pi-check" (click)="store.saveAllPreAllocate()"></button>
    <button pButton pRipple label="Remove Pre-Allocate" icon="pi pi-trash" (click)="store.removePreAllocate()"></button>
    <button pButton pRipple label="Back" icon="pi pi-times" class="p-button-outlined" (click)="store.setModalVisible(false)"></button>
  </ng-template>
</p-dialog>
