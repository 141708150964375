<div>

  <div>
    <illustration [name]="queryTitle" [path]="'Stock In & Out'"></illustration>
  </div>
  <app-query-pannel class="query-panel" [store]="queryPannel"></app-query-pannel>
  <app-add-pannel *ngIf="showC" [store]="createPannel"></app-add-pannel>

  <div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
    <div class="p-d-flex p-jc-between p-mb-2">
      <span class="panel-title"></span>
      <p-multiSelect
          [options]="cols"
          [(ngModel)]="selectedColumns"
          optionLabel="title"
          [selectedItemsLabel]="selectedColumns.length === cols.length ? 'ALL columns' : selectedColumns.length +' columns displayed'"
          [style]="{minWidth: '200px'}"
          placeholder="Choose Columns"
        ></p-multiSelect>
    </div>
    <!-- <div class="btns p-mb-2">
      <button *ngIf="showD" pButton class="p-button-outlined p-button-sm" label="Delete" (click)="delete()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
    </div> -->
    <p-table
      styleClass="p-datatable-sm p-datatable-striped"
      class="cus-fix-scroll-y"
      [rowHover]="true"
      [scrollable]="false"
      [value]="lisOfData"
      [(selection)]="selectedProducts"
      [resizableColumns]="true"
      [reorderableColumns]="true"
      [columns]="selectedColumns"
      [customSort]="true"
      [sortField]="field"
      [sortOrder]="order"
      [loading]="loadingSwitch"
      dataKey="id"
    >

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <!-- <col style="width:3rem"> -->
          <col *ngFor="let col of selectedColumns" [style]="{'width': col.width || 'auto'}">
          <col style="width:160px">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!-- <th style="text-align: center;" id="h1">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
          <th
            *ngFor="let col of selectedColumns"
            (click)="sortSearch(col.header)"
            pResizableColumn
            pReorderableColumn
            [pSortableColumn]="col.header"
            [title]="col.fullTitle || col.title"
            [id]="col.title"
          >
            {{col.title}}
            <p-sortIcon [field]="col.header"></p-sortIcon>
          </th>
          <th id="action">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index let-columns="columns" let-expanded="expanded">
        <tr>
          <!-- <td style="text-align: center;">
            <p-tableCheckbox [value]="data"></p-tableCheckbox>
          </td> -->
          <td *ngFor="let h of columns">
            <div *ngIf="h.header === 'roleId'">
              <span *ngIf="data.id" [title]="data.roleName">{{data.roleName}}</span>

              <input *ngIf="!data.id" pInputText class="p-inputtext-sm" style="width: 100%" [(ngModel)]="data.roleName" class="active"/>
            </div>
            <div *ngIf="h.header === 'descriptionId'">
              <span *ngIf="data.id" [title]="data.description">{{data.description}}</span>
              <input *ngIf="!data.id" pInputText class="p-inputtext-sm" style="width: 100%" [(ngModel)]="data.description" class="active"/>
            </div>
          </td>

          <td>
            <div style="display: inline-flex;overflow-x: auto">
              <button class="row-btn p-button-sm p-button-outlined p-mr-1" *ngIf="!data.id && showU" pButton label="Cancel" (click)="cancel(data)" [disabled]="!roleEditEnable"></button>
              <button class="row-btn p-button-sm p-mr-1" *ngIf="data.id && showU"  pButton label="Edit" (click)="edit(data)" [disabled]="!roleEditEnable"></button>
              <button class="row-btn p-button-sm p-mr-1" *ngIf="!data.id && showU" pButton label="Save" (click)="editSave(data)" [disabled]="!roleEditEnable"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length+2" style=" text-align: center;">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="page($event)"></p-paginator>
  </div>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
