<illustration [name]="store.breadcrumb"></illustration>
<p-progressBar [style.visibility]="store.showSearchLoading"></p-progressBar>
<app-query-pannel class="query-panel" [store]="store.queryData"></app-query-pannel>
<app-add-pannel *ngIf="store.addData.show" class="add-panel" [store]="store.addData"></app-add-pannel>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <p-table styleClass="p-datatable-sm p-datatable-striped" dataKey="rowId"
  [value]="store.tableData.data"
  [columns]="store.tableData.selectedColumns"
  [loading]="store.tableData.loadingSwitch" >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th style="width: 11rem">Action</th>
        <th pSortableColumn="channelCode" (click)="store.sortSearch('channelCode')">Channel<p-sortIcon field="channelCode"></p-sortIcon></th>
        <th pSortableColumn="itemCode" (click)="store.sortSearch('itemCode')">Item<p-sortIcon field="itemCode"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-expanded="expanded">
      <tr>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="store.onRowExpand(expanded, data)"></button>
        </td>
        <td>
          <button class="p-button-sm p-mr-1" *ngIf="store.showSearch &&data.isEdit" pButton label="Save" (click)="store.rowSave(data)" [disabled]="!store.stockAvailabilityLevelEditEnable"></button>
          <button class="p-button-sm p-button-outlined p-mr-1" *ngIf="store.showSearch && data.isEdit" pButton label="Cancel" (click)="store.rowCancel(data)" [disabled]="!store.stockAvailabilityLevelEditEnable"></button>

          <button *ngIf="store.showSearch && !data.isEdit && expanded" class="p-button-sm p-mr-1" pButton label="Edit" (click)="store.rowEdit(data)" [disabled]="!store.stockAvailabilityLevelEditEnable"></button>
          <button *ngIf="store.showSearch && !data.isEdit && data.deleted && expanded" class="p-button-sm p-mr-1" pButton [label]="'Delete'" (click)="store.rowDelete(data)" [disabled]="!store.stockAvailabilityLevelEditEnable"></button>
        </td>
        <td>{{data.channelCode||'ALL'}}</td>
        <td>{{data.itemCode||'ALL'}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-data>
      <tr class="expand-row">
        <td colspan="4" style="padding: 0 2rem">
          <div>
            <p-table [value]="data.lines" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <!-- <th style="width: 11rem">Action</th> -->
                  <th>Label</th>
                  <th>From</th>
                  <th  style="width: 24rem">To</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-line>
                <tr>
                  <!-- <td>
                    <button class="p-button-sm p-mr-1" *ngIf="store.showSearch &&data.isEdit" pButton label="Save" (click)="store.rowSave(data)"></button>
                    <button class="p-button-sm p-button-outlined p-mr-1" *ngIf="store.showSearch && data.isEdit" pButton label="Cancel" (click)="store.rowCancel(data, line)"></button>

                    <button *ngIf="store.showSearch && !data.isEdit" class="p-button-sm p-mr-1" pButton label="Edit" (click)="store.rowEdit(data)"></button>
                    <button *ngIf="store.showSearch && !data.isEdit && data.deleted" class="p-button-sm p-mr-1" pButton [label]="'Delete'" (click)="store.rowDelete(line)"></button>
                  </td> -->
                  <td>{{line.description}}</td>
                  <td>{{line.availLevelFrom}}</td>
                  <td>
                    <p-inputNumber *ngIf="data.edit && !line.endLine" class="p-inputtext-sm" [(ngModel)]="line.availLevelTo" [inputStyle]="{'width': '23em'}" [min]="0"></p-inputNumber>
                    <span *ngIf="!data.edit" [title]="line.availLevelTo">{{line.availLevelTo}}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4" style="text-align: center;">No record found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <p-listbox [options]="store.labels" optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}" (onChange)="store.selectLabel($event, op)"></p-listbox>
  </ng-template>
</p-overlayPanel>
