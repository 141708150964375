<illustration name="Stock Balance Enquiry"></illustration>
<p-panel header="FILTER" class="p-col-12 p-p-0" [toggleable]="true">
    <div class="p-fluid p-grid">
      <ng-container *ngFor="let ipt of store.queryPannel.ipts; index as i">
        <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt"></app-input-cst>
        <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" ></app-select-cst>
        <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" ></app-treeSelect-cst>
        <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt"></app-multipleTreeSelect-cst>
        <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt"></app-daterange-cst>
        <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt"></app-datetime-cst>
        <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt"></app-select-multiple-cst>
        <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15" [ipt]="ipt"></app-checkboxt-cst>
      </ng-container>
      <div [class]="store.queryPannel.btnsclass">
        <button *ngFor="let btn of store.queryPannel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;" [disabled]="btn.disabled"></button>
      </div>
    </div>
</p-panel>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex p-jc-between p-mb-2">
    <p-tabView class="p-ml-6" [activeIndex]="store.activeIndex" (onChange)="store.onChange($event)">
      <p-tabPanel header="Header 1">
      </p-tabPanel>
      <p-tabPanel header="Header 2">
      </p-tabPanel>
      <p-tabPanel header="Header 3">
      </p-tabPanel>
    </p-tabView>
  </div>
  <p-table #ptable dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="true"
    [scrollable]="true"
    [value]="store.tableData.data"
    [columns]="store.tableData.selectedColumns"
    [loading]="store.tableData.loadingSwitch"
    [resizableColumns]="store.tableData.editShow"
    [reorderableColumns]="store.tableData.editShow"
    [frozenColumns]="store.tableData.frozenColumns"
    [frozenWidth]="store.tableData.frozenWidth"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [title]="col.fullTitle || col.title"
          [id]="col.title"
        >
          {{col.title}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px">
        <td *ngFor="let col of columns">
          <span [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="12" style=" text-align: center;">No record found.</td></tr>
    </ng-template>
  </p-table>
  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
