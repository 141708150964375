<illustration [name]="store.breadcrumb "></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel headerTilte="Demand Cycle" [store]="store.parentData"></app-query-pannel>
<!-- <illustration [name]="store.subBreadcrumb "></illustration> -->
<app-add-pannel [headerTilte]="store.subBreadcrumb" [store]="store.queryData"></app-add-pannel>
<!-- table module -->
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <p-multiSelect
      [options]="store.tableData.columns"
      [ngModel]="store.tableData.selectedColumns"
      (ngModelChange)="store.tableSelectedColumnsChange($event)"
      optionLabel="title"
      selectedItemsLabel="{{store.tableData.selectedColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.selectedColumns.length +' columns displayed'}}"
      selectedItemsLabel="{0} columns displayed"
      [style]="{minWidth: '200px'}"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <p-table #ptable dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="true"
    [scrollable]="true"
    [(selection)]="store.tableData.selectedProducts"
    [resizableColumns]="store.tableData.editShow"
    [reorderableColumns]="store.tableData.editShow"
    [value]="store.tableData.data"
    [columns]="store.tableData.selectedColumns"
    [loading]="store.tableData.loadingSwitch"
    [sortField]="store.tableData.initSortField"
    [sortOrder]="store.tableData.initSortOrder"
    [frozenColumns]="store.tableData.frozenColumns"
    [frozenWidth]="store.tableData.frozenWidth"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [title]="col.fullTitle || col.title"
          (click)="store.sortSearch(col.field)"
          [id]="col.title"
        >
          {{col.title}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px">
        <td *ngFor="let col of columns" class="p-text-truncate" (click)="store.openDet(data)">
          <div *ngIf="store.showU && col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="store.showU && (data.status==='DRAFT'||data.status==='VERIFIED') && !data.gtEnd" pButton label="Delete" (click)="store.deleteRow($event, data)"></button>
          </div>
          <span *ngIf="col.type==='select'">
            <span *ngIf="data.edit" class="p-input-icon-right" (click)="op.toggle($event)">
              <i class="pi pi-chevron-down"></i>
              <input type="text" pInputText [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
            </span>
            <span *ngIf="!data.edit" [title]="data[col.field]">{{data[col.field]}}</span>
          </span>
          <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
          <p-inputNumber *ngIf="col.type==='number' && data.edit"class="p-inputtext-sm" [(ngModel)]="data[col.field]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
          <span *ngIf="col.type==='number' && !data.edit" [title]="data[col.field]">{{data[col.field]}}</span>
          <span *ngIf="col.type==='date'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span>
          <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</span>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="12" style=" text-align: center;">{{frozen.length === 1 ? '' : 'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectVisible]="false"></p-confirmDialog>
<div *ngIf="store.loading" class="mask" (click)="store.stopEvent($event)"></div>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <p-listbox [options]="store.skus" [(ngModel)]="store.selected" optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}"></p-listbox>
  </ng-template>
</p-overlayPanel>
