<illustration name="Item Master"></illustration>
<app-query-pannel [store]="store.queryData"></app-query-pannel>
<div class="p-shadow-1">
  <div class="p-d-flex p-mb-2 align-items-center">
    <button type="button" class="p-ml-auto p-mr-2" label="Export" pButton (click)="store.exportXlsx()"></button>
    <p-multiSelect
        [options]="store.tableData.columns"
        [ngModel]="store.tableData.visibleColumns"
        (ngModelChange)="store.tableSelectedColumnsChange($event)"
        optionLabel="title"
        selectedItemsLabel="{{store.tableData.visibleColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.visibleColumns.length + ' columns displayed'}}"
        [style]="{minWidth: '200px'}"
        placeholder="Choose Columns"
      ></p-multiSelect>
  </div>

  <p-table #ptable dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="true"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    columnResizeMode="expand"
    [value]="store.tableData.data"
    [columns]="store.tableData.visibleColumns"
    [loading]="store.tableData.loadingSwitch"
    [sortField]="store.tableData.sortField.field"
    [sortOrder]="store.tableData.sortField.order"
    [scrollable]="true"
    (sortFunction)="store.sortSearch($event.field, $event.order)"
    [customSort]="true"
    scrollHeight="500px">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of store.tableData.visibleColumns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of store.tableData.visibleColumns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [title]="col.fullTitle || col.title"
          [id]="col.tilte"
        >
          {{col.title}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
      <tr>
        <td *ngFor="let col of columns" class="p-text-truncate">
          <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</span>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="store.tableData.visibleColumns.length" style=" text-align: center;">No record found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
