<illustration name="Stock Take"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.tableData.loadingSwitch"></p-progressBar>
<app-query-pannel [store]="store.queryData" [headerTilte]="'Detail'" [disableBtn]="store.tableData.loadingSwitch"></app-query-pannel>
<div class="p-shadow-1">
  <div class="p-grid" *ngIf="store.isShowFilterBy">
    <div class="p-fluid p-col-3">
      <app-select-multiple-cst [class]="store.assignByIpt.class" [ipt]="store.assignByIpt"></app-select-multiple-cst>
    </div>
    <div class="p-col p-d-flex p-align-center">
      <button pButton type="button" class="cus-btn-auto" [label]="'Filter'" (click)="store.filterAssignBy()"></button>
      <button pButton type="button" class="cus-btn-auto p-ml-2" [label]="'Reset'" (click)="store.clearFilterAssignBy()"></button>
    </div>
  </div>

  <p-table
    [rowHover]="true"
    [paginator]="false"
    [showCurrentPageReport]="false"
    [resizableColumns]="true"
    [value]="store.tableData.dataDisplay?store.tableData.dataDisplay:store.tableData.data"
    [columns]="store.tableData.head"
    [loading]="store.tableData.loadingSwitch"
    [sortField]="store.tableData.sortField || null"
    [sortOrder]="store.tableData.sortOrder || null"
    [customSort]="true"
    (sortFunction)="store.customSort($event)"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent && col.parent.title" [attr.colspan]="col.parent.colspan?col.parent.colspan:1" [id]="col.parent">
          {{col.parent.title}}
        </th>
        <th *ngIf="!col.parent" rowspan="2" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
        <!-- <th *ngIf="!col.parent" rowspan="2" [id]="col.title">
          {{col.title}}
        </th> -->
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
        <!-- <th *ngIf="col.parent" [id]="col.title">
          {{col.title}}
        </th> -->
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <ng-container *ngFor="let h of columns">
        <td *ngIf="!h.type" class="p-text-truncate"><span [title]="rowData[h.key]">{{rowData[h.key]}}</span></td>
        <td *ngIf="h.type=='compareSerial'" class="p-text-truncate">
          <a *ngIf="store.allowCompareSerial[rowData[store.rowDataUniqueKey]+'_'+h.key]" class="cus-clickable" (click)="store.compareStockTakeLineSerial(rowData, h.key, h.count)">
            {{rowData[h.key]}}
          </a>
          <span *ngIf="!store.allowCompareSerial[rowData[store.rowDataUniqueKey]+'_'+h.key]">{{rowData[h.key]}}</span>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
</div>

<p-dialog [(visible)]="store.uploadModalConfig.visible" [modal]="true" (onHide)="store.onUploadModalHide()"
  [draggable]="false" [resizable]="false" [styleClass]="'cus-modal cus-modal-lg'">
  <ng-template pTemplate="header">
    <h3 *ngIf="store.uploadModalConfig.title">{{store.uploadModalConfig.title}}</h3>
  </ng-template>
  <div class="p-mb-2" *ngIf="!store.uploadModalConfig.readonly">
    <p-fileUpload #popupPUploadEl customUpload="true" (uploadHandler)="store.confirmUpload($event, popupPUploadEl)">
      <ng-template let-file let-i="index" pTemplate="file">
        <div class="p-fileupload-row">
          <div class="p-fileupload-filename overflow-wrap-break-word">{{file.name}}</div>
          <div>{{popupPUploadEl.formatSize(file.size)}}</div>
          <div>
            <button type="button" icon="pi pi-times" pButton (click)="popupPUploadEl.remove($event,i)" [disabled]="popupPUploadEl.uploading"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="content" let-files>
        <div *ngIf="!files || files.length==0">
          <!-- <div class="dragdropinfo">
            <i class="pi pi-cloud-upload dragdropicon"></i>
          </div> -->
          <div class="dragdropinfo">
            Drag and Drop here
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="cus-fix-scroll-y">
    <p-table
      [rowHover]="false"
      [paginator]="false"
      [showCurrentPageReport]="false"
      [value]="store.uploadModalConfig.table.data"
      [columns]="store.uploadModalConfig.table.head"
      [customSort]="true"
      [scrollable]="true"
      [loading]="store.uploadModalConfig.table.loading"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup >
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th [id]="col.title">
              {{col.title}}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let h of columns">
            <span *ngIf="h.type=='datetime'" [title]="rowData[h.key]">{{rowData[h.key]|date:'yyyy/MM/dd HH:mm:ss'}}</span>
            <span *ngIf="h.type=='filename'" [title]="rowData[h.key]"><a tabIndex=1 class="cus-clickable" (click)="store.downloadFile(rowData)">{{rowData[h.key]}}</a></span>
            <span *ngIf="h.type=='delete'">
              <!-- <a tabIndex=1 class="cus-clickable" (click)="store.deleteFile(rowData)">Delete</a> -->
              <button pButton label="Delete" title="Delete" [disabled]="store.uploadModalConfig.readonly" icon="pi pi-trash" (click)="store.attachmentDeleteOnClick(rowData)"></button>
            </span>
            <span *ngIf="!h.type" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length>2?6:2" style="text-align: center">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-outlined" (click)="store.hideUploadModal()"></button>
    <!-- <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="store.assignActionBy()"></button> -->
  </ng-template>
</p-dialog>


<p-dialog [(visible)]="store.cancelModalConfig.visible" [modal]="true" (onHide)="store.onCancelModalHide()"
  [draggable]="false" [resizable]="false" [styleClass]="'cus-modal cus-modal-lg'">
  <ng-template pTemplate="header">
    <h3 *ngIf="store.cancelModalConfig.title">{{store.cancelModalConfig.title}}</h3>
  </ng-template>
  <div>
    <app-textarea-cst [class]="store.cancelModalConfig.reasonIpt.class" 
      [ipt]="store.cancelModalConfig.reasonIpt"></app-textarea-cst>
  </div>
  <ng-template pTemplate="footer">
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="store.hideConfirmCancelTakeModal()"></button>
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="store.cancelTake()"></button>
  </ng-template>
</p-dialog>

<app-compare-serial [store]="store.compareSerialModalConfig"></app-compare-serial>
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-blockUI [blocked]="store.tableData.loadingSwitch"></p-blockUI>
