<div #leftScrollEl class="position-relative" style="height:100%;overflow-y: auto;word-wrap: break-word;">
  <div class="p-d-flex p-flex-column p-mt-1">
    <ng-container *ngFor="let item of itemList">
      <div id="pre-allocate-left-{{item.name}}">
        <button class="item w-100" 
          [ngClass]="{
            'selected':selectedItem==item.code,
            'changed':changedItemListCode!=null&&changedItemListCode.indexOf(item.code)>-1,
            'saved':savedItemListCode!=null&&savedItemListCode.indexOf(item.code)>-1,
            'highLighted':filterItemCode!=null&&filterItemCode.indexOf(item.code)>-1
          }" [title]="item.name" (click)="onClick(item)">
          <span>{{ item.name }}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
