<p-dialog
  [(visible)]="store.modalConfig.visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'modal'"
  class="pre-allocate-modal"
  (onHide)="onHide()">
  <ng-template pTemplate="header">
    <h3 class="modal-header" *ngIf="store.modalConfig.title">{{store.modalConfig.title}} - NOP</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="popup-body-wrapper" [appResizeSensor]="true" (onResize)="popupBodyResized($event)">
      <div class="popup-left-sticker">
        <div class="popup-left-panel-wrapper">
          <div class="popup-left-panel-body">
            <div class="popup-left-panel-content">
              <div class="p-fluid p-mt-2">
                <span class="p-float-label">
                  <input id="leftpanelfilter" type="text" pInputText [(ngModel)]="store.modalConfig.leftPanelFilterText" (ngModelChange)="store.leftPanelFilter($event)">
                  <label for="leftpanelfilter">Source Ref. No Filter</label>
                </span>
              </div>
              <app-pre-allocate-left-item-panel
                class="popup-left-panel-items"
                [itemList]="store.modalConfig.items"
                [changedItemListCode]="store.modalConfig.changedItemCodeList"
                [savedItemListCode]="store.modalConfig.savedItemCodeList"
                [selectedItem]="store.modalConfig.selectedItem"
                [filterItemCode]="store.modalConfig.filteredCodeList"
                [isAutoScroll]="true"
                (onItemClick)="store.popupLeftItemPanelOnClick($event)"
              ></app-pre-allocate-left-item-panel>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-main-panel">
        <div class="p-fluid p-grid" >
          <div class="p-col-12" style="height: 280px;">
            <div class="preallocatetopgrid">
                <div class="p-col-12">
                  <p-table dataKey="id"
                    [scrollable]="true"
                    scrollHeight="150px"
                    styleClass="p-datatable-sm p-datatable-striped custom-table"
                    [loading]="store.tableData.loadingSwitch"
                    [columns]="store.modalConfig.nopItem.columns"
                    [value]="store.modalConfig.nopItem.data"
                    [(selection)]="store.modalConfig.table.selected">
                    <ng-template pTemplate="colgroup" let-columns>
                      <colgroup>
                        <col *ngFor="let col of columns" [style]="{'width': col.width || '80px'}">
                      </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr style="height: 45px">
                        <ng-container *ngFor="let col of columns">
                          <th>{{col.title}}</th>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr style="height: 47px" [ngClass]="{'filtered': store.modalConfig.filteredIdList&&store.modalConfig.filteredIdList.indexOf(rowData.id)>-1}">
                        <ng-container *ngFor="let col of columns">
                          <ng-container *ngIf="col.type">
                            <td *ngIf="col.type ==='number'" class="ui-resizable-column p-text-truncate">
                              <input type="text" pInputText [(ngModel)]="rowData[col.field]"/>
                            </td>
                            <td *ngIf="col.type ==='date'" class="ui-resizable-column p-text-truncate">
                              <p-inputMask mask="9999/99/99" [(ngModel)]="rowData[col.field]" placeholder="9999/99/99" slotChar="yyyy/mm/dd"></p-inputMask>
                            </td>
                          </ng-container>
                          <td *ngIf="!col.type" class="ui-resizable-column p-text-truncate"><span [title]="rowData[col.field]">{{rowData[col.field]}}</span></td>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="9" style=" text-align: center;">No record found.</td>
                        <td [attr.colspan]="columns.length" style=" text-align: center;" [ngClass]="{hidden: columns===store.modalConfig.table.frozenColumns}">No record found.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <div class="p-col-12">
                  <div class="p-fluid p-d-flex">
                    <div class="p-ml-auto">
                      <button class="p-button-sm" pButton label="Calculate All" (click)="store.calculate()"></button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <p-table dataKey="id" #nopPreallocateMainTable
          [columns]="store.modalConfig.table.columns"
          [frozenColumns]="store.modalConfig.table.frozenColumns"
          [frozenWidth]="store.modalConfig.table.frozenWidth"
          [scrollable]="true"
          styleClass="p-datatable-sm p-datatable-striped custom-table"
          [loading]="store.tableData.loadingSwitch"
          [value]="store.modalConfig.table.data"
          [(selection)]="store.modalConfig.table.selected"
          (onRowSelect)="store.change($event.data, true)"
          (onRowUnselect)="store.change($event.data, true)"
          >
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style]="{'width': col.width || '80px'}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr style="height: 45px">
              <ng-container *ngFor="let col of columns">
                <th>{{col.title}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr style="height: 47px" [ngClass]="{'filtered': store.modalConfig.filteredIdList&&store.modalConfig.filteredIdList.indexOf(rowData.id)>-1}">
              <ng-container *ngFor="let col of columns">
                <td *ngIf="col.field==='action'" style="text-align: center;">
                  <p-tableCheckbox [value]="rowData"
                    [disabled]="rowData.qtyPending > store.modalConfig.nopItem.data[rowData.nopItemIdx].totalCount - store.modalConfig.nopItem.data[rowData.nopItemIdx].planAllocateQty && !nopPreallocateMainTable.selectionKeys[rowData.id]"
                  ></p-tableCheckbox>
                </td>
                <td *ngIf="col.field!=='action'" class="ui-resizable-column p-text-truncate"><span [title]="rowData[col.field]">{{rowData[col.field]}}</span></td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="9" style=" text-align: center;">No record found.</td>
              <td [attr.colspan]="columns.length" style=" text-align: center;" [ngClass]="{hidden: columns===store.modalConfig.table.frozenColumns}">No record found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Save All Item" icon="pi pi-check" (click)="save()"></button>
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="remove()"></button>
    <!-- <button pButton pRipple label="Back" icon="pi pi-times" class="p-button-outlined" (click)="store.setModalVisible(false)"></button> -->
  </ng-template>
</p-dialog>
