<illustration name="Reservation"></illustration>
<app-query-pannel class='query-panel' [store]="queryPannel"></app-query-pannel>

<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex">
    <p-multiSelect
        [options]="columns"
        [(ngModel)]="selectedColumns"
        (ngModelChange)="resetTableTab()"
        optionLabel="title"
        selectedItemsLabel="{{selectedColumns.length == columns.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
        selectedItemsLabel="{0} columns displayed"
        [style]="{minWidth: '200px'}"
        placeholder="Choose C olumns"
        class="p-mb-2 p-ml-auto"
      ></p-multiSelect>
  </div>
  <p-table #ptable
    styleClass="p-datatable-sm p-datatable-striped"
    [scrollable]="true"
    [(selection)]="selectedProducts"
    [resizableColumns]="editShow"
    [reorderableColumns]="editShow"
    [value]="lisOfData"
    [columns]="selectedColumns"
    [loading]="loadingSwitch"
    dataKey="id"
    [sortField]="initSortField"
    [sortOrder]="initSortOrder"
    [frozenColumns]="frozenColumns"
    [frozenWidth]="'250px'"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns">
          <ng-container >
            <col [style]="{'width': col.width || '300px'}">
          </ng-container>
        </ng-container>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <ng-container >
            <th
              *ngIf="col.sortable!=false"
              pResizableColumn
              pReorderableColumn
              [pSortableColumn]="col.header"
              [title]="col.fullTitle || col.title"
              (click)="sortSearch(col.header)"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </th>
            <th
              *ngIf="col.sortable==false"
              pResizableColumn
              pReorderableColumn
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr style="height: 53px">
        <ng-container *ngFor="let col of columns">
          <ng-container >
            <td>
              <button *ngIf="col.type==='expandControl'" 
                type="button" pButton pRipple 
                [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain" 
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              <div *ngIf="showU && col.type=='action'" style="display: inline-flex">
                <button class="p-button-sm p-mr-1" *ngIf="data.edit && showU" pButton label="Save" (click)="save(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1"  pButton label="Edit" (click)="editDetail(data)"></button>
                <button class="p-button-sm p-button-outlined" *ngIf="data.edit && showU" pButton label="Cancel" (click)="cancel(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && warehouseIdList[data.channelId] == true && data.lisReservationId == null"  pButton label="Reserve LIS" (click)="reserveLis(data)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && data.expiryDate != null"  pButton label="Extend" (click)="extendReleaseDate(data)"></button>
              </div>
              <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.header]" [style]="{'width': '100%'}"/>
              <p-inputNumber *ngIf="col.type==='number'" [min]="col.min" [disabled]="!data.edit" class="p-inputtext-sm"
                (ngModelChange)="onTableValueChange($event, data, col)"
                (onInput)="onTableValueInput($event, data, col)"
                [(ngModel)]="data[col.header]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
              <span *ngIf="col.type==='date'" [title]="data[col.header]|date:'yyyy/MM/dd'">{{data[col.header] | date:'yyyy/MM/dd' }}</span>
              <span *ngIf="!col.type" [title]="data[col.header]">{{data[col.header]}}</span>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
      <tr></tr>
      <tr class="expand-row">
        <td [attr.colspan]="columns.length">
          <div class="p-d-flex">
            <div class="expand-row-wrapper">
              <!-- sticky width 0 left 50%-->
              <div class="expand-row-content-wrapper">
                <!-- width 0 -->
                <div class="expand-row-content">
                  <!-- width 75vw -->
                  <!-- content -->
                  <div class="p-fluid p-grid p-mt-2 p-mb-2">
                    <ng-container *ngFor="let col of selectedColumns">
                      <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                        <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==frozenColumns}">
                          <input 
                            class="p-inputtext-sm" 
                            type="text" 
                            [attr.id]="col.header" 
                            [class]="{'p-input-sm':true}"
                            pInputText [(ngModel)]="data[col.header]"
                            [disabled]='true'
                            [readonly]='true'>
                          <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12" style=" text-align: center;">No record found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="page($event)"></p-paginator>

</div>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex">
    <p-multiSelect
        [options]="columns"
        [(ngModel)]="selectedColumns"
        (ngModelChange)="resetTableTab()"
        optionLabel="title"
        selectedItemsLabel="{{selectedColumns.length == columns.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
        selectedItemsLabel="{0} columns displayed"
        [style]="{minWidth: '200px'}"
        placeholder="Choose C olumns"
        class="p-mb-2 p-ml-auto"
      ></p-multiSelect>
  </div>
  <p-table #ptable
    styleClass="p-datatable-sm p-datatable-striped"
    [scrollable]="true"
    [(selection)]="selectedProducts"
    [resizableColumns]="editShow"
    [reorderableColumns]="editShow"
    [value]="lisOfData"
    [columns]="selectedColumns"
    [loading]="loadingSwitch"
    dataKey="id"
    [sortField]="initSortField"
    [sortOrder]="initSortOrder"
    [frozenColumns]="frozenColumns"
    [frozenWidth]="'56px'"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns">
          <ng-container *ngIf="col.type!='action'">
            <col [style]="{'width': col.width || '300px'}">
          </ng-container>
        </ng-container>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <ng-container  *ngIf="col.type!='action'">
            <th
              *ngIf="col.sortable!=false"
              pResizableColumn
              pReorderableColumn
              [pSortableColumn]="col.header"
              [title]="col.fullTitle || col.title"
              (click)="sortSearch(col.header)"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </th>
            <th
              *ngIf="col.sortable==false"
              pResizableColumn
              pReorderableColumn
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr style="height: 53px">
        <ng-container *ngFor="let col of columns">
          <ng-container *ngIf="col.type!='action'" >
            <td>
              <button *ngIf="col.type==='expandControl'" 
                type="button" pButton pRipple 
                [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain" 
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              <div *ngIf="showU && col.type=='action'" style="display: inline-flex">
                <button class="p-button-sm p-mr-1" *ngIf="data.edit && showU" pButton label="Save" (click)="save(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1"  pButton label="Edit" (click)="editDetail(data)"></button>
                <button class="p-button-sm p-button-outlined" *ngIf="data.edit && showU" pButton label="Cancel" (click)="cancel(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && warehouseIdList[data.channelId] == true && data.lisReservationId == null"  pButton label="Reserve LIS" (click)="reserveLis(data)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && data.expiryDate != null"  pButton label="Extend" (click)="extendReleaseDate(data)"></button>
              </div>
              <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.header]" [style]="{'width': '100%'}"/>
              <p-inputNumber *ngIf="col.type==='number'" [min]="col.min" [disabled]="!data.edit" class="p-inputtext-sm"
                (ngModelChange)="onTableValueChange($event, data, col)"
                (onInput)="onTableValueInput($event, data, col)"
                [(ngModel)]="data[col.header]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
              <span *ngIf="col.type==='date'" [title]="data[col.header]|date:'yyyy/MM/dd'">{{data[col.header] | date:'yyyy/MM/dd' }}</span>
              <span *ngIf="!col.type" [title]="data[col.header]">{{data[col.header]}}</span>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
      <tr></tr>
      <tr class="expand-row">
        <td [attr.colspan]="columns.length">
          <div class="p-d-flex">
            <div class="expand-row-wrapper">
              <!-- sticky width 0 left 50%-->
              <div class="expand-row-content-wrapper">
                <!-- width 0 -->
                <div class="expand-row-content">
                  <!-- width 75vw -->
                  <!-- content -->
                  <div class="p-fluid p-grid p-mt-2 p-mb-2">
                    <ng-container *ngFor="let col of selectedColumns">
                      <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                        <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==frozenColumns}">
                          <input 
                            class="p-inputtext-sm" 
                            type="text" 
                            [attr.id]="col.header" 
                            [class]="{'p-input-sm':true}"
                            pInputText [(ngModel)]="data[col.header]"
                            [disabled]='true'
                            [readonly]='true'>
                          <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12" style=" text-align: center;">No record found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="page($event)"></p-paginator>

</div>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex">
    <p-multiSelect
        [options]="columns"
        [(ngModel)]="selectedColumns"
        (ngModelChange)="resetTableTab()"
        optionLabel="title"
        selectedItemsLabel="{{selectedColumns.length == columns.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
        selectedItemsLabel="{0} columns displayed"
        [style]="{minWidth: '200px'}"
        placeholder="Choose C olumns"
        class="p-mb-2 p-ml-auto"
      ></p-multiSelect>
  </div>
  <p-table #ptable
    styleClass="p-datatable-sm p-datatable-striped"
    [scrollable]="true"
    [(selection)]="selectedProducts"
    [resizableColumns]="editShow"
    [reorderableColumns]="editShow"
    [value]="lisOfData"
    [columns]="selectedColumns"
    [loading]="loadingSwitch"
    dataKey="id"
    [sortField]="initSortField"
    [sortOrder]="initSortOrder"
    [frozenColumns]="frozenColumns"
    [frozenWidth]="'56px'"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns">
          <ng-container *ngIf="col.type!='action'">
            <col [style]="{'width': col.width || '300px'}">
          </ng-container>
        </ng-container>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <ng-container  *ngIf="col.type!='action'">
            <th
              *ngIf="col.sortable!=false"
              pResizableColumn
              pReorderableColumn
              [pSortableColumn]="col.header"
              [title]="col.fullTitle || col.title"
              (click)="sortSearch(col.header)"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </th>
            <th
              *ngIf="col.sortable==false"
              pResizableColumn
              pReorderableColumn
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr style="height: 53px">
        <ng-container *ngFor="let col of columns">
          <ng-container *ngIf="col.type!='action'" >
            <td>
              <button *ngIf="col.type==='expandControl'" 
                type="button" pButton pRipple 
                [icon]="'pi pi-chevron-right'"
                class="p-button-text p-button-rounded p-button-icon-only p-button-plain" (click)="openModal(data)"></button>
              <div *ngIf="showU && col.type=='action'" style="display: inline-flex">
                <button class="p-button-sm p-mr-1" *ngIf="data.edit && showU" pButton label="Save" (click)="save(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1"  pButton label="Edit" (click)="editDetail(data)"></button>
                <button class="p-button-sm p-button-outlined" *ngIf="data.edit && showU" pButton label="Cancel" (click)="cancel(data, rowIndex)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && warehouseIdList[data.channelId] == true && data.lisReservationId == null"  pButton label="Reserve LIS" (click)="reserveLis(data)"></button>
                <button class="p-button-sm p-mr-1" *ngIf="showU && !data.edit && data.expiryDate != null"  pButton label="Extend" (click)="extendReleaseDate(data)"></button>
              </div>
              <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.header]" [style]="{'width': '100%'}"/>
              <p-inputNumber *ngIf="col.type==='number'" [min]="col.min" [disabled]="!data.edit" class="p-inputtext-sm"
                (ngModelChange)="onTableValueChange($event, data, col)"
                (onInput)="onTableValueInput($event, data, col)"
                [(ngModel)]="data[col.header]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
              <span *ngIf="col.type==='date'" [title]="data[col.header]|date:'yyyy/MM/dd'">{{data[col.header] | date:'yyyy/MM/dd' }}</span>
              <span *ngIf="!col.type" [title]="data[col.header]">{{data[col.header]}}</span>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12" style=" text-align: center;">No record found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="page($event)"></p-paginator>

</div>





<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [(visible)]="modalVisible" [modal]="true"
  [draggable]="false" [resizable]="false" [styleClass]="'modal'">
  <ng-template pTemplate="header">
    <h3 *ngIf="selectedRow">Reservation ID: {{selectedRow.id}}</h3>
  </ng-template>
  <div class="p-fluid p-grid p-mt-2 p-mb-2" *ngIf="selectedRow">
    <ng-container *ngFor="let col of selectedColumns">
      <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
        <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==frozenColumns}">
          <input 
            class="p-inputtext-sm" 
            type="text" 
            [attr.id]="col.header" 
            [class]="{'p-input-sm':true}"
            pInputText [(ngModel)]="selectedRow[col.header]"
            [disabled]='true'
            [readonly]='true'>
          <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
        </span>
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="setModalVisible(false)"></button>
  </ng-template>
</p-dialog>