<illustration [name]="'CAS Appointment'"></illustration>
<div
  class="p-shadow-1 position-relative"
  #dropdownTarget
  style="
    background-color: white;
    margin: 20px 0;
    border-radius: 5px;
    padding: 25px;
  "
>
  <div class="p-fluid p-grid" *ngIf="queryPannel">
    <ng-container *ngFor="let ipt of queryPannel.ipts; index as i">
      <app-input-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 0"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-input-cst>
      <app-select-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 1"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-select-cst>
      <app-treeSelect-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 3"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-treeSelect-cst>
      <app-daterange-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 6"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-daterange-cst>
      <app-datetime-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 2"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-datetime-cst>
      <app-select-multiple-cst
        [class]="ipt.class"
        *ngIf="ipt.type == 10"
        [ipt]="ipt"
        (change)="change(i, $event, ipt)"
      ></app-select-multiple-cst>
      <div [class]="ipt.class" *ngIf="ipt.title == TITLE.DATERANGE">
        <p-calendar
          [inputId]="ipt.inputId"
          [(ngModel)]="ipt.value"
          (ngModelChange)="change(i, $event, ipt)"
          dateFormat="yy/mm/dd"
          [disabled]="ipt.disabled"
          [selectionMode]="ipt.selectionMode"
          [showTime]="ipt.showTime"
          [showSeconds]="ipt.showSeconds"
          [inline]="ipt.inline"
          [minDate]="ipt.minDate"
          [maxDate]="ipt.maxDate"
          [class]="ipt.calendarClass"
        >
        </p-calendar>
      </div>
      <div [class]="ipt.class" *ngIf="ipt.title == TITLE.TIMESLOT">
        <custom-button-radio-group
          class="timeslot-radio-group"
          [(value)]="ipt.value"
          (valueChange)="change(i, $event, ipt)"
          [options]="ipt.options"
          [inputId]="ipt.inputId"
        ></custom-button-radio-group>
      </div>
      <div [class]="ipt.class" *ngIf="ipt.title == TITLE.INFO && ipt.show!=false">
        <span [innerHtml]="ipt.value"></span>
      </div>
    </ng-container>

    <div [class]="queryPannel.btnsclass">
      <button
        *ngFor="let btn of queryPannel.btns"
        pButton
        type="button"
        [disabled]="btn.disabled"
        [class]="btn.class"
        [label]="btn.title"
        (click)="btn.handler.click()"
        style="width: auto; height: 35px"
      ></button>
    </div>
  </div>

  <div class="cus-loading-overlay" *ngIf="loading">
    <i class="loading-icon pi pi-spin pi-spinner"></i>
  </div>
</div>
