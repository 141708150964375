
<illustration [name]="store.breadcrumb"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel [store]="store.queryData"></app-query-pannel>
<!-- table module -->
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <p-multiSelect
      [options]="store.tableData.columns"
      [ngModel]="store.tableData.selectedColumns"
      (ngModelChange)="store.tableSelectedColumnsChange($event)"
      optionLabel="title"
      selectedItemsLabel="{{store.tableData.selectedColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.selectedColumns.length +' columns displayed'}}"
      selectedItemsLabel="{0} columns displayed"
      [style]="{minWidth: '200px'}"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <p-table #ptable
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="false"
    [scrollable]="true"
    [(selection)]="store.tableData.selectedProducts"
    [resizableColumns]="store.tableData.editShow"
    [reorderableColumns]="store.tableData.editShow"
    [value]="store.tableData.data"
    [columns]="store.tableData.selectedColumns"
    [loading]="store.tableData.loadingSwitch"
    dataKey="id"
    [sortField]="store.field"
    [sortOrder]="store.order"
    [frozenColumns]="store.tableData.frozenColumns"
    [frozenWidth]="store.tableData.frozenWidth"
    [customSort]="true"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.field"
          [title]="col.fullTitle || col.title"
          (click)="store.sortSearch(col.field)"
          [id]="col.title"
        >
          {{col.title}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px" [ngClass]="{'dimfilter': data.overdue}">
        <td *ngFor="let col of columns" ngClass="{'p-text-truncate':col.type!='action'}">
          <div *ngIf="col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="!data.gtEnd && data.isEdit" pButton label="Save" (click)="store.save(data, rowIndex)"></button>
            <button class="p-button-sm p-button-outlined p-mr-1" *ngIf="!data.gtEnd && data.isEdit" pButton label="Cancel" (click)="store.cancel(data, rowIndex)"></button>

            <button *ngIf="store.showEDC && !data.isEdit && !data.action" class="p-button-sm p-mr-1" pButton label="Edit" (click)="store.editRow(data)"></button>
            <button *ngIf="store.showED && !data.isEdit && data.hasDemand && !data.action" class="p-button-sm p-mr-1" pButton [label]="'Demand'" (click)="store.demand(data, rowIndex)"></button>
            <button *ngIf="store.showVD && !data.isEdit && data.hasDemand && data.action" class="p-button-sm p-button-outlined p-mr-1" pButton [label]="'View Demand'" (click)="store.demand(data, rowIndex)"></button>
            <button *ngIf="store.showR && !data.isEdit && data.action && store.access" class="p-button-sm" [ngClass]="{'cus-button-secondary': data.overdue}" pButton label="Replenishment" (click)="store.replenishment(data, rowIndex)"></button>
          </div>
          <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
          <ng-container *ngIf="col.type==='date'">
            <span *ngIf="!data.isEdit" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span>
            <!-- <div *ngIf="data.isEdit && col.edit" class="edit-panel" [title]="data[col.field]|date:'yyyy/MM/dd'" (click)="onShowCalender($event, data, col.field)">{{data[col.field] | date:'yyyy/MM/dd' }}</div> -->
            <div *ngIf="data.isEdit && col.edit" class="edit-panel">
              <p-calendar [(ngModel)]="data[col.inputValueKey]" [defaultDate]="col.defaultDate" [showTime]="false" [appendTo]="'body'" [dateFormat]="'yy/mm/dd'" (onSelect)="onShowCalender($event, data, col.field);store.onSelectCalender($event)"></p-calendar>
            </div>
          </ng-container>
          <ng-container *ngIf="col.type==='datetime'">
            <span *ngIf="!col.edit" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm' }}</span>
            <span *ngIf="col.edit && !data.isEdit" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm' }}</span>
            <!-- <div *ngIf="col.edit && data.isEdit && col.edit" class="edit-panel" [title]="data[col.field]|date:'yyyy/MM/dd'" (click)="onShowCalender($event, data, col.field)">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</div> -->
            <div *ngIf="col.edit && data.isEdit && col.edit" class="edit-panel">
              <p-calendar [(ngModel)]="data[col.inputValueKey]" [defaultDate]="col.defaultDate" [hourFormat]="24" [stepMinute]="30" [showTime]="true" [appendTo]="'body'" [dateFormat]="'yy/mm/dd'" [showSeconds]="false" (onSelect)="onShowCalender($event, data, col.field);store.onSelectCalender($event)"></p-calendar>
            </div>

          </ng-container>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="12" style=" text-align: center;">{{frozen.length === 1 ? '' : 'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<div *ngIf="store.loading" class="mask" (click)="store.stopEvent($event)"></div>
<p-overlayPanel #op>
  <!-- <ng-template pTemplate>
    <p-calendar [(ngModel)]="store.currentDate" [showTime]="store.showTime || false" [showSeconds]="store.showTime || false" [inline]="true" (onSelect)="store.onSelectCalender($event, op)"></p-calendar>
  </ng-template> -->
</p-overlayPanel>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectVisible]="false"></p-confirmDialog>
