<illustration [name]="store.breadcrumb "></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel headerTilte="Demand Cycle" [store]="store.parentData"></app-query-pannel>
<!-- <illustration [name]="store.subBreadcrumb "></illustration> -->
<app-add-pannel [headerTilte]="store.subBreadcrumb" class="add-pannel" [store]="store.queryData"></app-add-pannel>

<p-panel header="Add Items By BU" class="p-d-block p-mt-4 p-mb-0" [toggleable]="true" *ngIf="store.showU && store.allowAddItem && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand">
  <div class="p-fluid p-grid">
    <div class="p-col-12 p-md-6" *ngIf="store.showU && store.allowAddItem && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand">
      <div class="bordered-input-group">
        <label class="input-group-label">{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU_ALL_CHANNEL_GROUP_TITLE}}</label>
        <div class="p-fluid p-grid vertical-container p-mt-2">
          <app-select-cst *ngIf="store.buCodeForAllChannelIpt" class="p-col-12" [ipt]="store.buCodeForAllChannelIpt"></app-select-cst>
          <div class="p-col-12">
            <!-- <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU_EXPORT}}" (click)="store.getItemsByBuAndExportXlsx()"></button>
            <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU}}" (click)="store.getItemsByBU()"></button> -->
            <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU_EXPORT_ALL_CHANNEL}}" (click)="store.exportItemsByBu()"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12" *ngIf="store.showU && store.allowAddItem && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand">
      <div class="bordered-input-group">
        <label class="input-group-label">Add Items By BU</label>
        <div class="p-fluid p-grid vertical-container p-mt-2">
          <app-select-multiple-cst *ngIf="store.bucodeIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.bucodeIpt"></app-select-multiple-cst>
          <app-multipleTreeSelect-cst *ngIf="store.addItemByBuChannelIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.addItemByBuChannelIpt" 
            (change)="store.addItemByBuChannelIpt.change($event, store.addItemByBuChannelIpt)"></app-multipleTreeSelect-cst>
          <!-- <app-select-multiple-cst *ngIf="store.lobcodeIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.lobcodeIpt"></app-select-multiple-cst> -->
          <!-- <app-select-multiple-cst *ngIf="store.maincatIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.maincatIpt"></app-select-multiple-cst> -->
          <!-- <app-select-multiple-cst *ngIf="store.subcatIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.subcatIpt"></app-select-multiple-cst> -->
          <app-select-multiple-cst *ngIf="store.subsubcatIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.subsubcatIpt"></app-select-multiple-cst>
          <app-select-multiple-cst *ngIf="store.brandIpt" class="p-lg-6 p-md-6 p-sm-12" [ipt]="store.brandIpt"></app-select-multiple-cst>
          <!-- <div class="p-lg-6 p-md-6 p-sm-12"></div> -->
          <div class="p-lg-4 p-md-6 p-sm-12">
            <app-checkboxt-cst *ngIf="store.showU && store.demandData.hasDemand" [ipt]="store.checkBox"></app-checkboxt-cst>
          </div>
          <div class="p-lg-4 p-md-6 p-sm-12">
            <app-checkboxt-cst *ngIf="store.showU && store.demandData.hasDemand" [ipt]="store.isAodoIpt"></app-checkboxt-cst>
          </div>
          <div class="p-lg-4 p-md-6 p-sm-12">
            <app-checkboxt-cst *ngIf="store.showU && store.demandData.hasDemand" [ipt]="store.isSerializedIpt"></app-checkboxt-cst>
          </div>
          <div class="p-col-12">
            <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU_EXPORT}}" (click)="store.getItemsByBuAndExportXlsx()"></button>
            <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="{{store.BUTTON_TITLE.ADD_ITEMS_BY_BU}}" (click)="store.getItemsByBU()"></button>
            <!-- <button class="p-mr-1 width-auto" *ngIf="store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand" 
              pButton label="Export By BU For All Channel" (click)="store.exportItemsByBu()"></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</p-panel>
<!-- table module -->
<div class="p-shadow-1 p-component" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-mb-2 p-fluid p-grid ">
    <div class="p-col-8" *ngIf="store.allowAddItem">
      <button class="p-mr-1 p-mb-1 width-auto" *ngIf="store.showU" pButton label="{{store.BUTTON_TITLE.ADD_ITEM}}" (click)="store.addItem()"></button>
      <button class="p-mr-1 p-mb-1 width-auto" *ngIf="store.showU && store.isNop" pButton label="Add Item by Home Delivery Reservation" (click)="store.addItemByReservationWarehouse()"></button>
      <button class="p-mr-1 p-mb-1 width-auto" *ngIf="store.showU && store.isNop" pButton label="Add Item by On Demand Reservation" (click)="store.addItemByReservationChannel()"></button>
      <button class="p-mr-1 p-mb-1 width-auto" *ngIf="store.showU && store.isBYOD" pButton label="Add Item by BYOD Reservation" (click)="store.addItemByReservationForBYOD()"></button>
    </div>
    <!-- <div class="p-text-right p-col-4" [ngClass]="{'p-col-12': store.showU && store.demandData && store.demandData.hasByBUItem && store.demandData.hasDemand}"> -->
    <div class="p-text-right p-col-4">
      <button class="p-mr-1 p-mb-1 width-auto" *ngIf="store.showU && store.allowAddItem" pButton label="Pre-Allocate" (click)="store.readyToBePreAllocated()"></button>
    </div>
  </div>
  <app-spreadsheet-table #spreadSheet [value]="store.spreadsheet.value" [column]="store.spreadsheet.column" [loading]="store.loading"
  [checkRowDeleteAvailableFn]="store.spreadsheet.checkRowDeleteAvailableFn"
  (onValueChanged)="store.spreadsheetValueChange($event)" (onCellValueChanged)="store.onCellValueChanged($event)"
  [allowImport]="true" [exportXlsxName]="store.exportXlsxName"
  (onEditStart)="store.onEditStart($event)" (onImport)="store.onImport($event)"
  ></app-spreadsheet-table>
</div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectVisible]="false"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" key="preAllocate" acceptLabel="Confirm" rejectLabel="Cancel" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<div *ngIf="store.loading" class="mask" (click)="store.stopEvent($event)"></div>
<p-overlayPanel (keydown)="store.onOptionKeyDown($event)" class="cus-spreadsheet-overlay" (onShow)="store.onOverlayShow()" #overlay [style]="{'height':'308px','border':'1px solid #ccc'}">
  <ng-template pTemplate>
    <span class="p-input-icon-right p-m-2" >
      <i class="pi pi-search"></i>
      <ng-container *ngIf="store.overlayName==='sku'">
        <input type="text" class="SKUFilter" id="SKUFilter" #searchSku pInputText (input)="store.searchSku(searchSku.value)"/>
      </ng-container>
      <ng-container *ngIf="store.overlayName==='repo'">
        <input type="text" class="REPOFilter" id="REPOFilter" #searchRepo pInputText (input)="store.searchRepo(searchRepo.value)"/>
      </ng-container>
    </span>
    <ng-container *ngIf="store.overlayName==='sku'">
      <!-- <p-listbox [(ngModel)]="store.skusSelect" [options]="store.skus" optionLabel="name" optionValue="code" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem','border':'none'}" (onClick)="store.changeSku($event, overlay)"></p-listbox>     -->
      <p-virtualScroller class="cus-spreadsheet-overlay-scroller" #overlayVirtualScroller [value]="store.skus" [scrollHeight]="'250px'" [itemSize]="40" maxBufferPx="200" minBufferPx="200" [rows]="store.skus.length">
        <ng-template pTemplate="item" let-item>
          <div class="cus-virtual-scroller-item" [ngClass]="{'p-highlight':item.code==store.skusSelect}" [attr.aria-label]="item.name" title="{{item.name}} ~ {{item.flag}}" (click)="store.changeSku({value:item.code}, overlay)">
            {{ item.name }}
          </div>
        </ng-template>
      </p-virtualScroller>
    </ng-container>
    <ng-container *ngIf="store.overlayName==='repo'">
      <p-listbox [(ngModel)]="store.reposSelect" [options]="store.repos" optionLabel="label" optionValue="id" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem','border':'none'}" (onClick)="store.changeRepo($event, overlay)"></p-listbox>
    </ng-container>
  </ng-template>
</p-overlayPanel>
<app-pre-allocate-common *ngIf="!store.isNop" [store]="store"></app-pre-allocate-common>
<app-pre-allocate-nop *ngIf="store.isNop" [store]="store"></app-pre-allocate-nop>
<p-blockUI [blocked]="store.loading"></p-blockUI>