<illustration name="Task"></illustration>
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <p-table #ptable dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [value]="tableData.data"
    [columns]="tableData.selectedColumns"
    scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [title]="col.fullTitle || col.title" [id]="col.title" >
          {{col.title}}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px">
        <td *ngFor="let col of columns">
          <div *ngIf="col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="data.full" pButton label="Fullset" (click)="rowClick(data.task, 'full')"></button>
            <button class="p-button-sm p-button-info p-mr-1" *ngIf="data.topic" pButton label="Delta" (click)="rowClick(data.task, 'topic')"></button>
          </div>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="2" style=" text-align: center;">{{'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
</div>
