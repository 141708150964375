<illustration [name]="'menu.stock.transaction' | translate" path="Stock In & Out"></illustration>
<app-query-pannel [store]="queryPannel" [headerTilte]="headId?'DETAIL':'CREATE'" [disableBtn]="loadingSwitch"></app-query-pannel>

  <!-- 展示表格 -->

<div class="p-shadow-1" #dropdownTarget style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <p-multiSelect
      [options]="cols"
      [(ngModel)]="selectedColumns"
      optionLabel="title"
      selectedItemsLabel="{{selectedColumns.length == cols.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
      [style]="{minWidth: '200px'}"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <div class="btns p-mb-2">
    <p-button label="Add Stock Order Item" *ngIf="isEditable && showCreate" [disabled]="loadingSwitch" styleClass="p-button-sm" (click)="create()"></p-button>
  </div>
    <!-- <div> -->
  <p-table
    styleClass="p-datatable-sm p-datatable-striped"
    class="custom-order-item-table"
    [rowHover]="true"
    [scrollable]="true"
    [resizableColumns]="!editing"
    [reorderableColumns]="!editing"
    [value]="lineData"
    [columns]="selectedColumns"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    [loading]="loadingSwitch"
    dataKey="id"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col style="width:330px">
        <col *ngFor="let col of selectedColumns" [style]="{'width': col.width || '250px'}">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th id="action">Action</th>
        <th
          *ngFor="let col of selectedColumns"
          pSortableColumn="{{col.header}}"
          pResizableColumn
          pReorderableColumn
          [title]="col.fullTitle || col.title"
          [id]="col.title"
        >
          {{col.title}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">
      <tr [ngClass]="{'unallocated': isWarehouseToChannel && data.unallocatedDate}">
        <td>
          <div class="order-item-table-row-action">
            <p-button label="Delete" class="p-mr-1" *ngIf="isEditable && showCreate" styleClass="p-button-outlined p-button-sm" (click)="delete(data)"></p-button>
            <p-button label="Input Serial Number" *ngIf="isEditable && showCreate && data.isserialcontrol=='Y'" [disabled]="!items||items.length==0" styleClass="p-button-outlined p-button-sm p-mr-1" (click)="editSerial(rowIndex)"></p-button>
            <p-button label="Show Serial Number" *ngIf="(!isNew || !showCreate) && data.serials && data.serials.length > 0" [disabled]="!items||items.length==0" styleClass="p-button p-button-sm p-mr-1" (click)="showSerial(rowIndex)"></p-button>
            <p-button label="Attachment" *ngIf="isLineAttachment" styleClass="p-button p-button-sm" (click)="showUploadModalByLineId(rowIndex)"></p-button>
          </div>
        </td>
        <td *ngFor="let h of columns">

          <div *ngIf="h.header === 'statusCondition'">
            <p-dropdown *ngIf="fromChannel" class="p-dropdown-sm" [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" [disabled]="!isEditable" [options]="fromStatus" [(ngModel)]="data.fromStockConditionId" optionLabel="name" optionValue="value"
              [filter]="true" [filterBy]="'name'"
              placeholder="Select Status Condition" [showClear]="true" [style]="{'width':'100%'}" (onChange)="onAdjustmentItemStatusConditionChange($event, data)"></p-dropdown>
          </div>

          <div *ngIf="h.header === 'item'">
            <span *ngIf="!data.itemId" [title]="data.itemId">{{data['itemId']}}</span>
            <p-dropdown class="p-dropdown-sm" [disabled]="!isEditable" [options]="items" [(ngModel)]="data.itemId" optionLabel="name" optionValue="code"
              [filter]="true" [filterBy]="'name'"
              [virtualScroll]="true" [itemSize]="40"
              [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" placeholder="Select a Item" [showClear]="true" [style]="{'width':'100%'}" (onChange)="itemChange($event, data)">
              <ng-template let-option pTemplate="item">
                <div class="p-text-truncate" [title]="option.name">{{option.name}}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div *ngIf="h.header === 'qty'">
            <span [title]="data.quantity" *ngIf="data.isserialcontrol=='Y'">{{data.quantity}}</span>
            <p-inputNumber class="p-inputtext-sm" *ngIf="data.isserialcontrol!='Y'" [disabled]="!isEditable" [min]="isQtyPositive ? 0 : null" [(ngModel)]="data.quantity" [style]="{'width':'100%'}"></p-inputNumber>
          </div>

          <div *ngIf="h.header === 'qtyCancelled'">
            <p-inputNumber class="p-inputtext-sm" [disabled]="true" [min]="isQtyPositive ? 0 : null" [(ngModel)]="data.qtyCancelled" [style]="{'width':'100%'}"></p-inputNumber>
          </div>

          <div *ngIf="h.header === 'qtyWriteOff'">
            <p-inputNumber class="p-inputtext-sm" [disabled]="true" [min]="isQtyPositive ? 0 : null" [(ngModel)]="data.qtyWriteOff" [style]="{'width':'100%'}"></p-inputNumber>
          </div>

          <div *ngIf="h.type === 'receiveColumns'">
            <span [title]="data.quantity" *ngIf="data.isserialcontrol=='Y'">{{data[h.header]}}</span>
            <p-inputNumber class="p-inputtext-sm" *ngIf="data.isserialcontrol!='Y'" [disabled]="!isCheckingReceived || (items && items.length==0)" [min]="isQtyPositive ? 0 : null" [(ngModel)]="data[h.header]" [style]="{'width':'100%'}"></p-inputNumber>
          </div>

          <!-- <div *ngIf="h.header === 'reason' ">
            <p-dropdown class="p-dropdown-sm" [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" [disabled]="!isEditable || !isReason" [options]="reasons" [(ngModel)]="data.reason" optionLabel="description" optionValue="id"
            placeholder="Select a reason" [showClear]="true" [style]="{'width':'100%'}"></p-dropdown>
          </div> -->
          <div *ngIf="h.header === 'reason' ">
            <p-dropdown class="p-dropdown-sm" [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" [disabled]="!(isEditable && showCreate && reasonEditable)" [options]="_reasonOptions" [(ngModel)]="data.reason" (ngModelChange)="lineReasonChanged(data)" optionLabel="displayDesc" optionValue="id"
            placeholder="Select a reason" [showClear]="true" [style]="{'width':'100%'}"></p-dropdown>
          </div>
          <div *ngIf="h.header === 'finalReason' ">
            <p-dropdown class="p-dropdown-sm" [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" [disabled]="!(isEditable && showCreate && finalReasonEditable)" [options]="_reasonOptions" [(ngModel)]="data.finalReason" optionLabel="displayDesc" optionValue="id"
            placeholder="Select a reason" [showClear]="true" [style]="{'width':'100%'}"></p-dropdown>
          </div>

          <div *ngIf="h.header === 'remark' ">
            <input pInputText class="p-inputtext-sm w-100" [disabled]="!isEditable" [placeholder]="h.title" [(ngModel)]="data[h.header]">
          </div>

          <div *ngIf="h.type == 'text'">
            <span [title]="data[h.header]">{{data[h.header]}}</span>
          </div>
        </td>

      </tr>
    </ng-template>

  </p-table>
</div>

<p-dialog [(visible)]="serialModalConfig.visible" [modal]="true"
  [draggable]="false" [resizable]="false" [styleClass]="'serial-modal'" (onHide)="serialModalOnHide()">
  <ng-template pTemplate="header">
    <h3 class="modal-header" *ngIf="isEditable && showCreate">{{serialModalConfig.header}}</h3>
    <h3 class="modal-header" *ngIf="!isNew || !showCreate">{{serialModalConfig.header_readonly}}</h3>
  </ng-template>
  <div class="p-fluid p-grid p-mt-1">
    <app-input-cst [class]="'p-col-12 p-sm-3 p-md-3'" [ipt]="serialModalConfig.itemCode"></app-input-cst>
    <app-input-cst [class]="'p-col-12 p-sm-3 p-md-3'" [ipt]="serialModalConfig.itemDesc"></app-input-cst>
    <app-input-cst [class]="'p-col-12 p-sm-3 p-md-3'" [ipt]="serialModalConfig.actionQty"></app-input-cst>
    <app-checkboxt-cst *ngIf="serialModalConfig.isNegative && qtyAllowNegative" [class]="'p-col-12 p-sm-3 p-md-3'" [ipt]="serialModalConfig.isNegative" (change)="serialModalConfig.isNegative.onChange($event)"></app-checkboxt-cst>
  </div>
  <div class="serial-input-group p-mt-2 p-mb-2" *ngIf="isEditable && showCreate">
    <label class="serial-group-label">1 by 1 input</label>
    <div class="p-mt-1 p-fluid p-grid align-items-center">
      <div class="p-col-9 p-sm-4 p-md-5 p-lg-6 p-xl-8">
        <span class="p-float-label">
          <input id="serial-number-input" type="text" pAutoFocus [autofocus]="true" autocomplete="off" [(ngModel)]="serialModalConfig.newSerialValue" pInputText (keydown.enter)="addSerialByInput(serialModalConfig.newSerialValue)">
          <label for="serial-number-input">Serial Number Input</label>
        </span>
      </div>
      <div class="p-col-3 p-sm-2 p-md-2 p-lg-2 p-xl-1 align-self-center">
        <p-button label="Add" styleClass="p-button-sm" (click)="addSerialByInput(serialModalConfig.newSerialValue)"></p-button>
      </div>
      <div class="p-col-12 p-sm-1 p-md-1 align-self-center text-center">or</div>
      <div class="p-col-12 p-sm-5 p-md-4 p-lg-3 p-xl-2 align-self-center">
        <p-button *ngIf="!isScannerEnable" label="Enable Device Camera" styleClass="p-button-sm p-button-outlined" (click)="toggleScanner(true)"></p-button>
        <p-button *ngIf="isScannerEnable" label="Disable Device Camera" styleClass="p-button-sm p-button-outlined" (click)="toggleScanner(false)"></p-button>
      </div>
    </div>

    <div *ngIf="isScannerEnable && isEditable && showCreate">
      <div class="p-fluid p-grid p-mt-1" *ngIf="availableDevices && availableDevices.length > 1">
        <div class="p-col-12">
          <span class="p-float-label">
            <p-dropdown [inputId]="'camDeviceSelect'" [autoDisplayFirst]='false' [options]="availableDevices"
            [optionLabel]="'label'" [optionValue]="'deviceId'"
            [(ngModel)]="deviceSelected" (onChange)="onDeviceSelectChange($event)">
            </p-dropdown>
            <label [for]="'camDeviceSelect'">Available Devices</label>
          </span>
        </div>
      </div>
      <div class="scanner">
        <zxing-scanner #scanner [device]="deviceCurrent" (deviceChange)="onDeviceChange($event)" (scanSuccess)="onCodeResult($event)"
          [formats]="formats" [tryHarder]="SCANNERTRYHARDER" (permissionResponse)="onHasPermission($event)"
          (camerasFound)="onCamerasFound($event)">
          <ng-template let-file pTemplate="file">
              <div>Custom UI to display a file</div>
          </ng-template>
          <ng-template pTemplate="content" let-files>
              <div>Additional content.</div>
          </ng-template>
        </zxing-scanner>
      </div>
    </div>
  </div>

<!-- <div class="serial-input-group p-mt-3 p-mb-2" *ngIf="isEditable && showCreate">
  <label class="serial-group-label">Range input</label>
  <div class="p-mt-1 p-fluid p-grid p">
    <div class="p-col-12 p-md-2">
      <input type="text" [(ngModel)]="serialModalConfig.range.prefix" placeholder="prefix" pInputText>
    </div>
    <div class="p-col p-md-2">
      <input type="text" [(ngModel)]="serialModalConfig.range.from" (change)="serialModalRangeChange()" placeholder="starts from" pInputText>
    </div>
    <span class="align-self-center">-</span>
    <div class="p-col p-md-2">
      <input type="text" [(ngModel)]="serialModalConfig.range.to" (change)="serialModalRangeChange()" placeholder="ends at" pInputText>
    </div>
    <div class="p-col-12 p-md-2">
      <input type="text" [(ngModel)]="serialModalConfig.range.suffix" placeholder="suffix" pInputText>
    </div>
    <div class="p-col">
      <span class="p-float-label">
        <input id="serial-number-range-total" type="text" disabled [(ngModel)]="serialModalConfig.range.total" pInputText>
        <label for="serial-number-range-total">Total</label>
      </span>
    </div>
    <div class="align-self-center p-col-6 p-md-2">
      <p-button label="Add" styleClass="p-button-sm" (click)="addSerialByRange()"></p-button>
    </div>
  </div>
</div> -->
  <div class="p-fluid p-grid p-mt-1">
    <div class="p-col-12 p-md-3" *ngIf="isEditable && showCreate">
      <div>
        <ngx-file-drop class="ngx-file-drop" dropZoneLabel="Drop files here" (onFileDrop)="serialFileDropped($event)" [accept]="'.csv,.txt'"
          [dropZoneClassName]="'custom-drop-zone'" [contentClassName]="'custom-drop-content'">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="custom-drop-content-content-selector" (click)="openFileSelector()">
              <div><i class="pi pi-cloud-upload dragndrop-upload-icon"></i></div>
              <div>Drop File or Click to Browse</div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </div>
    <div class="p-col-12" [ngClass]="{ 'p-md-9': isEditable && showCreate }">
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-d-flex">
            <div>
              <span class="p-float-label">
                <input id="serial-number-input" type="text" [(ngModel)]="serialFilter" autocomplete="off" (ngModelChange)="serialFilterChanged($event)" pInputText>
                <label for="serial-number-input">Serial Filter</label>
              </span>
            </div>
            <div class="align-self-center p-ml-2 p-d-flex">
              <p-button label="Clear" styleClass="p-button-sm" (click)="clearFilter()"></p-button>
              <p-button *ngIf="isEditable && showCreate" label="Delete Serial Number" styleClass="p-button-sm p-ml-2" (click)="confirmSerialBulkDelete()"></p-button>
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <p-table
            styleClass="p-datatable-sm p-datatable-striped serial-table"
            [value]="_serialList"
            responsiveLayout="scroll"
            [scrollable]="false"
            [resizableColumns]="false"
            [reorderableColumns]="false"
            [(selection)]="selectedSerialRow"
            [dataKey]="'serial'"
          >
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngIf="isEditable && showCreate" class="table-checkbox-table-col">
                <col style="width:auto">
                <col *ngIf="isEditable && showCreate" style="width:4.75rem;">
                <ng-container *ngIf="(selectedType == SELECTEDTYPE.CHANNELTRANSFER || selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER || selectedType == SELECTEDTYPE.ASSORTMENT) && orderStatusId != STATUSID.DRAFT">
                  <ng-container *ngFor="let receiveStatus of serialReceiveStatus">
                    <col style="width:auto">
                  </ng-container>
                </ng-container>
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th *ngIf="isEditable && showCreate" scope="col" class="table-checkbox-table-cell">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th id="h1">Serial Number</th>
                  <th id="h2" *ngIf="isEditable && showCreate"></th>
                  <ng-container *ngIf="isSerialShowReceive">
                    <ng-container *ngFor="let receiveStatus of serialReceiveStatus">
                      <th id="{{'serial-th-' + receiveStatus.key}}" *ngIf="(selectedType == SELECTEDTYPE.CHANNELTRANSFER || selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER || selectedType == SELECTEDTYPE.ASSORTMENT)">
                        <p-checkbox
                          *ngIf="isCheckingReceived && showCreate"
                          [disabled]="receiveStatus.key === 'cancelled' || receiveStatus.key === 'writeOff'"
                          binary="true"
                          class="p-mx-2"
                          [(ngModel)]="serialReceiveAll[receiveStatus.key]"
                          (onChange)="serialReceiveStatusChangeAll($event, receiveStatus.key)"
                        >
                        </p-checkbox>
                        {{receiveStatus.label}}
                      </th>
                    </ng-container>
                  </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                  <td *ngIf="isEditable && showCreate" class="table-checkbox-table-cell">
                      <p-tableCheckbox [value]="data"></p-tableCheckbox>
                  </td>
                  <td>{{data.serial}}</td>
                  <td *ngIf="isEditable && showCreate">
                    <p-button label="Delete" styleClass="p-button-sm p-button-outlined" *ngIf="isEditable && showCreate" (click)="onSerialRowDelete(data)"></p-button>
                  </td>
                  <ng-container *ngIf="(selectedType == SELECTEDTYPE.CHANNELTRANSFER || selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER || selectedType == SELECTEDTYPE.ASSORTMENT) && orderStatusId != STATUSID.DRAFT">
                    <td *ngFor="let receiveStatus of serialReceiveStatus">
                      <p-checkbox *ngIf="!isCheckingReceived || !showCreate || data.skipCheckForReceive"
                        class="p-mx-2" [disabled]="true" binary="true" [ngModel]="data[receiveStatus.key]"></p-checkbox>
                      <p-checkbox *ngIf="isCheckingReceived && showCreate && !data.skipCheckForReceive"
                        class="p-mx-2" [disabled]="receiveStatus.key === 'cancelled' || receiveStatus.key === 'writeOff'" binary="true" [(ngModel)]="data[receiveStatus.key]" (ngModelChange)="serialReceiveStatusChange($event, data, receiveStatus.key)"></p-checkbox>
                    </td>
                  </ng-container>
                </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="setSerialModalVisible(false)"></button>
    <button *ngIf="isEditable && showCreate" pButton pRipple label="Save" icon="pi pi-check" (click)="saveSerial()"></button>
    <button *ngIf="isCheckingReceived && showCreate" pButton pRipple label="Save" icon="pi pi-check" (click)="updateSerialReceiveStatus()"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="uploadModalConfig.visible" [modal]="true" (onHide)="onUploadModalHide()"
  [draggable]="false" [resizable]="false" [styleClass]="'cus-modal cus-modal-lg'">
  <ng-template pTemplate="header">
    <h3 *ngIf="uploadModalConfig.title">{{uploadModalConfig.title}}</h3>
  </ng-template>
  <div class="p-mb-2" *ngIf="!uploadModalConfig.viewOnly">
    <p-fileUpload #popupPUploadEl customUpload="true" (uploadHandler)="confirmUpload($event, popupPUploadEl)">
      <ng-template let-file let-i="index" pTemplate="file">
        <div class="p-fileupload-row">
          <div class="p-fileupload-filename overflow-wrap-break-word">{{file.name}}</div>
          <div>{{popupPUploadEl.formatSize(file.size)}}</div>
          <div>
            <button type="button" icon="pi pi-times" pButton (click)="popupPUploadEl.remove($event,i)" [disabled]="popupPUploadEl.uploading"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="content" let-files>
        <div *ngIf="!files || files.length==0">
          <!-- <div class="dragdropinfo">
            <i class="pi pi-cloud-upload dragdropicon"></i>
          </div> -->
          <div class="dragdropinfo">
            Drag and Drop here
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="cus-fix-scroll-y">
    <p-table
      [rowHover]="false"
      [paginator]="false"
      [showCurrentPageReport]="false"
      [value]="uploadModalConfig.table.data"
      [columns]="uploadModalConfig.table.head"
      [customSort]="true"
      [scrollable]="true"
      [loading]="uploadModalConfig.table.loading"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup >
          <ng-container *ngFor="let col of columns">
            <col *ngIf="col.disabled!=true" [style]="{width: col.width || '250px'}">
          </ng-container>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th [id]="col.title" *ngIf="col.disabled!=true">
              {{col.title}}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <ng-container *ngFor="let h of columns">
            <td *ngIf="h.disabled!=true">
              <span *ngIf="h.type=='datetime'" [title]="rowData[h.key]">{{rowData[h.key]|date:'yyyy/MM/dd HH:mm:ss'}}</span>
              <span *ngIf="h.type=='filename'" [title]="rowData[h.key]"><a tabIndex=1 class="cus-clickable" (click)="downloadFile(rowData)">{{rowData[h.key]}}</a></span>
              <span *ngIf="h.type=='delete'">
                <!-- <a tabIndex=1 class="cus-clickable" (click)="store.deleteFile(rowData)">Delete</a> -->
                <button pButton label="Delete" icon="pi pi-trash" [disabled]="uploadModalConfig.viewOnly" (click)="attachmentDeleteOnClick(rowData)"></button>
              </span>
              <span *ngIf="!h.type" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
            </td>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length>2?6:2" style="text-align: center">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-outlined" (click)="hideUploadModal()"></button>
    <!-- <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="store.assignActionBy()"></button> -->
  </ng-template>
</p-dialog>

<p-blockUI [blocked]="loadingSwitch"></p-blockUI>

<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectButtonStyleClass]="'p-button-outlined'" [rejectVisible]="false">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>

