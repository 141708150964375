<!-- 
  this component use to fix primeng 11 multiselect allChecked performance issue in angular 11
  https://github.com/primefaces/primeng/blob/11.1.0/src/app/components/multiselect/multiselect.ts#L821C32-L821C32 
-->
<div
  #container
  [ngClass]="{
    'p-multiselect p-component': true,
    'p-multiselect-open': overlayVisible,
    'p-multiselect-chip': display === 'chip',
    'p-focus': focus,
    'p-disabled': disabled
  }"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="onMouseclick($event, in)"
>
  <div class="p-hidden-accessible">
    <input
      #in
      type="text"
      readonly="readonly"
      [attr.id]="inputId"
      [attr.name]="name"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
      (keydown)="onKeydown($event)"
      aria-haspopup="listbox"
      [attr.aria-expanded]="overlayVisible"
      [attr.aria-labelledby]="ariaLabelledBy"
      role="listbox"
    />
  </div>
  <div
    class="p-multiselect-label-container"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
    [positionStyle]="tooltipPositionStyle"
    [tooltipStyleClass]="tooltipStyleClass"
  >
    <div
      class="p-multiselect-label"
      [ngClass]="{
        'p-placeholder': valuesAsString === (defaultLabel || placeholder),
        'p-multiselect-label-empty':
          (valuesAsString == null || valuesAsString.length === 0) &&
          (placeholder == null || placeholder.length === 0)
      }"
    >
      <ng-container *ngIf="!selectedItemsTemplate">
        <ng-container *ngIf="display === 'comma'">{{
          valuesAsString || "empty"
        }}</ng-container>
        <ng-container *ngIf="display === 'chip'">
          <div
            #token
            *ngFor="let item of value; let i = index"
            class="p-multiselect-token"
          >
            <span class="p-multiselect-token-label">{{
              findLabelByValue(item)
            }}</span>
            <span
              *ngIf="!disabled"
              class="p-multiselect-token-icon pi pi-times-circle"
              (click)="removeChip(item, $event)"
            ></span>
          </div>
          <ng-container *ngIf="!value || value.length === 0">{{
            placeholder || defaultLabel || "empty"
          }}</ng-container>
        </ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="selectedItemsTemplate; context: { $implicit: value }"
      ></ng-container>
    </div>
  </div>
  <div class="p-multiselect-trigger width-auto" [ngClass]="{ 'p-d-none': !filled }" (click)="clearAll($event)">
    <i class="pi pi-times"></i>
  </div>
  <div [ngClass]="{ 'p-multiselect-trigger': true }">
    <span class="p-multiselect-trigger-icon" [ngClass]="dropdownIcon"></span>
  </div>
  <div
    *ngIf="overlayVisible"
    [ngClass]="['p-multiselect-panel p-component']"
    [@overlayAnimation]="{
      value: 'visible',
      params: {
        showTransitionParams: showTransitionOptions,
        hideTransitionParams: hideTransitionOptions
      }
    }"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
    (@overlayAnimation.done)="onOverlayAnimationDone($event)"
    [ngStyle]="panelStyle"
    [class]="panelStyleClass"
    (keydown)="onKeydown($event)"
  >
    <div class="p-multiselect-header" *ngIf="showHeader">
      <ng-content select="p-header"></ng-content>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <div
        class="p-checkbox p-component"
        *ngIf="showToggleAll && !selectionLimit"
        [ngClass]="{ 'p-checkbox-disabled': disabled || toggleAllDisabled }"
      >
        <div class="p-hidden-accessible">
          <input
            type="checkbox"
            readonly="readonly"
            [checked]="allChecked"
            (focus)="onHeaderCheckboxFocus()"
            (blur)="onHeaderCheckboxBlur()"
            (keydown.space)="toggleAll($event)"
            [attr.disabled]="disabled || toggleAllDisabled"
          />
        </div>
        <div
          class="p-checkbox-box"
          role="checkbox"
          [attr.aria-checked]="allChecked"
          [ngClass]="{
            'p-highlight': allChecked,
            'p-focus': headerCheckboxFocus,
            'p-disabled': disabled || toggleAllDisabled
          }"
          (click)="toggleAll($event)"
        >
          <span
            class="p-checkbox-icon"
            [ngClass]="{ 'pi pi-check': allChecked }"
          ></span>
        </div>
      </div>
      <div class="p-multiselect-filter-container" *ngIf="filter">
        <input
          #filterInput
          type="text"
          role="textbox"
          [value]="filterValue || ''"
          (input)="onFilter($event)"
          class="p-multiselect-filter p-inputtext p-component"
          [disabled]="disabled"
          [attr.placeholder]="filterPlaceHolder"
          [attr.aria-label]="ariaFilterLabel"
        />
        <span class="p-multiselect-filter-icon pi pi-search"></span>
      </div>
      <button
        class="p-multiselect-close p-link"
        type="button"
        (click)="close($event)"
        pRipple
      >
        <span class="p-multiselect-close-icon pi pi-times"></span>
      </button>
    </div>
    <div
      class="p-multiselect-items-wrapper"
      [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'"
    >
      <ul
        class="p-multiselect-items p-component"
        role="listbox"
        aria-multiselectable="true"
      >
        <ng-container *ngIf="!virtualScroll; else virtualScrollList">
          <ng-template
            ngFor
            let-option
            let-i="index"
            [ngForOf]="optionsToRender"
          >
            <p-multiSelectItem
              [option]="option"
              [selected]="isSelected(option)"
              [label]="getOptionLabel(option)"
              [disabled]="isOptionDisabled(option)"
              (onClick)="onOptionClick($event)"
              (onKeydown)="onOptionKeydown($event)"
              [template]="itemTemplate"
            ></p-multiSelectItem>
          </ng-template>
        </ng-container>
        <ng-template #virtualScrollList>
          <cdk-virtual-scroll-viewport
            #viewport
            [ngStyle]="{ height: scrollHeight }"
            [itemSize]="itemSize"
            *ngIf="virtualScroll && !emptyOptions"
          >
            <ng-container
              *cdkVirtualFor="
                let option of optionsToRender;
                let i = index;
                let c = count;
                let f = first;
                let l = last;
                let e = even;
                let o = odd
              "
            >
              <p-multiSelectItem
                [option]="option"
                [selected]="isSelected(option)"
                [label]="getOptionLabel(option)"
                [disabled]="isOptionDisabled(option)"
                (onClick)="onOptionClick($event)"
                (onKeydown)="onOptionKeydown($event)"
                [template]="itemTemplate"
                [itemSize]="itemSize"
              ></p-multiSelectItem>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-template>
        <li *ngIf="emptyOptions" class="p-multiselect-empty-message">
          {{ emptyFilterMessage }}
        </li>
      </ul>
    </div>
    <div class="p-multiselect-footer" *ngIf="footerFacet || footerTemplate">
      <ng-content select="p-footer"></ng-content>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>
</div>