  <illustration name="Transaction History" path="Detail page"></illustration>

<p-panel header="FILTER" class="p-col-12 p-p-0" [toggleable]="true">
      <div class="p-fluid p-grid">
        <ng-container *ngFor="let ipt of queryPannel.ipts; index as i">
          <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" (change)="change(i,$event,ipt )"></app-input-cst>
          <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="change(i,$event,ipt)"></app-select-cst>
          <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="change(i,$event,ipt)"></app-treeSelect-cst>
          <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="change(i,$event,ipt)" (close)="onClose()"></app-daterange-cst>
          <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="change(i,$event,ipt)"></app-datetime-cst>
          <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="change(i,$event,ipt)"></app-select-multiple-cst>
          <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="change?change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
        </ng-container>
        <div [class]="queryPannel.btnsclass">
          <button *ngFor="let btn of queryPannel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;"></button>
        </div>
      </div>
</p-panel>


  <div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
    <div class="p-d-flex p-jc-between p-mb-2">
      <span class="panel-title"></span>
      <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="title" selectedItemsLabel="{{selectedColumns.length == cols.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"  [style]="{ minWidth: '200px' }" placeholder="Choose Columns" ></p-multiSelect>
    </div>
    <p-table
      p-table-custom
      [columns]="selectedColumns"
      scrollable="true"
      [rowHover]="false"
      [frozenColumns]="frozenCols"
      [value]="lisOfData"
      resizableColumns="true"
      reorderableColumns="false"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [loading]="loadingSwitch"
      styleClass="p-datatable-sm p-datatable-striped"
      dataKey="id"
      class="custom-expand-table"
      [frozenWidth]="frozenWidth"
      [sortField]="field"
      [sortOrder]="order"
      [scrollHeight]="dyHeight">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th
              *ngIf="col.sortable!=false"
              pResizableColumn
              [pSortableColumn]="col.sortable!=false?col.header:false"
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </th>
            <th
              *ngIf="col.sortable==false"
              pResizableColumn
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
            </th>
          </ng-container>
          <!-- <th *ngFor="let col of columns"
            (click)="sortSearch(col.header)"
            [pSortableColumn]="col.header"
            [title]="col.fullTitle || col.title"
            pResizableColumn
            pReorderableColumn
            [id]="col.title"
          >
            {{ col.title }}
            <ng-container *ngIf="col.header !== 'temp'">
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </ng-container>
          </th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-columns="columns" let-expanded="expanded" >
        <tr style="height: 58px">
          <ng-container *ngFor="let col of columns">
            <td class="p-text-truncate" [ngClass]="{'p-py-0': col.header == 'skuDesc'}">
              <button *ngIf="col.type==='expandControl'"
              type="button" pButton pRipple
              [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              <ng-container *ngIf="col.header === 'temp'">
                <button type="button" pButton pRipple [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" ></button>
              </ng-container>
              <ng-container *ngIf="col.type!=='expandControl' && col.header !== 'temp'" >
                <span *ngIf="col.type == 'qty'" [title]="data[col.header]">
                  <a *ngIf="data._serialList&&data._serialList.length>0" class="clickable" href="javascript:void(0)" (click)="showTxnSerial(data)">{{ data[col.header] }}</a>
                  <span *ngIf="!data._serialList||data._serialList.length==0">{{ data[col.header] }}</span>
                </span>
                <span *ngIf="col.type === 'date'" [title]="data[col.header] | date:'yyyy/MM/dd hh:mm:ss'" >{{data[col.header] | date:'yyyy/MM/dd ahh:mm:ss'}}</span>
                
                <ng-container *ngIf="col.type=='link'">
                  <span *ngIf="data[col.isClickableKey]" class="cus-clickable" [title]="data[col.header]" (click)="col.onClick($event,data)">{{data[col.header]}}</span>
                  <span *ngIf="!data[col.isClickableKey]" [title]="data[col.header]">{{data[col.header]}}</span>
                </ng-container>
                
                <span *ngIf="col.header == 'skuDesc'" class="cus-webkit-line-clamp-3 em-09" [title]="data[col.header]"><div><p>{{ data[col.header] }}</p></div></span>
                <span *ngIf="col.type !== 'date' && col.type !== 'qty' && col.type !== 'link' && col.header !== 'skuDesc' " [title]="data[col.header]">{{ data[col.header] }}</span>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
        <tr class="p-d-none"></tr>
        <tr class="expand-row">
          <td [attr.colspan]="columns.length">
            <div class="p-d-flex expand-row-wrapper-container">
              <div class="expand-row-wrapper">
                <div class="expand-row-content-wrapper">
                  <div class="expand-row-content">
                    <!-- content -->
                    <div class="p-fluid p-grid p-mt-2 p-mb-2">
                      <ng-container *ngFor="let col of collapseColumns">
                        <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                          <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==frozenCols}">
                            <input
                              *ngIf="col.type !== 'date'"
                              class="p-inputtext-sm"
                              type="text"
                              [attr.id]="col.header"
                              [class]="{'p-input-sm':true}"
                              pInputText [ngModel]="data[col.header]"
                              [disabled]='true'
                              [readonly]='true'>
                            <input
                              *ngIf="col.type === 'date'"
                              class="p-inputtext-sm"
                              type="text"
                              [attr.id]="col.header"
                              [class]="{'p-input-sm':true}"
                              pInputText [ngModel]="data[col.header] | date: 'yyyy/MM/dd hh:mm:ss'"
                              [disabled]='true'
                              [readonly]='true'>
                            <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length>6?6:columns.length" style=" text-align: center;">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20, 30, 50]" [totalRecords]="totalRecords" (onPageChange)="page($event)" > </p-paginator>
  </div>

  <p-dialog [(visible)]="serialModalConfig.visible" [modal]="true"
    [draggable]="false" [resizable]="false" [styleClass]="'serial-modal'">
    <ng-template pTemplate="header">
      <h3>{{serialModalConfig.header}}</h3>
    </ng-template>
    <div class="p-fluid p-grid p-mt-1">
      <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="serialModalConfig.itemCode"></app-input-cst>
      <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="serialModalConfig.itemDesc"></app-input-cst>
      <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="serialModalConfig.qty"></app-input-cst>
    </div>
    <div class="p-mt-1">
      <p-table
        styleClass="p-datatable-sm p-datatable-striped"
        [value]="serialModalConfig.serialList"
        responsiveLayout="scroll"
        [scrollable]="false"
        [resizableColumns]="false"
        [reorderableColumns]="false"
      >
        <ng-template pTemplate="header">
            <tr>
                <th id="serial">Serial Number</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>{{data.serial}}</td>
            </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
