<!-- <div class="wraper">
  <h1><i class="pi pi-exclamation-triangle"></i></h1>
  <h2>Page Not Found (404 Error)</h2>
  <h3>You are not authorized to access this page.</h3>
  <h3>Please contact system admin</h3>
  <h3>Or</h3>
  <h3 ><a [routerLink]="['/login']" (click)="logout()" >Login as different user</a></h3>
</div> -->

<div class="content-body">
  <div class="inner-content">
    <h1><i class="pi pi-exclamation-triangle"></i></h1>
    <h2>Page Not Found (404 Error)</h2>
    <h3>You are not authorized to access this page.</h3>
    <h3>Please contact admin</h3>
    <h3>or</h3>
    <h3>
      <a class="logout-text" (click)="logout()" tabindex="1" (keydown.enter)="logout()">Login as different user</a>
    </h3>
  </div>
</div>
