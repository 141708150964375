<div class="p-shadow-1 wrapper">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <span>
      <!-- 遍历按钮组 -->
      <ng-container *ngFor="let btn of store.btns">
        <button *ngIf="btn.show" pButton class="p-button-sm" [label]="btn.title" (click)="btn.handler.click()"
          [disabled]="(btn.title === 'Edit' || btn.title === 'Delete') && !store.selectedProducts.length" style="margin-right: 10px;top: -2px;">
        </button>
      </ng-container>
      <p-multiSelect [options]="store.head" [(ngModel)]="store.selectedColumns" optionLabel="title" selectedItemsLabel="{{store.selectedColumns.length == store.head.length ? 'ALL columns' : store.selectedColumns.length+' columns displayed'}}"  [style]="{minWidth: '200px'}" placeholder="Choose Columns" ></p-multiSelect>
    </span>
  </div>

  <div>
      <p-table
      styleClass="p-datatable-sm p-datatable-striped custom-table"
      [columns]="store.selectedColumns"
      [frozenColumns]="store.frozenColumns ? store.frozenColumns : null"
      [value]="store.display_data"
      [(selection)]="store.selectedProducts"
      [rowHover]="isHover"
      [scrollable]="true"
      [rows]="20"
      [paginator]="false"
      [rowsPerPageOptions]="[20,30,50]"
      [loading]="store.loadingSwitch"
      [resizableColumns]="true"
      [reorderableColumns]="true"
      [customSort]="true"
      (onPage)="searchStore.page($event)"
      [totalRecords]="searchStore.totalRecords"
      dataKey="id"
      [frozenWidth]="store.frozenWidth || null"
      [sortField]="store.sortField || null"
      [sortOrder]="store.sortOrder || 1"
      (sortFunction)="searchStore.doSortSearchBySortFunction($event.field, $event.order)"
      scrollHeight="500px"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup *ngIf="scrollable">
          <ng-container *ngIf="!hasAttr">
            <col *ngIf="store.showCheckboxFlag" style="width: 3rem">
            <col *ngIf="!store.showCheckboxFlag" style="width: 4rem">
          </ng-container>
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
          <col *ngIf="rowEditable" style="width:250px">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngIf="!hasAttr">
            <!-- <th *ngIf="store.showCheckboxFlag" style="width: 3rem" id="h1"> -->
            <th *ngIf="store.showCheckboxFlag" style="width: 3rem" id="h2">
              <p-tableHeaderCheckbox (click)="store.selectAllPNCheckbox($event)" style="margin-left: 5px"></p-tableHeaderCheckbox>
            </th>
            <th *ngIf="!store.showCheckboxFlag" id="h3"></th>
          </ng-container>
          <th
            *ngFor="let h of columns"
            pResizableColumn
            pReorderableColumn
            [title]="h.fullTitle || h.title"
            [style]="{'border-right' : '2px solid #A6D5FA','padding':'5px','white-space': 'normal','min-width':h.minwidth}"
            [pSortableColumn]="h.key"
            [id]="h.title"
          >
            <div style="display: inline-flex;align-items: center;">
              <span style="flex:1;text-align: center;">{{h.title}}</span>
              <p-sortIcon [field]="h.key"></p-sortIcon>
            </div>
          </th>
          <th *ngIf="rowEditable" id="action">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns"  let-expanded="expanded">
        <tr>
          <ng-container *ngIf="!hasAttr">
            <td *ngIf="store.showCheckboxFlag" style="width: 3rem">
              <p-tableCheckbox [value]="rowData"  (click)="store.selectOnePNCheckBox(rowData.id)" style="margin-left: 5px"></p-tableCheckbox >
            </td>
            <td *ngIf="!store.showCheckboxFlag">
              <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="width: 40px;height: 40px;padding: unset"></button>
            </td>
          </ng-container>
          <td [style]="{'text-align': h['text-align']}"
            (click)="searchStore.openDet(rowData)"
            *ngFor="let h of columns"
            class="ui-resizable-column p-text-truncate"
          >
            <p-cellEditor *ngIf="rowEditable">
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="rowData[h.key]">
              </ng-template>
              <ng-template pTemplate="output">
                <span [title]="rowData[h.key]">
                  {{ rowData[h.key] }}
                </span>
              </ng-template>
            </p-cellEditor>



            <span *ngIf="!rowEditable">
              <span [ngSwitch]="h.type">
                <span *ngSwitchCase="'datetime'" [title]="rowData[h.key] | date:'yyyy/MM/dd ahh:mm:ss'">
                  {{rowData[h.key] | date:'yyyy/MM/dd ahh:mm:ss'}}
                </span>
                <span *ngSwitchCase="'date'" [title]="rowData[h.key] | date:'yyyy/MM/dd'">
                  {{rowData[h.key] | date:'yyyy/MM/dd'}}
                </span>
                <span *ngSwitchCase="'priority'" [title]="rowData[h.key] === 3 ? 'low' : (rowData[h.key] === 2 ? 'middle': 'high')">
                  {{rowData[h.key] === 3 ? 'low' : (rowData[h.key] === 2 ? 'middle': 'high')}}
                </span>
                <span *ngSwitchCase="'hyperlink'" [title]="rowData[h.key]">
                  <a *ngIf="rowData.Hyperlink==='Y'" href="javascript:void(0)" (click)="searchStore.navigate(rowData)" style="text-decoration: underline;">{{rowData[h.key]}}</a>
                  <span *ngIf="!rowData.Hyperlink || rowData.Hyperlink==='N'">{{rowData[h.key]}}</span>
                </span>
                <span *ngSwitchCase="'eventType'" [title]="rowData['eventType']">
                  {{rowData['eventType']}}
                </span>
                <ng-container *ngSwitchCase="'link'">
                  <span *ngIf="!h.isClickableKey||rowData[h.isClickableKey]" class="cus-clickable" [title]="rowData[h.key]" (click)="h.onClick($event,rowData)">{{rowData[h.key]}}</span>
                  <span *ngIf="h.isClickableKey&&!rowData[h.isClickableKey]" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
                </ng-container>
                <span *ngSwitchDefault [title]="rowData[h.key]">
                  {{rowData[h.key]}}
                </span>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-product let-rowIndex="rowIndex">
        <tr>
          <td colspan="7">
            <div class="p-p-3" style="margin: 25px">

              <p-table [value]="product.line" [resizableColumns]="true" [columns]="store.lineCols"
                [reorderableColumns]="true" (sortFunction)="customSort($event)"
                [customSort]="true">
                <ng-template pTemplate="colgroup" let-columns>
                  <colgroup>
                    <col *ngFor="let col of columns">
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th
                      *ngFor="let lineCol of columns"
                      pSortableColumn="{{lineCol.header}}"
                      pResizableColumn
                      pReorderableColumn
                      [id]="lineCol.title"
                    >
                      {{lineCol.title}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      <div *ngIf="col.header === 'expiryDate'">
                        {{order[col.header] | date:'yyyy/MM/dd hh:mm:ss'}}
                      </div>
                      <div *ngIf="col.header !== 'expiryDate'">
                        <span [title]="order[col.header]">
                          {{ order[col.header] }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="store.showCheckboxFlag?columns.length+1:columns.length" style=" text-align: center;" [ngClass]="{hidden: columns===store.frozenColumns}">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator #paginator styleClass="p-paginator-sm" [rowsPerPageOptions]="[20,30,50]" [rows]="searchStore.pageSize||20" [totalRecords]="searchStore.totalRecords" (onPageChange)="searchStore.page($event)"></p-paginator>
  </div>
</div>
