<span class="p-float-label">
  <p-calendar [appendTo]="'body'" inputId="calendar" [(ngModel)]="ipt.value" [showIcon]="!ipt.notIcon" [disabled]="ipt.disabled"
    (ngModelChange)="ngModelChange($event)"
    [defaultDate]="ipt.defaultDate || null"
    [hourFormat]="ipt.hourFormat||null"
    dateFormat="yy/mm/dd" [showTime]="ipt.showTime || false" 
    [showSeconds]="(ipt.showTime && !ipt.hideSeconds) || false" 
    (onShow)="onShow()"
    (onSelect)="onSelect($event)"
    (onInput)="onInput($event)"
    [showButtonBar]="ipt.showButtonBar || false"
    [todayButtonStyleClass]="ipt.todayButtonStyleClass || 'p-button-text'"
    [clearButtonStyleClass]="ipt.clearButtonStyleClass || 'p-button-text'"
    [stepMinute]="(ipt.showTime&&ipt.stepMinute)?ipt.stepMinute:1"
    [hideOnDateTimeSelect]="ipt.hideOnDateTimeSelect!=null?ipt.hideOnDateTimeSelect:true"
    [minDate]="ipt.minDate" [maxDate]="ipt.maxDate" [class]="{'p-calendar-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}"></p-calendar>
  <label for="calendar">{{ipt.title}}</label>
</span>
