<illustration name="Stock Allocation" path="Stock Allocation"></illustration>
<app-query-pannel [store]="queryPannel" headerTilte="CREATE"></app-query-pannel>

<div
  class="p-shadow-1"
  style="
    background-color: white;
    margin: 20px 0;
    border-radius: 5px;
    padding: 25px;
  "
>
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <p-multiSelect
      [options]="cols"
      [(ngModel)]="selectedColumns"
      optionLabel="title"
      selectedItemsLabel="{{
        selectedColumns.length == cols.length
          ? 'ALL columns'
          : selectedColumns.length + ' columns displayed'
      }}"
      [style]="{ minWidth: '200px' }"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="true"
    [scrollable]="true"
    [resizableColumns]="false"
    [reorderableColumns]="false"
    [value]="filteredAllocationDataList"
    [columns]="selectedColumns"
    [loading]="loadingSwitch"
    dataKey="id"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col
          *ngFor="let col of columns"
          [style]="{ width: col.width || '300px' }"
        />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          pResizableColumn
          pReorderableColumn
          [pSortableColumn]="col.header"
          [title]="col.fullTitle || col.title"
          (click)="sortSearch(col.header)"
          [id]="col.title"
        >
          {{ col.title }}
          <p-sortIcon [field]="col.header"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-data
      let-index
      let-columns="columns"
      let-rowIndex="rowIndex"
    >
      <tr style="height: 53px">
        <td *ngFor="let col of columns">
          <div
            *ngIf="showU && col.type === 'action'"
            style="display: inline-flex"
          >
            <button
              class="p-button-sm p-mr-1"
              *ngIf="data.edit && showU"
              pButton
              label="Save"
              (click)="save(data, rowIndex)"
            ></button>
            <button
              class="p-button-sm p-mr-1"
              *ngIf="showU && !data.edit"
              pButton
              label="Edit"
              (click)="editDetail(data)"
            ></button>
            <button
              class="p-button-sm p-button-outlined"
              *ngIf="data.edit && showU"
              pButton
              label="Cancel"
              (click)="cancel(data, rowIndex)"
            ></button>
          </div>
          <input
            *ngIf="col.type === 'txt'"
            [disabled]="!isEdit"
            pInputText
            class="p-inputtext-sm"
            [(ngModel)]="data[col.header]"
            [style]="{ width: '100%' }"
          />
          <ng-container *ngIf="col.type === 'number' && col.header == 'qty_adjust_allocate'">
            <p-inputNumber
              [disabled]="!isEdit"
              class="p-inputtext-sm"
              (ngModelChange)="onTableValueChange($event, data, col)"
              (onInput)="onTableValueInput($event, data, col)"
              [(ngModel)]="data[col.header]"
              [min]="0"
              [max]="data['qty_pending']"
              [inputStyle]="{ width: '100%' }"
              [mode]="'decimal'"
            ></p-inputNumber>
          </ng-container>
          <span
            *ngIf="col.type === 'date'"
            [title]="data[col.header] | date: 'yyyy/MM/dd'"
            >{{ data[col.header] | date: "yyyy/MM/dd" }}</span
          >
          <span *ngIf="!col.type" [title]="data[col.header]" [ngClass]="col.class">{{
            data[col.header]
          }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="totalRecords" (onPageChange)="pageSpecAllocationForReservationList($event)"></p-paginator>
</div>

<p-confirmDialog #cd header="Confirmation" [rejectButtonStyleClass]="'p-button-outlined'">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
