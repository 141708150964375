<p-panel [header]="headerTilte" class="p-col-12 p-p-0 p-mt-5 custom-panel" [toggleable]="true" [style]="{'margin-top': '20px'}">
  <p-progressBar mode="indeterminate" [style.visibility]="store.loading||'hidden'" [style]="{position: 'relative', top: '-48px'}"></p-progressBar>
  <div class="p-fluid p-grid">
    <ng-container *ngFor="let ipt of store.ipts; index as i">
      <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-cst>
      <app-inputnumber-cst [class]="ipt.class" *ngIf="ipt.type==11" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-inputnumber-cst>
      <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (click)="store.handler?store.handler.click(ipt):null" (change)="store.change?store.change(i,$event,ipt):null"></app-select-cst>
      <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-treeSelect-cst>
      <app-textarea-cst [class]="ipt.class" *ngIf="ipt.type==4" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-textarea-cst>
      <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-daterange-cst>
      <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-datetime-cst>
      <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-multiple-cst>
      <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-checkboxt-cst>
      <app-select-group [class]="ipt.class" *ngIf="ipt.type==20" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-group>
      <app-input-limit-cst [class]="ipt.class" *ngIf="ipt.type==26" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-limit-cst>
      <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
    </ng-container>

    <p-fieldset [legend]="store.extended.title" [class]="store.extended.class" *ngIf="store?.extended?.type===25 && store?.extended.show">
      <div class="p-fluid p-grid">
        <ng-container *ngFor="let ipt of store.extended.ipts; index as i">
          <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-cst>
          <app-inputnumber-cst [class]="ipt.class" *ngIf="ipt.type==11" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-inputnumber-cst>
          <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (click)="store.handler?store.handler.click(ipt):null" (change)="store.change?store.change(i,$event,ipt):null"></app-select-cst>
          <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-treeSelect-cst>
          <app-textarea-cst [class]="ipt.class" *ngIf="ipt.type==4" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-textarea-cst>
          <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-daterange-cst>
          <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-datetime-cst>
          <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-multiple-cst>
          <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-checkboxt-cst>
          <app-select-group [class]="ipt.class" *ngIf="ipt.type==20" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-group>
          <app-input-limit-cst [class]="ipt.class" *ngIf="ipt.type==26" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-limit-cst>
          <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
        </ng-container>
      </div>
    </p-fieldset>

    <div [class]="store.btnsclass">
      <ng-container *ngFor="let btn of store.btns">
        <button *ngIf="btn.show!=false&&btn.type!='wrapper'" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width:auto;height: 35px;" [disabled]="btn.disabled || disableBtn"></button>
        <div *ngIf="btn.show!=false&&btn.type=='wrapper'" [class]="btn.class"></div>
      </ng-container>
    </div>
  </div>
</p-panel>

