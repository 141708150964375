<illustration [name]="'Stock Take'"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.tableData.loadingSwitch"></p-progressBar>
<app-query-pannel *ngIf="store.queryData" [store]="store.queryData" [headerTilte]="'Create'"></app-query-pannel>

<!-- <p-panel [header]="'Add Items'" [toggleable]="true" class="p-col-12 p-mt-3 p-d-block p-p-0 p-shadow-1" *ngIf="store.bulkAddQueryPanel">
  <div class="p-fluid p-grid">
    <ng-container *ngFor="let ipt of store.bulkAddQueryPanel.ipts; index as i">
      <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
      <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-treeSelect-cst>
      <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
      <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
    </ng-container>
    <div [class]="store.bulkAddQueryPanel.btnsclass">
      <button *ngFor="let btn of store.bulkAddQueryPanel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;"></button>
    </div>
  </div>
</p-panel> -->

<p-panel [header]="'Add Items'" [toggleable]="true" class="p-col-12 p-mt-3 p-d-block p-p-0 p-shadow-1" *ngIf="store.filterAddPanel">
  <div class="p-fluid p-grid">
    <ng-container *ngFor="let ipt of store.filterAddPanel.ipts; index as i">
      <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
      <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-treeSelect-cst>
      <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
      <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
      <div *ngIf="ipt.type==null" [class]="ipt.class"></div>
    </ng-container>
    <div [class]="store.filterAddPanel.btnsclass">
      <button *ngFor="let btn of store.filterAddPanel.btns" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;"></button>
    </div>
  </div>
</p-panel>

<div class="p-shadow-1" #dropdownTarget>
  <!-- <p class="p-fluid p-grid">
    <app-select-multiple-cst [ipt]="store.tableData.ipt"></app-select-multiple-cst>
  </p> -->
  <!-- <div class="p-mb-1">
    <p-button label="Add Stock Take Item" styleClass="p-button-sm" (click)="store.addItem()" [disabled]="store.addItemDisabled"></p-button>
  </div> -->
  <p-table
    [rowHover]="true"
    [paginator]="false"
    [showCurrentPageReport]="false"
    [value]="store.tableData.data"
    [columns]="store.tableData.head"
    [loading]="store.tableData.loadingSwitch"
    [sortField]="store.tableData.sortField || null"
    [sortOrder]="store.tableData.sortOrder || null"
    [customSort]="true"
    (sortFunction)="store.customSort($event)"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup >
      <col style="width:100px">
      <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th id="action" rowspan="2">Action</th>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent && col.parent.title" [attr.colspan]="col.parent.colspan?col.parent.colspan:1" [id]="col.parent">
          {{col.parent.title}}
        </th>
        <th *ngIf="!col.parent" rowspan="2" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent" pSortableColumn="{{col.key}}" [id]="col.title">
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td>
        <div style="display: inline-flex">
          <p-button label="Delete" styleClass="p-button-text p-button-sm" (click)="store.delete(rowIndex, rowData)" [disabled]="rowData.disabled"></p-button>
        </div>
      </td>
      <td *ngFor="let h of columns" class="p-text-truncate">
        <ng-container *ngIf="h.edit && h.key == 'itemDesc'">
          <p-dropdown class="p-dropdown-sm" [options]="store.tableData.ipt.options" [(ngModel)]="rowData._item" optionLabel="name" optionValue="code"
            [filter]="true" [filterBy]="'name'"
            [panelStyleClass]="'item-table-dropdown-panel'" [appendTo]="dropdownTarget" placeholder="Select a Item" [showClear]="true" [style]="{'width':'100%'}" (onChange)="store.tableItemChange($event, rowData)">
          </p-dropdown>
        </ng-container>
        <p-inputNumber class="p-inputtext-sm" *ngIf="h.edit && h.key === 'normal'"  [(ngModel)]="rowData.normal" [style]="{'width':'100%'}" [disabled]="rowData.disabled"></p-inputNumber>
        <p-inputNumber class="p-inputtext-sm" *ngIf="h.edit && h.key === 'faulty'"  [(ngModel)]="rowData.faulty" [style]="{'width':'100%'}" [disabled]="rowData.disabled"></p-inputNumber>
        <span *ngIf="!h.edit" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" [rejectVisible]="false">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-blockUI [blocked]="store.tableData.loadingSwitch"></p-blockUI>

<ngx-file-drop class="p-d-none" dropZoneLabel="Drop files here" #fileDropper (onFileDrop)="store.fileDropped($event)" [accept]="'.csv,.txt'"
  [dropZoneClassName]="'custom-drop-zone'" [contentClassName]="'custom-drop-content'">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <!-- <div class="custom-drop-content-content-selector" (click)="openFileSelector()">
      <div><i class="pi pi-cloud-upload dragndrop-upload-icon"></i></div>
      <div>Drop File or Click to Browse</div>
    </div> -->
  </ng-template>
</ngx-file-drop>
