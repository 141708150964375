<div class="content-body">
  <div class="inner-content">
    <div class="header"><i class="pi pi-cloud p-mr-2" style="font-size: 2rem; color: royalblue;"></i>Ruby Stock Management</div>
    <div class="p-mb-4">
      <span class="p-float-label p-input-icon-left p-input-icon-right">
        <i class="pi pi-user"></i>
        <i class="pi"></i>
        <input type="text" id="inputtext-account" pInputText [(ngModel)]="store.account" (keydown.enter)="store.enterLogin()">
        <label for="inputtext-account">Account</label>
      </span>
    </div>

    <div  class="p-mb-4">
      <span class="p-float-label p-input-icon-left p-input-icon-right">
        <i class="pi pi-lock"></i>
        <i class="pi" [ngClass]="!store.visible?'pi-eye':'pi-eye-slash'" (click)="store.switchView()"></i>
        <input [type]="store.visible?'text':'password'" id="inputtext-password" pInputText [(ngModel)]="store.password" (keydown.enter)="store.enterLogin()">
        <label for="inputtext-password">Password</label>
      </span>
    </div>

    <div class="p-mb-4 loading-panel">
      <button pButton label="Login" iconPos="left" (click)="store.login()" ></button>
      <i *ngIf="store.loading" class="pi pi-spin pi-spinner loading"></i>
      <div class="acloak">
        <span (click)="oauthLogin()">{{store.$CONFIG.LOGINWITHKEYCLOAKDESC || "Login with Keycloak"}}</span>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
