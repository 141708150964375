<illustration name="Stock Take"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.tableData.loadingSwitch"></p-progressBar>
<app-query-pannel [store]="store.queryData" [headerTilte]="'Check Variance'" [disableBtn]="store.tableData.loadingSwitch"></app-query-pannel>
<div class="p-shadow-1">
  <p-table
    [rowHover]="true"
    [paginator]="false"
    [rows]="20"
    [showCurrentPageReport]="false"
    [rowsPerPageOptions]="[20, 30, 50]"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [value]="store.tableData.data"
    [columns]="store.tableData.head"
    [loading]="store.tableData.loadingSwitch"
    [frozenColumns]="store.tableData.frozenColumns || null"
    [frozenWidth]="store.tableData.frozenWidth || null"
    [customSort]="true"
    [sortField]="store.tableData.sortField || null"
    [sortOrder]="store.tableData.sortOrder || null"
    dataKey="id"
    scrollHeight="500px"
    [scrollable]="store.tableData.frozenWidth?true:false"
    (sortFunction)="store.customSort($event)"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup >
      <col *ngFor="let col of columns" [style]="{width: col.width || '250px', height: col.height || '60px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>

    <tr>
      <ng-container *ngFor="let col of columns">
        <th
          *ngIf="col.parent && col.parent.title"
          pSortableColumn="{{col.key}}"
          pResizableColumn
          pReorderableColumn
          [attr.colspan]="col.parent.colspan?col.parent.colspan:1"
          [id]="col.parent"
          [style.height]='col.height'
          [style]="{'height': col.height || '60px', 'white-space': col.wrap?'break-spaces':'nowrap'}"
          [style.whiteSpace]='col.wrap'
        >
          {{col.parent.title}}
        </th>
        <th
          *ngIf="!col.parent" rowspan="2"
          pSortableColumn="{{col.key}}"
          pResizableColumn
          pReorderableColumn
          [id]="col.title"
          [style.height]='col.height'
          [style]="{'height': col.height || '60px', 'white-space': col.wrap?'break-spaces':'nowrap'}"
          [style.whiteSpace]='col.wrap'
        >
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>

      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th
          *ngIf="col.parent"
          pSortableColumn="{{col.key}}"
          [id]="col.title"
          [style.height]='col.height'
          [style]="{'height': col.height || '60px', 'white-space': col.wrap?'break-spaces':'nowrap'}"
          [style.whiteSpace]='col.wrap'
        >
          {{col.title}}
          <p-sortIcon field="{{col.key}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <ng-container *ngFor="let h of columns">
        <td [style.height]="'60px'" [style]="{height:'60px',textOverflow: h.textOverflow||'unset'}" class="p-text-truncate" [ngClass]="h.cellClass">
          <ng-container *ngIf="h.type=='text'; else elseTemplate">
            <div class="p-d-flex">
              <span style="line-height: 35px;">
                <span *ngIf="!h.keyPath" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
                <span *ngIf="h.keyPath" AppGetValueByPath [data]='rowData' [keyPath]='h.keyPath'></span>
              </span>
              <!-- hardcode logic -->
              <!-- <span *ngIf="rowData.isSerialControl==='Y' && h.count != null && rowData.countData[h.count] && ((h.key==='normal'&& rowData.countData[h.count].nv!=0) || (h.key==='faulty'&&rowData.countData[h.count].fv!=0)) && (rowData.countData[h.count]&&rowData.countData[h.count].normalSerialCount) || (rowData.countData[h.count]&&rowData.countData[h.count].faultySerialCount)" style="line-height: 35px; color: #F00; text-indent: 4px;">

                {{h.key === 'normal' ? rowData.countData[h.count].normalSerialCount : rowData.countData[h.count].faultySerialCount}}
              </span> -->
              <!-- <span *ngIf="rowData.isSerialControl==='Y' && ((h.key==='normal'&&rowData.nv!=0) ||
                (h.key==='faulty'&&rowData.fv!=0)) && h.count == store.stockTakeCount" class="p-ml-auto">
                <button class="p-button-sm p-mr-1" *ngIf="store.showU" pButton label="Scan" (click)="store.view(rowData, h.key, h.count)"></button>
              </span> -->
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <ng-container *ngIf="rowData.isSerialControl==='Y' && h.count == store.stockTakeCount; else normalTemplate">
              <a class="clickable" href="javascript:void(0)" (click)="store.compareStockTakeLineSerial(rowData, h.key, h.count)">
                <span *ngIf="!h.keyPath">{{rowData[h.key]}}</span>
                <span *ngIf="h.keyPath" AppGetValueByPath [data]='rowData' [keyPath]='h.keyPath'></span>
              </a>                
              <span *ngIf="h.keyPath && h.subfix && h.subfix.keyPath && ((rowData | getValueByKeyPath: h.subfix.keyPath)!=null)">&nbsp;
                <span class="vertical-align-middle" *ngIf="h.subfix.iconClass"><i [ngClass]="h.subfix.iconClass" [attr.title]="rowData | getValueByKeyPath: h.subfix.keyPath"></i></span>
              </span>
            </ng-container>
            <ng-template #normalTemplate>
              <span *ngIf="!h.keyPath" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
              <span *ngIf="h.keyPath" AppGetValueByPath [data]='rowData' [keyPath]='h.keyPath'></span>
            </ng-template>

          </ng-template>
        </td>
      </ng-container>

    </tr>
  </ng-template>
</p-table>
</div>
<p-dialog [(visible)]="store.uploadModalConfig.visible" [modal]="true" (onHide)="store.onUploadModalHide()"
  [draggable]="false" [resizable]="false" [styleClass]="'cus-modal cus-modal-lg'">
  <ng-template pTemplate="header">
    <h3 *ngIf="store.uploadModalConfig.title">{{store.uploadModalConfig.title}}</h3>
  </ng-template>
  <div class="p-mb-2" *ngIf="!store.uploadModalConfig.readonly">
    <p-fileUpload #popupPUploadEl customUpload="true" (uploadHandler)="store.confirmUpload($event, popupPUploadEl)">
      <ng-template let-file let-i="index" pTemplate="file">
        <div class="p-fileupload-row">
          <div class="p-fileupload-filename overflow-wrap-break-word">{{file.name}}</div>
          <div>{{popupPUploadEl.formatSize(file.size)}}</div>
          <div>
            <button type="button" icon="pi pi-times" pButton (click)="popupPUploadEl.remove($event,i)" [disabled]="popupPUploadEl.uploading"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="content" let-files>
        <div *ngIf="!files || files.length==0">
          <!-- <div class="dragdropinfo">
            <i class="pi pi-cloud-upload dragdropicon"></i>
          </div> -->
          <div class="dragdropinfo">
            Drag and Drop here
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="cus-fix-scroll-y">
    <p-table
      [rowHover]="false"
      [paginator]="false"
      [showCurrentPageReport]="false"
      [value]="store.uploadModalConfig.table.data"
      [columns]="store.uploadModalConfig.table.head"
      [customSort]="true"
      [scrollable]="true"
      [loading]="store.uploadModalConfig.table.loading"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup >
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th [id]="col.title">
              {{col.title}}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let h of columns">
            <span *ngIf="h.type=='datetime'" [title]="rowData[h.key]">{{rowData[h.key]|date:'yyyy/MM/dd HH:mm:ss'}}</span>
            <span *ngIf="h.type=='filename'" [title]="rowData[h.key]"><a tabIndex=1 class="cus-clickable" (click)="store.downloadFile(rowData)">{{rowData[h.key]}}</a></span>
            <span *ngIf="h.type=='delete'">
              <!-- <a tabIndex=1 class="cus-clickable" (click)="store.deleteFile(rowData)">Delete</a> -->
              <button pButton label="Delete" title="Delete" [disabled]="store.uploadModalConfig.readonly" icon="pi pi-trash" (click)="store.attachmentDeleteOnClick(rowData)"></button>
            </span>
            <span *ngIf="!h.type" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
          </td>
        </tr>
      </ng-template>
      
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length>2?6:2" style="text-align: center">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-outlined" (click)="store.hideUploadModal()"></button>
    <!-- <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="store.assignActionBy()"></button> -->
  </ng-template>
</p-dialog>
<!-- <app-serial-cst [store]="store"></app-serial-cst> -->
<app-compare-serial [store]="store.compareSerialModalConfig"></app-compare-serial>
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-blockUI [blocked]="store.tableData.loadingSwitch"></p-blockUI>
