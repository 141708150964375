<p-panel [header]="headerTilte" class="p-col-12 p-p-0 p-mb-2 p-d-block" [toggleable]="store.toggleable ? false : true">
  
    <div class="p-fluid p-grid">
      <ng-container *ngFor="let ipt of store.ipts; index as i">
        <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-cst>
        <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (click)="store.handler?store.handler.click(ipt):null" (change)="store.change?store.change(i,$event,ipt):null"></app-select-cst>
        <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-treeSelect-cst>
        <app-textarea-cst [class]="ipt.class" *ngIf="ipt.type==4" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-textarea-cst>
        <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null" (close)="store.close?store.close():null"></app-daterange-cst>
        <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-datetime-cst>
        <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-multiple-cst>
        <app-inputnumber-cst [class]="ipt.class" *ngIf="ipt.type==11" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-inputnumber-cst>
        <app-switch-cst [class]="ipt.class" *ngIf="ipt.type==18" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-switch-cst>
        <app-select-group [class]="ipt.class" *ngIf="ipt.type==20" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-group>
        <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-checkboxt-cst>
        <div *ngIf="ipt.subset" [class]="ipt.subsetClass"></div>
      </ng-container>
      <div [class]="store.btnsclass">
        <ng-container *ngFor="let btn of store.btns">
          <button *ngIf="btn.show" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width:auto;height: 35px;" [disabled]="btn.disabled"></button>
        </ng-container>
      </div>
    </div>
    <div class="p-mb-1" style="margin-top: -2rem">
      <span><span style="color: red">* </span>Required</span>
    </div>
</p-panel>
