<p-panel [header]="headerTilte" class="p-col-12 p-p-0" [toggleable]="store.toggleable ? false : true">
    <div class="p-fluid p-grid">
      <ng-container *ngFor="let ipt of store.ipts; index as i">
        <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-cst>
        <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1&&ipt.show!==false" [ipt]="ipt" (click)="store.handler?store.handler.click(ipt):null" (change)="store.change?store.change(i,$event,ipt):null"></app-select-cst>
        <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-treeSelect-cst>
        <app-textarea-cst [class]="ipt.class" *ngIf="ipt.type==4&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-textarea-cst>
        <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null" (close)="store.close?store.close():null"></app-daterange-cst>
        <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-datetime-cst>
        <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-multiple-cst>
        <app-inputnumber-cst [class]="ipt.class" *ngIf="ipt.type==11&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-inputnumber-cst>
        <app-switch-cst [class]="ipt.class" *ngIf="ipt.type==18&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-switch-cst>
        <app-select-group [class]="ipt.class" *ngIf="ipt.type==20&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-select-group>
        <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-checkboxt-cst>
        <app-radio-cst [class]="ipt.class" *ngIf="ipt.type==22&&ipt.show!==false" [ipt]="ipt" [store]="store" (change)="store.change?store.change(i,$event,ipt):null"></app-radio-cst>
        <app-input-scanner-cst [class]="ipt.class" *ngIf="ipt.type==INPUT_TYPE.INPUTSCAN&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-input-scanner-cst>
        <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21&&ipt.show!==false" [ipt]="ipt" (change)="store.change?store.change(i,$event,ipt):null"></app-multipleTreeSelect-cst>
        <div [class]="ipt.class" *ngIf="ipt.type==27&&ipt.show!==false"></div>
      </ng-container>
      <div [class]="store.btnsclass">
        <ng-container *ngFor="let btn of store.btns">
          <button *ngIf="btn.show&&btn.type!='wrapper'" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width:auto;height: 35px;" [disabled]="btn.disabled || disableBtn"></button>
          <div *ngIf="btn.show&&btn.type=='wrapper'" [class]="btn.class"></div>
        </ng-container>
      </div>
    </div>
</p-panel>
