
<div class="p-d-flex button-radio-group">
  <ng-container *ngFor="let item of options; index as idx">
    <ng-container
      *ngTemplateOutlet="
        btnRadioTemplate;
        context: { value: item, idx: idx, id: itemIds[idx] }
      "
    >
    </ng-container>
  </ng-container>
</div>

<ng-template #btnRadioTemplate let-value="value" let-id="id" let-idx="idx">
  <div class="p-mr-1 p-mb-1">
    <input
      hidden
      type="radio"
      [disabled]="disabled"
      [required]="required"
      [readonly]="readonly"
      [id]="id"
      [name]="groupname"
      [value]="value"
      [(ngModel)]="_value"
      (change)="onChange($event, value)"
    />
    <label class="radio-button p-button" [for]="id" [ngClass]="{'p-button-outlined': _value != value}">
      {{ inputId ? value[inputId] : value }}
    </label>
  </div>
</ng-template>
