<illustration [name]="store.title" ></illustration>
<app-query-pannel [store]="store.queryData"></app-query-pannel>
<div class="p-shadow-1">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
  </div>
    <div class="btns p-mb-2">
      <p-button *ngIf="store.showC" label="Create a new Stock Backend" styleClass="p-button-sm" (click)="store.create()"></p-button>
    </div>
      <p-table
        styleClass="p-datatable-sm p-datatable-striped"
        [scrollable]="false"
        [value]="store.tableData.data"
        [columns]="store.tableData.head"
        (sortFunction)="store.customSort($event)"
        [customSort]="true"
        [loading]="store.tableData.loadingSwitch"
        dataKey="id"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 4rem">
            <col *ngFor="let col of columns" [style]="{'width': col.width || '250px'}">
            <col style="width:160px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th id="h1"></th>
            <th
              *ngFor="let col of columns"
              pSortableColumn="{{col.header}}"
              pResizableColumn
              pReorderableColumn
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon field="{{col.header}}"></p-sortIcon>
            </th>
            <th id="h2"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">
          <tr>
            <td>
              <button type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td *ngFor="let h of columns">
              <ng-container *ngIf="h.key==='itemCode'; else elseTemplate">
                <p-dropdown class="p-dropdown-sm" [options]="store.skuList" [(ngModel)]="product[h.key]" optionLabel="name" optionValue="code"
                placeholder="Select a Item" [showClear]="true" [style]="{'width':'100%'}" (onChange)="store.itemChange($event)"></p-dropdown>
              </ng-container>
              <ng-template #elseTemplate>
                <input pInputText class="p-inputtext-sm" [(ngModel)]="product[h.key]" [style]="{'width':'100%'}"/>
              </ng-template>
            </td>
            <td>
              <div style="display: inline-flex">
                <p-button label="Delete" styleClass="p-button-text p-button-sm" (click)="store.delete(rowIndex)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-product let-rowIndex="rowIndex">
          <tr>
            <td colspan="9">
              <div class="p-p-3">
                <button *ngIf="!product.id" class="p-button-sm p-button-outlined p-mb-2"
                  pButton label="Create a new Serial" (click)="store.createLine(rowIndex)" >
                </button>
                <p-table [value]="product.serial" dataKey="id" editMode="row">
                  <ng-template pTemplate="header">
                    <tr>
                      <th *ngFor="let lineCol of store.lineCols" [id]="lineCol.title">{{lineCol.title}}</th>
                      <th style="width:12rem" *ngIf="!product.id" id="h3"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-order let-editing="editing" let-ri="rowIndex">
                    <tr>
                      <td>
                        <input class="p-inputtext-sm" type="text" pInputText [(ngModel)]="order.serialNumber" [style]="{'width':'100%'}">
                      </td>
                      <td>
                        <button class="p-button-sm p-button-outlined" pButton label="Delete" *ngIf="!editing" (click)="store.onRowDelete(ri, rowIndex)"></button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
    </p-table>
</div>
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
