<p-dialog [(visible)]="visible" [modal]="true" [draggable]="false" [resizable]="false" [styleClass]="'serial-modal'" (onHide)="_onHide()">
  <p-progressBar mode="indeterminate" *ngIf="loadingSwitch"></p-progressBar>
  <ng-template pTemplate="header">
    <h3>{{config.header}}</h3>
  </ng-template>
  <div class="p-fluid p-grid p-mt-1">
    <!-- <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="store.serialModalConfig.itemCode"></app-input-cst> -->
    <!-- <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="store.serialModalConfig.itemDesc"></app-input-cst> -->
    <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="qtyTotalCst"></app-input-cst>
    <app-input-cst [class]="'p-col-12 p-sm-4 p-md-4'" [ipt]="qtyAppendableCst"></app-input-cst>
  </div>
  <div class="serial-input-group p-mt-2 p-mb-2">
    <label class="serial-group-label">1 by 1 input</label>
    <div class="p-mt-1 p-fluid p-grid align-items-center">
      <div class="p-col-9 p-sm-4 p-md-5 p-lg-6 p-xl-8">
        <span class="p-float-label">
          <input id="serial-number-input" type="text" pAutoFocus [autofocus]="true" autocomplete="off" #singleInput [(ngModel)]="config.newSerialValue" pInputText (keydown.enter)="addSerialByInput(config.newSerialValue)">
          <label for="serial-number-input">Serial Number Input</label>
        </span>
      </div>
      <div class="p-col-3 p-sm-2 p-md-2 p-lg-2 p-xl-1 align-self-center">
        <p-button label="Add" styleClass="p-button-sm" (click)="addSerialByInput(config.newSerialValue)"></p-button>
      </div>
      <div class="p-col-12 p-sm-1 p-md-1 align-self-center text-center">or</div>
      <div class="p-col-12 p-sm-5 p-md-4 p-lg-3 p-xl-2 align-self-center">
        <p-button *ngIf="!isScannerEnable" label="Enable Device Camera" styleClass="p-button-sm p-button-outlined" (click)="toggleScanner(true)"></p-button>
        <p-button *ngIf="isScannerEnable" label="Disable Device Camera" styleClass="p-button-sm p-button-outlined" (click)="toggleScanner(false)"></p-button>
      </div>
    </div>
  
    <div *ngIf="isScannerEnable">
      <div class="p-fluid p-grid p-mt-1" *ngIf="availableDevices && availableDevices.length > 1">
        <div class="p-col-12">
          <span class="p-float-label">
            <p-dropdown [inputId]="'camDeviceSelect'" [autoDisplayFirst]='false' [options]="availableDevices"
            [optionLabel]="'label'" [optionValue]="'deviceId'"
            [(ngModel)]="deviceSelected" (onChange)="onDeviceSelectChange($event)">
            </p-dropdown>
            <label [for]="'camDeviceSelect'">Available Devices</label>
          </span>
        </div>
      </div>
      <div class="scanner">
        <zxing-scanner #scanner 
          [device]="deviceCurrent" 
          [formats]="formats"
          [tryHarder]="SCANNERTRYHARDER"
          (permissionResponse)="onHasPermission($event)"
          (deviceChange)="onDeviceChange($event)" 
          (scanSuccess)="onCodeResult($event)"
          (scanFailure)="scanFailure($event)"
          (scanError)="scanError($event)"
          (scanComplete)="scanComplete($event)"
          (camerasFound)="onCamerasFound($event)">
          <ng-template let-file pTemplate="file">
              <div>Custom UI to display a file</div>
          </ng-template>
          <ng-template pTemplate="content" let-files>
              <div>Additional content.</div>
          </ng-template>
        </zxing-scanner>
      </div>
    </div>
  </div>
  <!-- <div class="serial-input-group p-mt-3 p-mb-2">
    <label class="serial-group-label">Range input</label>
    <div class="p-mt-1 p-fluid p-grid p">
      <div class="p-col-12 p-md-2">
        <input type="text" [(ngModel)]="store.serialModalConfig.range.prefix" placeholder="prefix" pInputText>
      </div>
      <div class="p-col p-md-2">
        <input type="text" [(ngModel)]="store.serialModalConfig.range.from" (change)="serialModalRangeChange()" placeholder="starts from" pInputText>
      </div>
      <span class="align-self-center">-</span>
      <div class="p-col p-md-2">
        <input type="text" [(ngModel)]="store.serialModalConfig.range.to" (change)="serialModalRangeChange()" placeholder="ends at" pInputText>
      </div>
      <div class="p-col-12 p-md-2">
        <input type="text" [(ngModel)]="store.serialModalConfig.range.suffix" placeholder="suffix" pInputText>
      </div>
      <div class="p-col">
        <span class="p-float-label">
          <input id="serial-number-range-total" type="text" disabled [(ngModel)]="store.serialModalConfig.range.total" pInputText>
          <label for="serial-number-range-total">Total</label>
        </span>
      </div>
      <div class="align-self-center p-col-6 p-md-2">
        <p-button label="Add" styleClass="p-button-sm" (click)="store.addSerialByRange()"></p-button>
      </div>
    </div>
  </div> -->
  <div class="p-grid p-mt-1">
    <div class="p-xl-12 p-col-12">
      <div>
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="serialFileDropped($event)" [accept]="'.csv,.txt'"
          [dropZoneClassName]="'custom-drop-zone'" [contentClassName]="'custom-drop-content'">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="custom-drop-content-content-selector" (click)="openFileSelector()">
              <div><i class="pi pi-cloud-upload dragndrop-upload-icon"></i></div>
              <div>Drop File or Click to Browse</div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </div>
    <div class="p-xl-12 p-col-12">
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-d-flex">
            <div>
              <span class="p-float-label">
                <input id="serial-number-input" type="text" [(ngModel)]="serialFilter" autocomplete="off" (ngModelChange)="serialFilterChanged($event)" pInputText>
                <label for="serial-number-input">Serial Filter</label>
              </span>
            </div>
            <div class="align-self-center p-ml-2">
              <p-button label="Clear" styleClass="p-button-sm" (click)="clearFilter()"></p-button>
              <p-button label="Delete Serial Number" styleClass="p-button-sm p-ml-2" (click)="confirmBulkDelete()"></p-button>
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <p-table styleClass="p-datatable-sm p-datatable-striped break-word-td"
            [value]="_serialList" [scrollable]="false" [(selection)]="selectedSerialRow"
            [dataKey]="'serial'"
            [resizableColumns]="false" [reorderableColumns]="false">
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col class="table-checkbox-table-col">
                <col style="width:auto">
                <col style="width:auto">
                <col style="width:auto">
                <col style="width:auto">
                <col style="width:auto">
                <col style="width:auto">
                <col style="width:4.75rem;">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" class="table-checkbox-table-cell">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th scope="col">Serial</th>
                <th scope="col">Item Code</th>
                <th scope="col">Serial No. Exists</th>
                <th scope="col">Stock Take Item</th>
                <th scope="col">Exists in Stock Take</th>
                <th scope="col">Stock Condition</th>
                <th scope="col" style="width:6rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
              <tr>
                <td class="table-checkbox-table-cell">
                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                </td>
                <td><span title="{{data.serial}}">{{data.serial}}</span></td>
                <td><span title="{{data._itemCode}}">{{data._itemCode}}</span></td>
                <td><span title="{{data._exist}}">{{data._exist}}</span></td>
                <td><span title="{{data._stockTakeItem}}">{{data._stockTakeItem}}</span></td>
                <td><span title="{{data._existInTake}}">{{data._existInTake}}</span></td>
                <td><span title="{{data._condition}}">{{data._condition}}</span></td>
                <td>
                  <button class="p-button-sm p-button-outlined" pButton label="Delete" (click)="onSerialRowDelete(data, index)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton label="Apply" icon="pi pi-check" (click)="confirm();reset()"></button>
    <button pButton label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel();reset()"></button>
  </ng-template>
</p-dialog>
