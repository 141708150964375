
<illustration name="Stock Balance Enquiry"></illustration>
<p-panel header="FILTER" class="p-col-12 p-p-0" [toggleable]="true">
    <div class="p-fluid p-grid">
      <ng-container *ngFor="let ipt of store.queryPannel.ipts; index as i">
        <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt )"></app-input-cst>
        <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-select-cst>
        <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-treeSelect-cst>
        <app-multipleTreeSelect-cst [class]="ipt.class" *ngIf="ipt.type==21" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-multipleTreeSelect-cst>
        <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-daterange-cst>
        <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-datetime-cst>
        <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-select-multiple-cst>
        <app-checkboxt-cst [class]="ipt.class" *ngIf="ipt.type==15" [ipt]="ipt" (change)="store.queryPannel.change(i,$event,ipt)"></app-checkboxt-cst>
        <div [class]="ipt.class" *ngIf="ipt.type==27&&ipt.show!==false"></div>
      </ng-container>
      <div [class]="store.queryPannel.btnsclass">
        <ng-container *ngFor="let btn of store.queryPannel.btns">
          <button *ngIf="btn.show!==false" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width: auto;height: 35px;" [disabled]="btn.disabled"></button>
        </ng-container>
      </div>
    </div>
</p-panel>
<app-coltable-pannel [store]="store.tablePannel" [searchStore]="store" [isHover]="false"></app-coltable-pannel>

<p-dialog [(visible)]="store.modalConfig.visible" [modal]="true"
  [draggable]="false" [resizable]="false" [styleClass]="'modal'" (onHide)="store.setModalVisible(false)">

  <ng-template pTemplate="header">
    <h3 *ngIf="store.modalConfig.title">{{store.modalConfig.title}}</h3>
  </ng-template>

  <div *ngIf="store.modalConfig.tables[store.modalConfig.type] && store.modalConfig.visible">
    <div class="p-d-flex p-jc-end p-mb-2">
      <span class="panel-title"></span>

      <button
        class="p-button p-mr-2"
        pButton
        [label]="'Export'"
        (click)="store.exportPopupTable()"
      ></button>
      <app-multi-select
        [appendTo]="'body'"
        [options]="store.modalConfig.tables[store.modalConfig.type].columns"
        [(ngModel)]="store.modalConfig.tables[store.modalConfig.type].selectedColumns"
        optionLabel="title"
        selectedItemsLabel="{{store.modalConfig.tables[store.modalConfig.type].selectedColumns.length == store.modalConfig.tables[store.modalConfig.type].columns.length ? 'ALL columns' : store.modalConfig.tables[store.modalConfig.type].selectedColumns.length +' columns displayed'}}"
        selectedItemsLabel="{0} columns displayed"
        [style]="{minWidth: '200px'}"
        [autofocusFilter]="true"
        placeholder="Choose Columns"
      ></app-multi-select>
    </div>
      <p-table p-table-custom class="custom-expand-table"
        styleClass="p-datatable-sm p-datatable-striped"
        [rowHover]="true"
        [scrollable]="true"
        [resizableColumns]="store.modalConfig.tables[store.modalConfig.type].resizable"
        [value]="store.modalConfig.tables[store.modalConfig.type].data"
        [columns]="store.modalConfig.tables[store.modalConfig.type].selectedColumns"
        [loading]="store.modalConfig.tables[store.modalConfig.type].loadingSwitch"
        dataKey="id"
        [sortField]="store.modalConfig.tables[store.modalConfig.type].initSortField"
        [sortOrder]="store.modalConfig.tables[store.modalConfig.type].initSortOrder"
        scrollHeight="500px"
        [customSort]="true"
        (sortFunction)="store.popupTableSortSearch($event.field, $event.order)"
        [frozenColumns]="store.modalConfig.tables[store.modalConfig.type].frozenColumns?store.modalConfig.tables[store.modalConfig.type].frozenColumns:null"
        [frozenWidth]="store.modalConfig.tables[store.modalConfig.type].frozenWidth?store.modalConfig.tables[store.modalConfig.type].frozenWidth:null"
      >

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th
              *ngIf="col.sortable!=false"
              pResizableColumn
              pReorderableColumn
              [pSortableColumn]="col.sortable!=false?col.header:false"
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
              <p-sortIcon [field]="col.header"></p-sortIcon>
            </th>
            <th
              *ngIf="col.sortable==false"
              pResizableColumn
              pReorderableColumn
              [pSortableColumn]="col.sortable!=false?col.header:false"
              [title]="col.fullTitle || col.title"
              [id]="col.title"
            >
              {{col.title}}
            </th>
          </ng-container>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
        <tr style="height: 58px">
          <td *ngFor="let col of columns" class="p-text-truncate">

            <button *ngIf="col.type==='expandControl'"
              type="button" pButton pRipple
              [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

            <div *ngIf="col.type==='action'" style="display: inline-flex">
              <!-- <button *ngIf="data.edit && showU"
                type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                pTooltip="Save" tooltipPosition="top"
                [icon]="'pi pi-save'" (click)="save(data, rowIndex)"></button>

              <button *ngIf="showU && !data.edit && !warehouseIdList[data.channelId] && channelsDisableEdit.indexOf(data.channelCode)==-1"
                type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                pTooltip="Edit" tooltipPosition="top"
                [icon]="'pi pi-pencil'" (click)="editDetail(data)"></button>

              <button *ngIf="data.edit && showU"
                type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                pTooltip="Cancel" tooltipPosition="top"
                [icon]="'pi pi-times'" (click)="cancel(data, rowIndex)"></button>

              <button *ngIf="showU && !data.edit && warehouseIdList[data.channelId] == true && data.lisReservationId == null"
                type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                pTooltip="Reserve LIS" tooltipPosition="top"
                [icon]="'pi pi-calendar'" (click)="reserveLis(data)"></button>

              <button *ngIf="showU && !data.edit && data.expiryDate != null"
                type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
                pTooltip="Extend" tooltipPosition="top"
                [icon]="'pi pi-calendar-plus'" (click)="extendReleaseDate(data)"></button> -->
            </div>
            <input *ngIf="col.type==='txt'" [disabled]="!data.edit" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.header]" [style]="{'width': '100%'}"/>
            <!-- <p-inputNumber *ngIf="col.type==='number'" [min]="col.min" [disabled]="!data.edit" class="p-inputtext-sm"
              (ngModelChange)="onTableValueChange($event, data, col)"
              (onInput)="onTableValueInput($event, data, col)"
              [(ngModel)]="data[col.header]" [inputStyle]="{'width': '100%'}"></p-inputNumber> -->
            <span *ngIf="col.type==='date'" [title]="data[col.header]|date:'yyyy/MM/dd'">{{data[col.header] | date:'yyyy/MM/dd' }}</span>
            <ng-container *ngIf="col.type=='link'">
              <span *ngIf="data[col.isClickableKey]" class="cus-clickable" [title]="data[col.header]" (click)="col.onClick($event,data)">{{data[col.header]}}</span>
              <span *ngIf="!data[col.isClickableKey]" [title]="data[col.header]">{{data[col.header]}}</span>
            </ng-container>
            <span *ngIf="!col.type" [title]="data[col.header]">{{data[col.header]}}</span>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
        <tr></tr>
        <tr class="expand-row">
          <td [attr.colspan]="columns.length">
            <div class="p-d-flex expand-row-wrapper-container">
              <div class="expand-row-wrapper">
                <div class="expand-row-content-wrapper">
                  <div class="expand-row-content">

                    <div class="p-fluid p-grid p-mt-2 p-mb-2">
                      <ng-container *ngFor="let col of store.modalConfig.tables[store.modalConfig.type].selectedColumns">
                        <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                          <span class="p-float-label p-input-icon-right" [ngClass]="{'hidden': columns==store.modalConfig.tables[store.modalConfig.type].frozenColumns}">
                            <input
                              *ngIf="!col.type || col.type == 'txt' || col.type == 'text' || col.type == 'number'"
                              class="p-inputtext-sm"
                              type="text"
                              [attr.id]="col.header"
                              [class]="{'p-input-sm':true}"
                              pInputText [(ngModel)]="data[col.header]"
                              [disabled]='true'
                              [readonly]='true'>
                            <input
                              *ngIf="col.type == 'date'"
                              class="p-inputtext-sm"
                              type="text"
                              [attr.id]="col.header"
                              [class]="{'p-input-sm':true}"
                              pInputText [ngModel]="data[col.header] | date: 'yyyy/MM/dd' "
                              [disabled]='true'
                              [readonly]='true'>
                            <label [for]="col.header" *ngIf="col.title">{{col.title}}</label>
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="12" style=" text-align: center;">No record found.</td>
        </tr>
      </ng-template>
    </p-table>

    <p-paginator #popupPaginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.modalConfig.tables[store.modalConfig.type].totalRecords" (onPageChange)="store.modalConfig.tables[store.modalConfig.type].page($event)"></p-paginator>
  </div>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="store.setModalVisible(false)"></button>
  </ng-template>

</p-dialog>
