<div class="p-shadow-1 wrapper">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <span>
      <!-- 遍历按钮组 -->
      <ng-container *ngFor="let btn of store.btns">
        <button *ngIf="btn.show" pButton class="p-button-sm" [label]="btn.title" (click)="btn.handler.click()"
          [disabled]="(btn.title === 'Edit' || btn.title === 'Delete') && !store.selectedProducts.length" style="margin-right: 10px;top: -2px;">
        </button>
      </ng-container>
    </span>
  </div>
  <div>
    <p-table
      styleClass="p-datatable-sm p-datatable-striped custom-table"
      [columns]="store.head"
      [frozenColumns]="store.frozenColumns ? store.frozenColumns : null"
      [value]="store.display_data"
      [(selection)]="store.selectedProducts"
      [rowHover]="isHover"
      [scrollable]="store.frozenWidth?true:false"
      [rows]="20"
      [paginator]="false"
      [rowsPerPageOptions]="[20,30,50]"
      [loading]="store.loadingSwitch"
      [resizableColumns]="true"
      [columnResizeMode]="'expand'"
      [reorderableColumns]="true"
      (sortFunction)="searchStore.sortSearchUrl === '/api/notice/search' ? null : searchStore.sortPN($event)"
      [customSort]="true"
      (onPage)="searchStore.page($event)"
      [totalRecords]="searchStore.totalRecords"
      dataKey="id"
      [frozenWidth]="store.frozenWidth || null"
      [sortField]="store.sortField || null"
      [sortOrder]="store.sortOrder || 1"
      scrollHeight="500px"
    >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup *ngIf="scrollable">
        <ng-container *ngIf="!hasAttr">
          <col *ngIf="store.showCheckboxFlag" style="width: 3rem">
          <col *ngIf="!store.showCheckboxFlag" style="width: 4rem">
        </ng-container>
        <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
        <col *ngIf="rowEditable" style="width:250px">
      </colgroup>

      <!-- <colgroup>
        <ng-container *ngFor="let col of columns; index as i">
          <col *ngIf="col.row" [attr.rowspan]="col.row" [style]="{width: col.width || '250px'}">
          <col *ngIf="col.parent && col.parent.colspan" [attr.colspan]="col.parent.colspan" [style]="{width: col.width || '250px'}">
        </ng-container>
      </colgroup>
      <colgroup>
        <ng-container *ngFor="let col of columns; index as i">
          <col *ngIf="col.parent" [style]="{width: col.width || '250px'}">
        </ng-container>
      </colgroup> -->


    </ng-template>
      <ng-template pTemplate="frozenheader" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="col.row" [attr.rowspan]="col.row" [style.height]='col.h'
              [title]="col.summary?col.summary:''"
              pResizableColumn [pResizableColumnDisabled]="true" [pSortableColumn]="col.key" [id]="col.title">
              {{col.title}}<p-sortIcon [field]="col.key" *ngIf="col.sort && !col.parent"></p-sortIcon>
            </th>
            <th *ngIf="col.parent && col.parent.colspan" 
              [title]="col.parent.summary?col.parent.summary:''"
              [attr.colspan]="col.parent.colspan" [style.height]='col.h' [id]="col.parent.title">
              {{col.parent.title}}
            </th>
            <th *ngIf="col.parent && !col.parent.colspan" style="display: none;">
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="col.row" style="display: none;">
            </th>
            <th *ngIf="col.parent" [style.height]='col.h' [pSortableColumn]="col.key"
              [title]="col.summary?col.summary:''"
              [id]="col.title">
              {{col.title}}<p-sortIcon [field]="col.key" *ngIf="col.sort"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="col.row" [attr.rowspan]="col.row" [style.height]='col.h'
              [title]="col.summary?col.summary:''"
              pResizableColumn [pSortableColumn]="col.key" [id]="col.title">
              {{col.title}}<p-sortIcon [field]="col.key" *ngIf="col.sort && !col.parent"></p-sortIcon>
            </th>
            <th *ngIf="col.parent && col.parent.colspan" 
              [title]="col.parent.summary?col.parent.summary:''"
              [attr.colspan]="col.parent.colspan" [style.height]='col.h' [id]="col.parent.title">
              {{col.parent.title}}
            </th>
            <th *ngIf="col.parent && !col.parent.colspan" style="display: none;">
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; index as i">
            <th *ngIf="col.row" style="display: none;">
            </th>
            <th *ngIf="col.parent" [style.height]='col.h' [pSortableColumn]="col.key"
              [title]="col.summary?col.summary:''"
              [id]="col.title">
              {{col.title}}<p-sortIcon [field]="col.key" *ngIf="col.sort"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns"  let-expanded="expanded">
        <tr>
          <td [style]="{'text-align': h['text-align']}"sa
            (click)="searchStore.openDet(rowData)"
            *ngFor="let h of columns"
            class="ui-resizable-column p-text-truncate"
          >
            <span [ngSwitch]="h.key">
              <span *ngSwitchCase="'total'" [title]="(0 + rowData['total'] || 0)">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData['total'] || 0 ), column: h, rowData: rowData}"></ng-container>
              </span>
              <span *ngSwitchCase="'reservedTotal'" [title]="(rowData['sourceSystem'] === 'LIS' ? 'N/A' : 0 + rowData[h.key])">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData['sourceSystem'] === 'LIS' ? 'N/A' : 0 + rowData[h.key]), column: h, rowData: rowData}"></ng-container>
              </span>
              <span *ngSwitchCase="'uom'" [title]="rowData[h.key]?rowData[h.key]:'EA'">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData[h.key] ? rowData[h.key] : 'EA'), column: h, rowData: rowData}"></ng-container>
              </span>
              <span *ngSwitchCase="'skuDesc'" [title]="rowData[h.key]?rowData[h.key]:''">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData[h.key] ? rowData[h.key] : ''), column: h, rowData: rowData}"></ng-container>
              </span>
              <span *ngSwitchCase="'fg'" [title]="rowData[h.key]?rowData[h.key]:0">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData[h.key]), column: h, rowData: rowData}"></ng-container>
              </span>
              <span *ngSwitchDefault [title]="(rowData[h.key]?rowData[h.key]: (h.key ==='skuDesc'||h.key==='uom' ? '': 0))">
                <ng-container *ngTemplateOutlet="cellContent; context: {$implicit: (rowData[h.key] ? rowData[h.key] : (rowData['sourceSystem'] === 'LIS' ? 'N/A' : 0)), column: h, rowData: rowData}"></ng-container>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length" style=" text-align: center;" [ngClass]="{hidden: columns!==store.frozenColumns}">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator #paginator styleClass="p-paginator-sm" [rowsPerPageOptions]="[20,30,50]" [rows]="20" [totalRecords]="searchStore.totalRecords" (onPageChange)="searchStore.page($event)"></p-paginator>
  </div>
</div>


<ng-template #cellContent let-rowData="rowData" let-column="column" let-value>
  <ng-container *ngIf="!column.onClick">
    {{value}}
  </ng-container>
  <a *ngIf="column.onClick && value !== 0" class="clickable" (click)="column.onClick(rowData)">
    {{value}}
  </a>
  <span *ngIf="column.onClick && value === 0">{{value}}</span>
</ng-template>
