<div class="content-body">
  <div class="inner-content">
    <div class="header"><i class="pi pi-key p-mr-2" style="font-size: 2rem; color: royalblue;"></i>Change password</div>
    <div  class="p-mb-4">
      <span class="p-float-label p-input-icon-left p-input-icon-right">
        <input id="inputtext-password" [type]="visible?'text':'password'" pInputText [(ngModel)]="oldPassword" >
        <label for="inputtext-password">OldPassword</label>
      </span>
    </div> 
    
    <div  class="p-mb-4">
      <span class="p-float-label p-input-icon-left p-input-icon-right">
        <input id="inputtext-password" [type]="visible?'text':'password'" pInputText [(ngModel)]="newPassword" >
        <label for="inputtext-password">Password</label>
      </span>
    </div> 
    
  <div class="p-mb-4">
      <span class="p-float-label p-input-icon-left p-input-icon-right">
        <input type="text" [type]="visible?'text':'password'" id="inputtext-confrimpassword" pInputText [(ngModel)]="confrimpassword" >
        <label for="inputtext-confrimpassword">Confirm Password</label>
      </span>
    </div>
     <button pButton label="confrim" iconPos="left" (click)="confirm()" ></button>
  </div>
  
</div>
