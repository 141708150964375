<span class="p-float-label p-input-icon-right" (click)="openSelectPanel()">
  <i *ngIf="ipt.value&&ipt.value.length>0" class="pi pi-times" (click)="clearSelectData()"></i>
  <i class="pi pi-chevron-down"></i>
  <input class="p-inputtext-sm" type="text" [attr.id]="ipt.name" [class]="{'p-input-sm':true, 'ng-invalid':ipt.error,'ng-dirty':ipt.error}"
  pInputText [(ngModel)]="ipt.showValue" [disabled]="ipt.disabled" autocomplete='off' readonly='readonly' style="cursor: pointer;"
  (keydown)="onKeydown($event)">
  <label [for]="ipt.name">{{ipt.title}}</label>
</span>
<div class="tree-panel" [ngClass]="{'tree-level':true}" *ngIf="isOpen" #treeWrapper
  [@overlayAnimation]="{
    value: 'visible',
    params: {
      showTransitionParams: showTransitionOptions,
      hideTransitionParams: hideTransitionOptions
    }
  }"
  (@overlayAnimation.start)="onOverlayAnimationStart($event)"
  (@overlayAnimation.done)="onOverlayAnimationDone($event)"
  (keydown)="onKeydown($event)"
>
  <p-tree *ngIf="isOpen" [value]="ipt.options" selectionMode="checkbox" [(selection)]="ipt.value" scrollHeight="230px"
    (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [filter]="true"
    [filterMode]="'strict'"
    [class]="{'p-tree-sm':true, 'ng-invalid':ipt.error,'ng-dirty':ipt.error}" styleClass="tree-panel-content p-dropdown-panel">
  </p-tree>
</div>

