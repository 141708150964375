<p-menubar [model]="stockItems" styleClass="menu">
  <ng-template pTemplate="start">
    <img src="../../../assets/Stock%20management%20logo.svg" height="40" class="p-ml-sm-2 p-mr-sm-2 p-ml-md-6 p-mr-md-4" alt='stock management logo'>
  </ng-template>
  <ng-template pTemplate="end">
    <span class="p-mr-3 version-item" *ngIf="commonStore.version">{{commonStore.version}}</span>
    <span class="p-mr-3 focus-item"  (click)="redirectTo('notice')"><i class="pi pi-envelope p-mr-2" pBadge severity="danger"></i></span>
    <span class="p-mr-3 focus-item" *ngIf="showOption" (click)="otherMenu.toggle($event)"><i class="pi pi-cog p-mr-2"></i></span>
    <p-tieredMenu #otherMenu [model]="otherItems" [popup]="true"></p-tieredMenu>
    <span class="p-mr-sm-2 p-mr-md-4 focus-item" (click)="menu.toggle($event)"><i class="pi pi-user p-mr-2"></i></span>
    <p-tieredMenu #menu [model]="rightItems" [popup]="true"></p-tieredMenu>
  </ng-template>
</p-menubar>
<div class="content p-col-12 p-sm-12">
  <router-outlet></router-outlet>
  <p-toast [style]="{width:'500px'}">
    <ng-template let-message pTemplate="message">
      <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')" [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
        'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}" style="order:-1"></span>
      <div class="p-toast-message-text" style="order:-1" *ngIf="!message.data || !message.data.isInnerHTML">
        <div class="p-toast-summary">{{message.summary}}</div>
        <div class="p-toast-detail">{{message.detail}}</div>
      </div>
      <div class="p-toast-message-text" style="order:-1" *ngIf="message.data && message.data.isInnerHTML">
        <div class="p-toast-summary" [innerHTML]="message.summary"></div>
        <div class="p-toast-detail" [innerHTML]="message.detail"></div>
      </div>
    </ng-template>
  </p-toast>
  <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
</div>
