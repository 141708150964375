<illustration [name]="store.breadcrumb "></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel headerTilte="Demand Cycle" [store]="store.parentData"></app-query-pannel>
<!-- <illustration [name]="store.subBreadcrumb "></illustration> -->
<app-add-pannel [headerTilte]="store.subBreadcrumb" class="add-pannel" [store]="store.queryData" [disableBtn]="store.loading"></app-add-pannel>
<!-- table module -->
<div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-grid">
    <!-- <span class="panel-title"></span> -->
    <div class="p-col-12 p-sm-12 cus-md-grow cus-lg-grow cus-xl-grow">
      <!-- <div class="p-grid"> -->
      <div class="p-d-flex p-grid p-fluid" *ngIf="store.tableFilterPanel">
        <ng-container *ngFor="let ipt of store.tableFilterPanel.ipts; index as i">
          <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1&&ipt.show!==false" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-cst>
          <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10&&ipt.show!==false" [ipt]="ipt" (change)="ipt.change?ipt.change(i,$event,ipt):null"></app-select-multiple-cst>
        </ng-container>
        <div [ngClass]="store.tableFilterPanel.btnsclass">
          <ng-container *ngFor="let btn of store.tableFilterPanel.btns">
            <button *ngIf="btn.show&&btn.type!='wrapper'" pButton type="button" [class]="btn.class" [label]="btn.title" (click)="btn.handler.click()" style="width:auto;height: 35px;" [disabled]="btn.disabled"></button>
          </ng-container>
        </div>
      </div>
    </div>
    <p-multiSelect
      [options]="store.tableData.columns"
      [(ngModel)]="store.tableData.selectedColumns"
      (ngModelChange)="store.tableSelectedColumnsChange($event)"
      optionLabel="title"
      selectedItemsLabel="{{store.tableData.selectedColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.selectedColumns.length +' columns displayed'}}"
      selectedItemsLabel="{0} columns displayed"
      [style]="{minWidth: '200px'}"
      class="p-col-fixed p-ml-auto"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <p-table #ptable
  styleClass="p-datatable-sm p-datatable-striped"
  [rowHover]="true"
  [scrollable]="true"
  [(selection)]="store.tableData.selectedProducts"
  [resizableColumns]="store.tableData.editShow"
  [reorderableColumns]="store.tableData.editShow"
  [value]="store.tableData.data"
  [columns]="store.tableData.selectedColumns"
  [loading]="store.tableData.loadingSwitch"
  (sortFunction)="store.sortFunction($event)"
  [customSort]="true"
  dataKey="id"
  [sortField]="store.tableData.initSortField"
  [sortOrder]="store.tableData.initSortOrder"
  [frozenColumns]="store.tableData.frozenColumns"
  [frozenWidth]="store.tableData.frozenWidth"
  scrollHeight="500px"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container 
          *ngFor="let col of columns">
          <th
            *ngIf="!col.disableSort"
            pResizableColumn
            pReorderableColumn
            [pSortableColumn]="col.field"
            [title]="col.fullTitle || col.title"
            [id]="col.title"
          >
            {{col.title}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th
            *ngIf="col.disableSort&&col.type!=='checkbox'"
            [title]="col.fullTitle || col.title"
            [id]="col.title"
          >
            {{col.title}}
          </th>
          <th
            *ngIf="col.field=='isReserveTarget'"
            [title]="col.fullTitle || col.title"
            [id]="col.title"
            class="p-text-center"
          >
            <p-checkbox 
              [(ngModel)]="store.isReserveSelectedAll" 
              [binary]="true"
              (onChange)="store.tableCheckboxChangeSelectAll($event, col)"
            ></p-checkbox>
          </th>
        </ng-container>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex">
      <tr style="height: 53px">
        <td *ngFor="let col of columns"  [ngClass]="{'qtyAllocateColor':col.title === 'Allocate Qty'}" [class]="{'p-text-truncate':col.field}">
          <div *ngIf="store.showU && col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="store.showU&&data._showCancelLisReserveBtn" [disabled]="!data.sourceSysReservationId || data.interfaceDate" pButton label="Cancel LIS Rsv" (click)="store.cancelLisReservation(data)"></button>
          </div>
          <div *ngIf="col.type==='checkbox'" class="p-text-center">
            <p-checkbox [ngModel]="data[col.field]" [binary]="true" [disabled]="data[col.disabledKey]" (onChange)="store.tableCheckboxChange($event, col, data)"></p-checkbox>
          </div>
          <input *ngIf="col.type==='input'" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
          <p-inputNumber *ngIf="col.type==='number'"class="p-inputtext-sm" [(ngModel)]="data[col.field]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
          <span *ngIf="col.type==='date'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span>
          <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</span>
          <span *ngIf="col.type==='name'" [title]="data.updateName">{{data.updateName }}</span>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>

        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td colspan="12" style=" text-align: center;">{{frozen.length === 1 ? '' : 'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<!-- |store.isNop:{{store.isNop}}| -->
<!-- table module -->
<div class="p-shadow-1" *ngIf="store.isNop && store.nopTableData" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
  <div class="p-grid p-mb-3">
    <!-- <span class="panel-title"></span> -->
    <div class="p-col"></div>
    <p-multiSelect
      [options]="store.nopTableData.columns"
      [(ngModel)]="store.nopTableData.selectedColumns"
      optionLabel="title"
      selectedItemsLabel="{{store.nopTableData.selectedColumns.length == store.nopTableData.columns.length ? 'ALL columns' : store.nopTableData.selectedColumns.length +' columns displayed'}}"
      selectedItemsLabel="{0} columns displayed"
      [style]="{minWidth: '200px'}"
      placeholder="Choose Columns"
    ></p-multiSelect>
  </div>
  <p-table #ptable
  styleClass="p-datatable-sm p-datatable-striped"
  [rowHover]="true"
  [scrollable]="true"
  [(selection)]="store.nopTableData.selectedProducts"
  [resizableColumns]="store.nopTableData.editShow"
  [reorderableColumns]="store.nopTableData.editShow"
  [value]="store.nopTableData.data"
  [columns]="store.nopTableData.selectedColumns"
  [loading]="store.nopTableData.loadingSwitch"
  dataKey="id"
  [sortField]="store.nopTableData.initSortField"
  [sortOrder]="store.nopTableData.initSortOrder"
  [frozenColumns]="store.nopTableData.frozenColumns"
  [frozenWidth]="store.nopTableData.frozenWidth"
  [tableStyleClass]="'cus-min-w-50rem'"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container 
          *ngFor="let col of columns">
          <th
            *ngIf="col.sortable!==false"
            pResizableColumn
            pReorderableColumn
            [pSortableColumn]="col.field"
            [title]="col.fullTitle || col.title"
            (click)="store.nopSortSearch(col.field)"
            [id]="col.title"
          >
            {{col.title}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th
            *ngIf="col.sortable===false"
            [title]="col.fullTitle || col.title"
            [id]="col.title"
          >
            {{col.title}}
          </th>
        </ng-container>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr style="height: 53px">
        <td *ngFor="let col of columns" [class]="{'p-text-truncate':col.field}">
          <button *ngIf="col.type==='expand'" type="button" pButton pRipple [pRowToggler]="data" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          <div *ngIf="store.showU && col.type==='action'" style="display: inline-flex">
            <button class="p-button-sm p-mr-1" *ngIf="store.showU" [disabled]="!data.sourceSysReservationId || store.status.toUpperCase() !=='RESERVED'" pButton label="Cancel LIS Reservation" (click)="store.cancelLisReservation(data)"></button>
          </div>
          <input *ngIf="col.type==='input'" pInputText class="p-inputtext-sm" [(ngModel)]="data[col.field]" [style]="{'width': '100%'}"/>
          <p-inputNumber *ngIf="col.type==='number'"class="p-inputtext-sm" [(ngModel)]="data[col.field]" [inputStyle]="{'width': '100%'}"></p-inputNumber>
          <span *ngIf="col.type==='date'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span>
          <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</span>
          <span *ngIf="col.type==='name'" [title]="data.updateName">{{data.updateName }}</span>
          <span *ngIf="!col.type" [title]="data[col.field]">{{data[col.field]}}</span>

        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-data let-product let-rowIndex="rowIndex">
      <tr class="p-d-none" tabindex="-1"></tr>
      <tr class="expand-row">
        <td [attr.colspan]="store.nopTableData.selectedColumns.length">
          <div class="p-p-3">
            <p-table
              [value]="data[store.nopOrderExpandCol.listKey]"
              [resizableColumns]="true"
              [columns]="store.nopOrderExpandCol.columns"
              [reorderableColumns]="true"
            >
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let lineCol of columns"
                    pSortableColumn="{{ lineCol.field }}"
                    pResizableColumn
                    pReorderableColumn
                    [id]="lineCol.title"
                  >
                    {{ lineCol.title }}
                    <p-sortIcon [field]="lineCol.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <!-- <span *ngIf="col.type==='date'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span> -->
                    <!-- <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd HH:mm:ss' }}</span> -->
                    <!-- <span *ngIf="col.type==='name'" [title]="data.updateName">{{data.updateName }}</span> -->
                    <span *ngIf="!col.type" [title]="row[col.field]">{{row[col.field]}}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-frozen>
      <tr><td [attr.colspan]="store.nopTableData.selectedColumns.length" style=" text-align: center;">{{frozen.length === 1 ? '' : 'No record found.'}}</td></tr>
    </ng-template>
  </p-table>
  <p-paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.nopTableData.totalRecords||0" (onPageChange)="store.nopPage($event)"></p-paginator>
</div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<!-- <div *ngIf="store.loading" class="mask" (click)="store.stopEvent($event)"></div> -->

<p-blockUI [blocked]="store.loading"></p-blockUI>