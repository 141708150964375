<p-dialog
  [visible]="visable"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'cus-modal cus-modal-lg'"
  (onHide)="onHide($event)"
  [closable]="false">
  <ng-template pTemplate="header">
    <h3 class="modal-header">{{title}}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <textarea class="w-100 result-textarea" disabled>{{jsResult}}</textarea>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Copy" icon="pi pi-copy" class="p-button-outlined" (click)="clickToCopy()"></button>
    <button pButton pRipple label="Back" icon="pi pi-times" class="p-button-outlined" (click)="onHide($event)"></button>
  </ng-template>
</p-dialog>