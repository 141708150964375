<span class="p-float-label custom-inputgroup">
  <span class="cus-required-label" *ngIf="ipt.required">*
  </span>
  <input type="text" #inputtext pInputText [(ngModel)]="ipt.value" (input)="onInput(inputtext.value)" [disabled]="ipt.disabled"
  [class]="{'p-inputtext-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}">
  <label for="inputtext">{{ipt.title}}</label>
  <button type="button" pButton pRipple icon="pi pi-video" styleClass="p-button-warn" (click)="setModalVisible(true)"></button>
</span>

<p-dialog [(visible)]="modalConfig.visible" [modal]="true"
  [draggable]="false" [resizable]="false" [styleClass]="'modal'" (onHide)="setModalVisible(false)">
  <ng-template pTemplate="header">
    <h3 *ngIf="ipt.title">{{ipt.title}}</h3>
  </ng-template>
  <div *ngIf="isScannerEnable">
    <div class="p-fluid p-grid p-mt-1" *ngIf="availableDevices && availableDevices.length > 1">
      <div class="p-col-12">
        <span class="p-float-label">
          <p-dropdown [inputId]="'camDeviceSelect'" [options]="availableDevices"
          [optionLabel]="'label'" [optionValue]="'deviceId'"
          [(ngModel)]="deviceSelected" (onChange)="onDeviceSelectChange($event)">
          </p-dropdown>
          <label [for]="'camDeviceSelect'">Available Devices</label>
        </span>
      </div>
    </div>
    <div class="scanner">
      <zxing-scanner #scanner [device]="deviceCurrent"
        [formats]="formats" [tryHarder]="SCANNERTRYHARDER"
        (permissionResponse)="onHasPermission($event)"
        (deviceChange)="onDeviceChange($event)" 
        (scanSuccess)="onCodeResult($event)"
        (scanFailure)="scanFailure($event)"
        (scanError)="scanError($event)"
        (scanComplete)="scanComplete($event)"
        (camerasFound)="onCamerasFound($event)"
      >
        <ng-template let-file pTemplate="file">
            <div>Custom UI to display a file</div>
        </ng-template>
        <ng-template pTemplate="content" let-files>
            <div>Additional content.</div>
        </ng-template>
      </zxing-scanner>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-outlined" (click)="setModalVisible(false)"></button>
  </ng-template>
</p-dialog>
