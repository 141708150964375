<illustration [name]="queryTitle" path="Stock In & Out"></illustration>
<app-query-pannel class="query-panel" [store]="queryPannel"></app-query-pannel>
<div class="p-shadow-1">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span class="panel-title"></span>
    <!-- <div class="btns p-mb-2">
      <button *ngIf="showD" pButton class="p-button-outlined p-button-sm" label="Delete" (click)="delete()" [disabled]="!selectedRows.length"></button>
    </div> -->
    <p-multiSelect [options]="tableData.columns" [(ngModel)]="selectedColumns" optionLabel="field" selectedItemsLabel="{{selectedColumns.length == tableData.columns.length ? 'ALL columns' : selectedColumns.length +' columns displayed'}}"
    [style]="{minWidth: '200px'}" placeholder="Choose Columns" ></p-multiSelect>
  </div>
  <p-table dataKey="id"
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="false"
    [responsive]="true"
    [scrollable]="true"
    [paginator]="false"
    [rows]="20"
    [showCurrentPageReport]="false"
    [rowsPerPageOptions]="[20, 30, 50]"
    [(selection)]="selectedRows"
    [resizableColumns]="true"
    [value]="tableData.data"
    [columns]="tableData.showColumns"
    [sortField]="tableData.sortField.field"
    [sortOrder]="tableData.sortField.order"
    [loading]="tableData.loadingSwitch"
    [customSort]="true"
    [frozenColumns]="tableData.frozenColumns"
    [frozenWidth]="tableData.frozenWidth"
    scrollHeight="500px"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="!col.allowSort" pResizableColumn [id]="col.title">
          {{col.title}}
        </th>
        <th *ngIf="col.allowSort" pSortableColumn="{{col.field}}" pResizableColumn pReorderableColumn [id]="col.title" (click)="sortSearch(col.field)">
          {{col.title}}
          <p-sortIcon field="{{col.field}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td *ngFor="let col of columns" class="p-text-truncate" style="height: 57px;">
        <div *ngIf="col.type==='checkbox'" style="text-align: center">
          <p-tableCheckbox [value]="rowData" ></p-tableCheckbox>
        </div>
        <div *ngIf="col.type==='action' && showC">
          <button *ngIf="!rowData.edit" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
            pTooltip="Edit" tooltipPosition="top" [icon]="'pi pi-pencil'" (click)="editDetail(rowData)"></button>
          <button *ngIf="rowData.edit" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
            pTooltip="Save" tooltipPosition="top" [icon]="'pi pi-save'" (click)="save(rowData)"></button>
          <button *ngIf="rowData.edit" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
            pTooltip="Cancel" tooltipPosition="top" [icon]="'pi pi-times'" (click)="cancel(rowData)"></button>
        </div>
        <span *ngIf="!col.type" [title]="rowData[col.field]">
          {{rowData[col.field]}}
        </span>
        <div *ngIf="col.type==='trigger'" style="padding-left: 5px;">
          <!-- <p-checkbox [readonly]="rowData.id" [value]='rowData[col.field]' [(ngModel)]="rowData[col.field+'cb']" (ngModelChange)="exchange($event)"></p-checkbox> -->
          <p-checkbox [readonly]="true" [disabled]="!rowData[col.field]" [binary]="true" [(ngModel)]="rowData[col.field]"></p-checkbox>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length" style=" text-align: center;">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="tableData.totalRecords" (onPageChange)="page($event)"></p-paginator>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button class="p-button-outlined" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </ng-template>
</p-confirmDialog>
<p-blockUI [blocked]="tableData.loadingSwitch"></p-blockUI>
