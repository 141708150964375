<div class="spreadsheet-table-overlay hide-toolbar-decoration hide-toolbar-colors hide-toolbar-align spreadsheet-cus-hide"
[ngClass]="{'hide-import': !allowImport}">
  <div
    #sheet
    class="hide-cell-lock-icon spreadsheet-base"
    [ngClass]="{ 'p-d-none': !dataInited, 'loading': loading||loadingSwitch }"
  ></div>
  <div class="cus-loading-overlay" *ngIf="loading||loadingSwitch">
      <i class="loading-icon pi pi-spin pi-spinner"></i>
  </div>
</div>

<p-overlayPanel #filter [style]="{'padding':'.5rem','border':'1px solid #ccc'}">
  <ng-template pTemplate>
    <div class="p-d-flex p-mb-2 sorter-input">
      <span class="p-input-icon-right" >
        <i class="pi pi-search"></i>
        <input type="text" pInputText [(ngModel)]="sorterFilter"/>
      </span>
      <i class="pi p-ml-2" [ngClass]="{'pi-sort-alt': sorterOrder == 0, 'pi-sort-amount-up-alt': sorterOrder == 1, 'pi-sort-amount-down': sorterOrder == -1}" (click)="sorterOrderOnClick()"></i>
      <i class="pi p-ml-2 pi-trash" (click)="resetSorter()"></i>
    </div>
    <div class="p-d-flex sorter-button-wrapper">
      <button class="p-mr-1" pButton label="OK" (click)="applySortFilter()"></button>
    </div>
  </ng-template>
</p-overlayPanel>
