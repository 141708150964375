<illustration [name]="store.breadcrumb"></illustration>
<app-query-pannel [store]="store.queryData"></app-query-pannel>
  <div class="p-shadow-1" style="background-color: white;margin:20px 0;border-radius:5px;padding:25px">
    <div class="p-d-flex p-jc-between p-mb-2">
      <span class="panel-title"></span>
      <p-multiSelect
          [options]="store.tableData.columns"
          [(ngModel)]="store.tableData.selectedColumns"
          optionLabel="title"
          selectedItemsLabel="{{store.tableData.selectedColumns.length == store.tableData.columns.length ? 'ALL columns' : store.tableData.selectedColumns.length +' columns displayed'}}"
          selectedItemsLabel="{0} columns displayed"
          [style]="{minWidth: '200px'}"
          placeholder="Choose Columns"
        ></p-multiSelect> 
        
    </div>
    <div class="btns p-mb-2" style="display: inline-block;margin-right: 5px">
     
    </div>
    <p-table #ptable p-table-custom class="custom-expand-table"
      styleClass="p-datatable-sm p-datatable-striped"
      [rowHover]="true"
      [scrollable]="true"
      [value]="store.tableData.data"
      [columns]="store.tableData.selectedColumns"
      [loading]="store.tableData.loadingSwitch"
      dataKey="id"
      [sortField]="store.tableData.sortField.field"
      [sortOrder]="store.tableData.sortField.order"
      scrollHeight="500px"
      [(selection)] = "store.selectedRow"
    >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th
            *ngIf="col.sortable!=false"
            pResizableColumn
            pReorderableColumn
            [pSortableColumn]="col.sortable!=false?col.field:false"
            [title]="col.fullTitle || col.title"
            (click)="store.sortSearch(col.field)"
            [id]="col.title"
          >
            {{col.title}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th *ngIf="col.sortable==false" [id]="col.title" >
            {{col.title}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-index let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr style="height: 58px">
        <td *ngFor="let col of columns" class="p-text-truncate">
          <span *ngIf="col.type==='date'" [title]="data[col.field]|date:'yyyy/MM/dd'">{{data[col.field] | date:'yyyy/MM/dd' }}</span>
          <span *ngIf="col.type==='datetime'" [title]="data[col.field]|date:'yyyy/MM/dd HH:mm'">{{data[col.field] | date:'yyyy/MM/dd HH:mm' }}</span>
          <span class="clickable" *ngIf="col.field==='request_body'" (click)="store.jsonShowDetail(data[col.field])">{{data[col.field]}}</span>
          <span class="clickable" *ngIf="col.field==='response_json'" (click)="store.jsonShowDetail(data[col.field])">{{data[col.field]}}</span>
          <span class="clickable" *ngIf="col.field==='request_url'" (click)="store.jsonShowDetail(data[col.field])">{{data[col.field]}}</span>
          <!-- <span *ngIf="col.type==='longtext'" class="cus-longtext" [title]="data[col.header]">{{data[col.header]}}</span> -->
          <span *ngIf="!col.type" [title]="data[col.field]"><!-- {{col.field}} | --> {{data[col.field]}}</span>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-data let-columns="columns">
      <tr></tr>
      <tr class="expand-row">
        <td [attr.colspan]="columns.length">
          <div class="p-d-flex expand-row-wrapper-container">
            <div class="expand-row-wrapper">
              <div class="expand-row-content-wrapper">
                <div class="expand-row-content">
                  <!-- content -->
                  <div class="p-fluid p-grid p-mt-2 p-mb-2">
                    <ng-container *ngFor="let col of store.tableData.selectedColumns">
                      <div *ngIf="!col.hideInExpandRow" class="p-col-12 p-lg-4">
                        <span class="p-float-label p-input-icon-right"  [pTooltip]="!col.tooltip?null:data[col.field]" tooltipPosition="top">
                          <input
                            *ngIf="!col.type || !col.type.includes('date')"
                            class="p-inputtext-sm"
                            type="text"
                            [attr.id]="col.field"
                            [class]="{'p-input-sm':true}"
                            pInputText [(ngModel)]="data[col.field]"
                            [style]="{'pointer-events': 'none'}"
                            [readonly]='true'>
                          <input
                            *ngIf="col.type == 'date'"
                            class="p-inputtext-sm"
                            type="text"
                            [attr.id]="col.field"
                            [class]="{'p-input-sm':true}"
                            pInputText [ngModel]="data[col.field] | date: 'yyyy/MM/dd' "
                            [style]="{'pointer-events': 'none'}"
                            [readonly]='true'>
                          <input
                            *ngIf="col.type == 'datetime'"
                            class="p-inputtext-sm"
                            type="text"
                            [attr.id]="col.field"
                            [class]="{'p-input-sm':true}"
                            pInputText [ngModel]="data[col.field] | date: 'yyyy/MM/dd HH:mm' "
                            [style]="{'pointer-events': 'none'}"
                            [readonly]='true'>
                          <label [for]="col.field" *ngIf="col.title">{{col.title}}</label>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
    </ng-template>
  </p-table>
  <p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
  </div>
<p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<app-json-display-pop-up [visable]="store.popUpVisable" [jsResult]="store.jsonResult" (visableChange)="store.popupHide($event)"></app-json-display-pop-up>
