<illustration name="Notice"></illustration>
<app-query-pannel class="query-panel" [store]="store.queryPannel"></app-query-pannel>
<app-ptable-pannel [store]="store.tablePannel" [searchStore]="store"></app-ptable-pannel>


<p-dialog *ngIf="store.msgPopup" [(visible)]="store.msgPopup.visible" [modal]="true" (onHide)="store.onMsgPopupHide()"
  [draggable]="false" [resizable]="false" [styleClass]="'cus-modal cus-modal-lg'">
  <ng-template pTemplate="header">
    <h3 *ngIf="store.msgPopup.title" class="p-text-truncate" title="{{store.msgPopup.title}}">{{store.msgPopup.title}}</h3>
  </ng-template>
  <div class="cus-fix-scroll-y">
    <p-table
      [rowHover]="false"
      [paginator]="false"
      [showCurrentPageReport]="false"
      [value]="store.msgPopup.table.data"
      [columns]="store.msgPopup.table.head"
      [customSort]="true"
      [scrollable]="true"
      [loading]="store.msgPopup.table.loading"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup >
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px'}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th [id]="col.title">
              {{col.title}}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let h of columns">
            <span *ngIf="h.type=='link'" class="cus-clickable" [title]="rowData[h.key]" (click)="h.onClick($event, rowData)">{{rowData[h.key]}}</span>
            <span *ngIf="!h.type" [title]="rowData[h.key]">{{rowData[h.key]}}</span>
          </td>
        </tr>
      </ng-template>
      
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length>2?6:2" style="text-align: center">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-outlined" (click)="store.hideMsgPopup()"></button>
    <!-- <button pButton pRipple label="Confirm" icon="pi pi-check" (click)="store.assignActionBy()"></button> -->
  </ng-template>
</p-dialog>