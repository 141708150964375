<div class="p-shadow-1 wrapper">
  <div class="p-d-flex p-jc-between p-mb-2">
    <span>
      <ng-container *ngFor="let btn of store.btns">
        <button *ngIf="btn.show" pButton class="p-button-sm" [label]="btn.title" (click)="btn.handler.click()"  [disabled]="btn.disabled" style="margin-right: 10px;top: -2px;">
        </button>
      </ng-container>
    </span>
    <p-multiSelect *ngIf="true" [options]="store.head" [(ngModel)]="store.selectedColumns" optionLabel="title" selectedItemsLabel="{{store.selectedColumns.length == store.head.length ? 'ALL columns' : store.selectedColumns.length+' columns displayed'}}"  [style]="{minWidth: '200px'}" placeholder="Choose Columns" ></p-multiSelect>
  </div>

  <div>
    <p-table styleClass="p-datatable-sm p-datatable-striped custom-table" editMode="row" dataKey="id"
      [columns] = "store.selectedColumns"
      [(selection)] = "store.selectedRow"
      scrollable = "true"
      scrollHeight="480px"
      [loading] = "store.loadingSwitch"
      [value] = "store.data">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup *ngIf="scrollable">
          <col *ngIf="store.showCheckboxFlag" style="width: 3rem">
          <col *ngFor="let col of columns" [style]="{width: col.width || '250px', minWidth: col.minwidth}">
          <col *ngIf="store.rowEditable" style="width: 160px">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngIf="store.showCheckboxFlag" style="width: 3rem" id="checkbox">
            <p-tableHeaderCheckbox (click)="searchStore.selectAllPNCheckbox($event)" style="margin-left: 5px"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns"
            (click)="searchStore.sortSearch(col.key)"
            pResizableColumn
            pReorderableColumn
            [title]="col.title"
            [style]="{'border-right' : '2px solid #A6D5FA','padding':'5px','white-space': 'normal','min-width':col.minwidth}"
            [pSortableColumn]="col.key"
            [id]="col.title"
            >
            <div style="display: inline-flex;align-items: center;">
              <span style="flex:1;text-align: center;">{{col.title|titlecase}}</span>
              <p-sortIcon [field]="col.key"></p-sortIcon>
            </div>
          </th>
          <th *ngIf="store.rowEditable" id="action">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngIf="store.showCheckboxFlag" style="width: 3rem">
            <p-tableCheckbox [value]="rowData"  (click)="searchStore.selectOnePNCheckBox(rowData.id)" style="margin-left: 5px"></p-tableCheckbox >
          </td>

          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <!-- <div *ngIf="searchStore.excludedField.includes(col.key)" class="edit-panel" (click)="onShowCalender($event, rowData, col.key)"> {{rowData[col.key] | date:'yyyy/MM/dd'}} </div> -->
                <!-- <div *ngIf="col.edit&&col.isDate" [title]="rowData[col.key]|date:'yyyy/MM/dd'"> {{rowData[col.key] | date:'yyyy/MM/dd'}} </div> -->
                <div *ngIf="col.edit" [title]="col.isDate?(rowData[col.key]|date:'yyyy/MM/dd'):rowData[col.key]">
                  {{col.isDate?(rowData[col.key]|date:'yyyy/MM/dd'):rowData[col.key]}}
                </div>
                <input *ngIf="!col.edit" pInputText type="text" [(ngModel)]="rowData[col.key]" style="width: 100%;">
              </ng-template>
              <ng-template pTemplate="output">
                <span [ngSwitch]="col.key">
                <div>
                  <p *ngSwitchCase="'event_time'" [title]="rowData[col.key]|date:'yyyy/MM/dd'"> {{rowData[col.key] | date:'yyyy/MM/dd'}} </p>
                  <p *ngSwitchDefault [title]="rowData[col.key]"> {{ rowData[col.key]}} </p>
                </div>
                </span>
              </ng-template>
            </p-cellEditor>
          </td>

          <td *ngIf="store.rowEditable">
            <button *ngIf="!editing && searchStore.showC" pButton type="button" pInitEditableRow class="p-button-sm" label="Edit" (click)="searchStore.onRowEditInit(rowData)"></button>
            <button *ngIf="editing" pButton type="button" pSaveEditableRow class="p-button-sm" label="Save" (click)="searchStore.onRowEditSave(rowData)" style="margin-right: .5em"></button>
            <button *ngIf="editing" pButton type="button" pCancelEditableRow class="p-button-outlined" label="Cancel" (click)="searchStore.onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length" style=" text-align: center;">No record found.</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator styleClass="p-paginator-sm" [rowsPerPageOptions]="[20,30,50]" [rows]="20" [totalRecords]="store.totalRecords" (onPageChange)="searchStore.page($event)"></p-paginator>
  </div>
</div>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <p-calendar [(ngModel)]="searchStore.currentDate" [showTime]="searchStore.showTime || false" [showSeconds]="searchStore.showTime || false" [inline]="true" (onSelect)="searchStore.onSelectCalender($event, op)"></p-calendar>
  </ng-template>
</p-overlayPanel>
