<illustration [name]="store.breadcrumb" path="Detail page"></illustration>
<p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
<app-query-pannel headerTilte="FILTER" [store]="store.queryData"></app-query-pannel>
<div class="p-shadow-1">
  <p-table
    styleClass="p-datatable-sm p-datatable-striped"
    [rowHover]="false"
    [paginator]="false"
    [rows]="20"
    [showCurrentPageReport]="false"
    [rowsPerPageOptions]="[20, 30, 50]"
    [resizableColumns]="true"
    [value]="store.tableData.data"
    [columns]="store.tableData.columns"
    [sortField]="store.tableData.sortField.field"
    [sortOrder]="store.tableData.sortField.order"
    [loading]="store.tableData.loadingSwitch"
    [customSort]="true"
  >
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style]="{'width': col.width || '300px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent && col.parent.title" [attr.colspan]="col.parent.colspan?col.parent.colspan:1" [id]="col.parent">
          {{col.parent.title}}
        </th>
        <th *ngIf="!col.parent" rowspan="2" pSortableColumn="{{col.field}}" pReorderableColumn [id]="col.title" (click)="store.sortSearch(col.field)">
          {{col.title}}
          <p-sortIcon field="{{col.field}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.parent" pSortableColumn="{{col.field}}" pResizableColumn pReorderableColumn [id]="col.title" (click)="store.sortSearch(col.field)">
          {{col.title}}
          <p-sortIcon field="{{col.field}}"></p-sortIcon>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" class="p-text-truncate" [title]="rowData[col.field]">{{rowData[col.field]}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length" style=" text-align: center;">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator #paginator [rows]="20" [rowsPerPageOptions]="[20,30,50]" [totalRecords]="store.tableData.totalRecords" (onPageChange)="store.page($event)"></p-paginator>
</div>
<p-blockUI [blocked]="store.tableData.loadingSwitch"></p-blockUI>

